import {IReceiveOnlyModel} from "./model";
import {ReviewStatCompositeDTO, ReviewStatDTO} from "../proto/stats_pb";
import {IReviewStat} from "./Review";
import {EntityKind} from "./BaseModel";
import {ReviewCard, ReviewCardStat} from "./ReviewCard";
import {IUIError} from "../service/cartaError";
import {convertDateToTimestamp, convertFromDTOToDate, convertFromDTOToID} from "../utils/utils";
import {Topic} from "./topic";
import {UUID_DTO} from "../proto/utils_pb";
import {ReviewCardDTO} from "../proto/review_pb";
import {TopicDTO} from "../proto/topic_pb";


export class ReviewStatComposite implements IReceiveOnlyModel<ReviewStatComposite, ReviewStatCompositeDTO> {
	id: string
	reviewId: string
	ord: number
	mostReviewedCards: ReviewCard[]
	strongestCards: ReviewCard[]
	weakestCards: ReviewCard[]
	mostReviewedTopics: ReviewCard[]
	strongestTopics: ReviewCard[]
	weakestTopics: ReviewCard[]
	allTopics: Topic[]
	
	constructor() {
		this.id = "";
		this.reviewId = "";
		this.ord = 0;
		this.mostReviewedCards = [];
		this.strongestCards = [];
		this.weakestCards = [];
		this.mostReviewedTopics = [];
		this.strongestTopics = [];
		this.weakestTopics = [];
		this.allTopics = [];
	}
	
	TYPE: EntityKind;
	
	fromDTO(t: ReviewStatCompositeDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, t.getId());
		this.reviewId = convertFromDTOToID('reviewId', this.TYPE, t.getReviewid());
		this.ord = t.getOrd();
		this.mostReviewedCards = t.getMostreviewedcardsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.strongestCards = t.getStrongestcardsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.weakestCards = t.getWeakestcardsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.mostReviewedTopics = t.getMostreviewedtopicsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.strongestTopics = t.getStrongesttopicsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.weakestTopics = t.getWeakesttopicsList().map((x) => {
			let card = new ReviewCard();
			card.fromDTO(x);
			return card;
		});
		this.allTopics = t.getAlltopicsList().map((x) => {
			let topic = new Topic();
			topic.fromDTO(x);
			return topic;
		});
	}
	
	intoDTO(): ReviewStatCompositeDTO {
		let dto = new ReviewStatCompositeDTO();
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setReviewid(new UUID_DTO().setValue(this.reviewId));
		dto.setOrd(this.ord);
		this.mostReviewedCards.forEach((x) => {
			dto.addMostreviewedcards(x.intoDTO() as ReviewCardDTO);
		});
		this.strongestCards.forEach((x) => {
			dto.addStrongestcards(x.intoDTO() as ReviewCardDTO);
		});
		this.weakestCards.forEach((x) => {
			dto.addWeakestcards(x.intoDTO() as ReviewCardDTO);
		});
		this.mostReviewedTopics.forEach((x) => {
			dto.addMostreviewedtopics(x.intoDTO() as ReviewCardDTO);
		});
		this.strongestTopics.forEach((x) => {
			dto.addStrongesttopics(x.intoDTO() as ReviewCardDTO);
		});
		this.weakestTopics.forEach((x) => {
			dto.addWeakesttopics(x.intoDTO() as ReviewCardDTO);
		});
		this.allTopics.forEach((x) => {
			dto.addAlltopics(x.intoDTO() as TopicDTO);
		});
		return dto;
	}
	
	clone(): ReviewStatComposite {
		let composite = new ReviewStatComposite();
		composite.id = this.id;
		composite.reviewId = this.reviewId;
		composite.ord = this.ord;
		composite.mostReviewedCards = [...this.mostReviewedCards];
		composite.strongestCards = [...this.strongestCards];
		composite.weakestCards = [...this.weakestCards];
		composite.mostReviewedTopics = [...this.mostReviewedTopics];
		composite.strongestTopics = [...this.strongestTopics];
		composite.weakestTopics = [...this.weakestTopics];
		composite.allTopics = [...this.allTopics];
		return composite;
	}
}

export class ReviewStat
	implements IReceiveOnlyModel<ReviewStat, ReviewStatDTO>, IReviewStat {
	TYPE: EntityKind = EntityKind.ReviewStat;
	
	id: string = "";
	reviewId: string = "";
	userId: string = "";
	
	avgCompletedQuality?: number;
	avgCompletedInterval?: number;
	avgCompletedRepetitions?: number;
	avgQuality?: number;
	avgInterval?: number;
	avgRepetition?: number;
	totalCardCount: number = 0;
	totalTopicCount: number = 0;
	
	reviewCards: ReviewCardStat[] = [];
	
	strongestCards: string[] = [];
	weakestCards: string[] = [];
	mostReviewedCards: string[] = [];
	mostReviewedTopics: string[] = [];
	strongestTopics: string[] = [];
	weakestTopics: string[] = [];
	allTopics: string[] = [];

	composite?: ReviewStatComposite;
	
	startAt?: Date;
	endAt?: Date;
	createdOn: Date;
	
	clone(): ReviewStat {
		let stat = new ReviewStat();
		
		stat.id = this.id;
		stat.reviewId = this.reviewId;
		stat.userId = this.userId;
		stat.strongestCards = [...this.strongestCards];
		stat.weakestCards = [...this.weakestCards];
		stat.avgCompletedQuality = this.avgCompletedQuality;
		stat.avgCompletedInterval = this.avgCompletedInterval;
		stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
		stat.avgQuality = this.avgQuality;
		stat.avgInterval = this.avgInterval;
		stat.avgRepetition = this.avgRepetition;
		if (this.reviewCards) {
			stat.reviewCards = [...this.reviewCards];
		}
		stat.mostReviewedCards = [...this.mostReviewedCards];
		stat.mostReviewedTopics = [...this.mostReviewedTopics];
		stat.strongestTopics = [...this.strongestTopics];
		stat.weakestTopics = [...this.weakestTopics];
		stat.allTopics = [...this.allTopics];
		stat.totalCardCount = this.totalCardCount;
		stat.totalTopicCount = this.totalTopicCount;
		stat.startAt = this.startAt ? new Date(this.startAt.getTime()) : undefined;
		stat.endAt = this.endAt ? new Date(this.endAt.getTime()) : undefined;
		stat.createdOn = new Date(this.createdOn.getTime());
		stat.composite = this.composite ? this.composite.clone() : undefined;
		
		return stat;
	}
	
	fromDTO(dto: ReviewStatDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.reviewId = convertFromDTOToID('reviewId', this.TYPE, dto.getReviewid());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
		
		this.strongestCards = dto
			.getStrongestcardsList()
			.map((x) => x.getValue());
		this.weakestCards = dto.getWeakestcardsList().map((x) => x.getValue());
		this.avgCompletedRepetitions = dto.getAvgcompletedrepetition();
		this.avgRepetition = dto.getAvgrepetition();
		this.avgQuality = dto.getAvgquality();
		this.avgCompletedQuality = dto.getAvgcompletedquality();
		this.avgCompletedInterval = dto.getAvgcompletedinterval();
		this.avgInterval = dto.getAvginterval();
		this.reviewCards = dto.getReviewcardstatsList().map((x) => {
			let stat = new ReviewCardStat();
			stat.fromDTO(x);
			return stat;
		});
		
		console.log("stat composite dto: ", dto.getComposite());
		let composite = new ReviewStatComposite();
		if (dto.getComposite()) {
			const err = composite.fromDTO(dto.getComposite()!);
			if (err) {
				return err;
			}
		}
		this.composite = composite
		
		this.mostReviewedCards = dto.getMostrepeatedcardsList().map((x) => x.getValue());
		this.mostReviewedTopics = dto.getMostReviewedTopicsList().map((x) => x.getValue());
		this.strongestTopics = dto.getStrongestTopicsList().map((x) => x.getValue());
		this.weakestTopics = dto.getWeakestTopicsList().map((x) => x.getValue());
		this.allTopics = dto.getAllTopicsList().map((x) => x.getValue());
		this.totalCardCount = dto.getTotalCardCount();
		this.totalTopicCount = dto.getTotalTopicCount();
		this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartat(), true);
		this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndat(), true);
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!;
	}
	
	intoDTO(): ReviewStatDTO {
		let dto = new ReviewStatDTO();
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setReviewid(new UUID_DTO().setValue(this.reviewId));
		dto.setUserid(new UUID_DTO().setValue(this.userId));
		dto.setAvgcompletedquality(this.avgCompletedQuality || 0);
		dto.setAvgcompletedrepetition(this.avgCompletedRepetitions || 0);
		dto.setAvgcompletedinterval(this.avgCompletedInterval || 0);
		dto.setAvgquality(this.avgQuality || 0);
		dto.setAvgrepetition(this.avgRepetition || 0);
		dto.setAvginterval(this.avgInterval || 0);
		dto.setTotalCardCount(this.totalCardCount);
		dto.setTotalTopicCount(this.totalTopicCount);
		
		if (this.startAt) {
			dto.setStartat(convertDateToTimestamp(this.startAt));
		}
		if (this.endAt) {
			dto.setEndat(convertDateToTimestamp(this.endAt));
		}
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setComposite(this.composite ? this.composite.intoDTO() : undefined);
		
		this.strongestCards.forEach((x) => {
			dto.addStrongestcards(new UUID_DTO().setValue(x));
		});
		this.weakestCards.forEach((x) => {
			dto.addWeakestcards(new UUID_DTO().setValue(x));
		});
		this.mostReviewedCards.forEach((x) => {
			dto.addMostrepeatedcards(new UUID_DTO().setValue(x));
		});
		this.mostReviewedTopics.forEach((x) => {
			dto.addMostReviewedTopics(new UUID_DTO().setValue(x));
		});
		this.strongestTopics.forEach((x) => {
			dto.addStrongestTopics(new UUID_DTO().setValue(x));
		});
		this.weakestTopics.forEach((x) => {
			dto.addWeakestTopics(new UUID_DTO().setValue(x));
		});
		this.allTopics.forEach((x) => {
			dto.addAllTopics(new UUID_DTO().setValue(x));
		});
		
		this.reviewCards.forEach((x) => {
			dto.addReviewcardstats(x.intoDTO());
		});
		
		return dto;
	}
}