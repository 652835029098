/**
 * @fileoverview gRPC-Web generated client stub for topic
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: topic.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.topic = require('./topic_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.topic.TopicServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.topic.TopicServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.CreateTopicRequest,
 *   !proto.topic.CreateTopicResponse>}
 */
const methodDescriptor_TopicService_create = new grpc.web.MethodDescriptor(
  '/topic.TopicService/create',
  grpc.web.MethodType.UNARY,
  proto.topic.CreateTopicRequest,
  proto.topic.CreateTopicResponse,
  /**
   * @param {!proto.topic.CreateTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.CreateTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.CreateTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.CreateTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.CreateTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/create',
      request,
      metadata || {},
      methodDescriptor_TopicService_create,
      callback);
};


/**
 * @param {!proto.topic.CreateTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.CreateTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/create',
      request,
      metadata || {},
      methodDescriptor_TopicService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.GetTopicRequest,
 *   !proto.topic.GetTopicResponse>}
 */
const methodDescriptor_TopicService_get = new grpc.web.MethodDescriptor(
  '/topic.TopicService/get',
  grpc.web.MethodType.UNARY,
  proto.topic.GetTopicRequest,
  proto.topic.GetTopicResponse,
  /**
   * @param {!proto.topic.GetTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.GetTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.GetTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.GetTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.GetTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/get',
      request,
      metadata || {},
      methodDescriptor_TopicService_get,
      callback);
};


/**
 * @param {!proto.topic.GetTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.GetTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/get',
      request,
      metadata || {},
      methodDescriptor_TopicService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ListTopicRequest,
 *   !proto.topic.ListTopicResponse>}
 */
const methodDescriptor_TopicService_list = new grpc.web.MethodDescriptor(
  '/topic.TopicService/list',
  grpc.web.MethodType.UNARY,
  proto.topic.ListTopicRequest,
  proto.topic.ListTopicResponse,
  /**
   * @param {!proto.topic.ListTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ListTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ListTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ListTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ListTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/list',
      request,
      metadata || {},
      methodDescriptor_TopicService_list,
      callback);
};


/**
 * @param {!proto.topic.ListTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ListTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/list',
      request,
      metadata || {},
      methodDescriptor_TopicService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ListTopicByIdsRequest,
 *   !proto.topic.ListTopicByIdsResponse>}
 */
const methodDescriptor_TopicService_listByIds = new grpc.web.MethodDescriptor(
  '/topic.TopicService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.topic.ListTopicByIdsRequest,
  proto.topic.ListTopicByIdsResponse,
  /**
   * @param {!proto.topic.ListTopicByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ListTopicByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ListTopicByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ListTopicByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ListTopicByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/listByIds',
      request,
      metadata || {},
      methodDescriptor_TopicService_listByIds,
      callback);
};


/**
 * @param {!proto.topic.ListTopicByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ListTopicByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/listByIds',
      request,
      metadata || {},
      methodDescriptor_TopicService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ArchiveTopicRequest,
 *   !proto.topic.ArchiveTopicResponse>}
 */
const methodDescriptor_TopicService_archive = new grpc.web.MethodDescriptor(
  '/topic.TopicService/archive',
  grpc.web.MethodType.UNARY,
  proto.topic.ArchiveTopicRequest,
  proto.topic.ArchiveTopicResponse,
  /**
   * @param {!proto.topic.ArchiveTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ArchiveTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ArchiveTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ArchiveTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ArchiveTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/archive',
      request,
      metadata || {},
      methodDescriptor_TopicService_archive,
      callback);
};


/**
 * @param {!proto.topic.ArchiveTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ArchiveTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.archive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/archive',
      request,
      metadata || {},
      methodDescriptor_TopicService_archive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.DeleteTopicRequest,
 *   !proto.topic.DeleteTopicResponse>}
 */
const methodDescriptor_TopicService_delete = new grpc.web.MethodDescriptor(
  '/topic.TopicService/delete',
  grpc.web.MethodType.UNARY,
  proto.topic.DeleteTopicRequest,
  proto.topic.DeleteTopicResponse,
  /**
   * @param {!proto.topic.DeleteTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.DeleteTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.DeleteTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.DeleteTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.DeleteTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/delete',
      request,
      metadata || {},
      methodDescriptor_TopicService_delete,
      callback);
};


/**
 * @param {!proto.topic.DeleteTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.DeleteTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/delete',
      request,
      metadata || {},
      methodDescriptor_TopicService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.UpdateTopicRequest,
 *   !proto.topic.UpdateTopicResponse>}
 */
const methodDescriptor_TopicService_update = new grpc.web.MethodDescriptor(
  '/topic.TopicService/update',
  grpc.web.MethodType.UNARY,
  proto.topic.UpdateTopicRequest,
  proto.topic.UpdateTopicResponse,
  /**
   * @param {!proto.topic.UpdateTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.UpdateTopicResponse.deserializeBinary
);


/**
 * @param {!proto.topic.UpdateTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.UpdateTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.UpdateTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/update',
      request,
      metadata || {},
      methodDescriptor_TopicService_update,
      callback);
};


/**
 * @param {!proto.topic.UpdateTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.UpdateTopicResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/update',
      request,
      metadata || {},
      methodDescriptor_TopicService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.GetTopicChildrenRequest,
 *   !proto.topic.GetTopicChildrenResponse>}
 */
const methodDescriptor_TopicService_listTopicChildren = new grpc.web.MethodDescriptor(
  '/topic.TopicService/listTopicChildren',
  grpc.web.MethodType.UNARY,
  proto.topic.GetTopicChildrenRequest,
  proto.topic.GetTopicChildrenResponse,
  /**
   * @param {!proto.topic.GetTopicChildrenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.GetTopicChildrenResponse.deserializeBinary
);


/**
 * @param {!proto.topic.GetTopicChildrenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.GetTopicChildrenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.GetTopicChildrenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.listTopicChildren =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/listTopicChildren',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicChildren,
      callback);
};


/**
 * @param {!proto.topic.GetTopicChildrenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.GetTopicChildrenResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.listTopicChildren =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/listTopicChildren',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicChildren);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.GetTopicGraphRequest,
 *   !proto.topic.GetTopicGraphResponse>}
 */
const methodDescriptor_TopicService_getTopicGraph = new grpc.web.MethodDescriptor(
  '/topic.TopicService/getTopicGraph',
  grpc.web.MethodType.UNARY,
  proto.topic.GetTopicGraphRequest,
  proto.topic.GetTopicGraphResponse,
  /**
   * @param {!proto.topic.GetTopicGraphRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.GetTopicGraphResponse.deserializeBinary
);


/**
 * @param {!proto.topic.GetTopicGraphRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.GetTopicGraphResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.GetTopicGraphResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.getTopicGraph =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/getTopicGraph',
      request,
      metadata || {},
      methodDescriptor_TopicService_getTopicGraph,
      callback);
};


/**
 * @param {!proto.topic.GetTopicGraphRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.GetTopicGraphResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.getTopicGraph =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/getTopicGraph',
      request,
      metadata || {},
      methodDescriptor_TopicService_getTopicGraph);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ListTopicRootsRequest,
 *   !proto.topic.ListTopicRootsResponse>}
 */
const methodDescriptor_TopicService_listTopicRoots = new grpc.web.MethodDescriptor(
  '/topic.TopicService/listTopicRoots',
  grpc.web.MethodType.UNARY,
  proto.topic.ListTopicRootsRequest,
  proto.topic.ListTopicRootsResponse,
  /**
   * @param {!proto.topic.ListTopicRootsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ListTopicRootsResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ListTopicRootsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ListTopicRootsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ListTopicRootsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.listTopicRoots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/listTopicRoots',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicRoots,
      callback);
};


/**
 * @param {!proto.topic.ListTopicRootsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ListTopicRootsResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.listTopicRoots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/listTopicRoots',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicRoots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.CreateTopicRelationshipRequest,
 *   !proto.topic.CreateTopicRelationshipResponse>}
 */
const methodDescriptor_TopicService_createTopicRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/createTopicRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.CreateTopicRelationshipRequest,
  proto.topic.CreateTopicRelationshipResponse,
  /**
   * @param {!proto.topic.CreateTopicRelationshipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.CreateTopicRelationshipResponse.deserializeBinary
);


/**
 * @param {!proto.topic.CreateTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.CreateTopicRelationshipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.CreateTopicRelationshipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.createTopicRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/createTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createTopicRelationship,
      callback);
};


/**
 * @param {!proto.topic.CreateTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.CreateTopicRelationshipResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.createTopicRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/createTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createTopicRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.DeleteTopicRelationshipRequest,
 *   !proto.topic.DeleteTopicRelationshipResponse>}
 */
const methodDescriptor_TopicService_deleteTopicRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/deleteTopicRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.DeleteTopicRelationshipRequest,
  proto.topic.DeleteTopicRelationshipResponse,
  /**
   * @param {!proto.topic.DeleteTopicRelationshipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.DeleteTopicRelationshipResponse.deserializeBinary
);


/**
 * @param {!proto.topic.DeleteTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.DeleteTopicRelationshipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.DeleteTopicRelationshipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.deleteTopicRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/deleteTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteTopicRelationship,
      callback);
};


/**
 * @param {!proto.topic.DeleteTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.DeleteTopicRelationshipResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.deleteTopicRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/deleteTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteTopicRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.CreateResourceRelationRequest,
 *   !proto.topic.CreateResourceRelationResponse>}
 */
const methodDescriptor_TopicService_createResourceRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/createResourceRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.CreateResourceRelationRequest,
  proto.topic.CreateResourceRelationResponse,
  /**
   * @param {!proto.topic.CreateResourceRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.CreateResourceRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.CreateResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.CreateResourceRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.CreateResourceRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.createResourceRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/createResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createResourceRelationship,
      callback);
};


/**
 * @param {!proto.topic.CreateResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.CreateResourceRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.createResourceRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/createResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createResourceRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.CreateTagRelationRequest,
 *   !proto.topic.CreateTagRelationResponse>}
 */
const methodDescriptor_TopicService_createTagRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/createTagRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.CreateTagRelationRequest,
  proto.topic.CreateTagRelationResponse,
  /**
   * @param {!proto.topic.CreateTagRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.CreateTagRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.CreateTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.CreateTagRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.CreateTagRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.createTagRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/createTagRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createTagRelationship,
      callback);
};


/**
 * @param {!proto.topic.CreateTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.CreateTagRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.createTagRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/createTagRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createTagRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.DeleteTagRelationRequest,
 *   !proto.topic.DeleteTagRelationResponse>}
 */
const methodDescriptor_TopicService_deleteTagRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/deleteTagRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.DeleteTagRelationRequest,
  proto.topic.DeleteTagRelationResponse,
  /**
   * @param {!proto.topic.DeleteTagRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.DeleteTagRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.DeleteTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.DeleteTagRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.DeleteTagRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.deleteTagRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/deleteTagRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteTagRelationship,
      callback);
};


/**
 * @param {!proto.topic.DeleteTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.DeleteTagRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.deleteTagRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/deleteTagRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteTagRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.CreateCardRelationRequest,
 *   !proto.topic.CreateCardRelationResponse>}
 */
const methodDescriptor_TopicService_createCardRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/createCardRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.CreateCardRelationRequest,
  proto.topic.CreateCardRelationResponse,
  /**
   * @param {!proto.topic.CreateCardRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.CreateCardRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.CreateCardRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.CreateCardRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.CreateCardRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.createCardRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/createCardRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createCardRelationship,
      callback);
};


/**
 * @param {!proto.topic.CreateCardRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.CreateCardRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.createCardRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/createCardRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_createCardRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.DeleteCardRelationRequest,
 *   !proto.topic.DeleteCardRelationResponse>}
 */
const methodDescriptor_TopicService_deleteCardRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/deleteCardRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.DeleteCardRelationRequest,
  proto.topic.DeleteCardRelationResponse,
  /**
   * @param {!proto.topic.DeleteCardRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.DeleteCardRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.DeleteCardRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.DeleteCardRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.DeleteCardRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.deleteCardRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/deleteCardRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteCardRelationship,
      callback);
};


/**
 * @param {!proto.topic.DeleteCardRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.DeleteCardRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.deleteCardRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/deleteCardRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteCardRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.DeleteResourceRelationRequest,
 *   !proto.topic.DeleteResourceRelationResponse>}
 */
const methodDescriptor_TopicService_deleteResourceRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/deleteResourceRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.DeleteResourceRelationRequest,
  proto.topic.DeleteResourceRelationResponse,
  /**
   * @param {!proto.topic.DeleteResourceRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.DeleteResourceRelationResponse.deserializeBinary
);


/**
 * @param {!proto.topic.DeleteResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.DeleteResourceRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.DeleteResourceRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.deleteResourceRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/deleteResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteResourceRelationship,
      callback);
};


/**
 * @param {!proto.topic.DeleteResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.DeleteResourceRelationResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.deleteResourceRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/deleteResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_deleteResourceRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ListTopicRelationshipRequest,
 *   !proto.topic.ListTopicRelationshipResponse>}
 */
const methodDescriptor_TopicService_listTopicRelationship = new grpc.web.MethodDescriptor(
  '/topic.TopicService/listTopicRelationship',
  grpc.web.MethodType.UNARY,
  proto.topic.ListTopicRelationshipRequest,
  proto.topic.ListTopicRelationshipResponse,
  /**
   * @param {!proto.topic.ListTopicRelationshipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ListTopicRelationshipResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ListTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ListTopicRelationshipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ListTopicRelationshipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.listTopicRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/listTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicRelationship,
      callback);
};


/**
 * @param {!proto.topic.ListTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ListTopicRelationshipResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.listTopicRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/listTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.topic.ListTopicForCardRequest,
 *   !proto.topic.ListTopicForCardResponse>}
 */
const methodDescriptor_TopicService_listTopicsForCard = new grpc.web.MethodDescriptor(
  '/topic.TopicService/listTopicsForCard',
  grpc.web.MethodType.UNARY,
  proto.topic.ListTopicForCardRequest,
  proto.topic.ListTopicForCardResponse,
  /**
   * @param {!proto.topic.ListTopicForCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.topic.ListTopicForCardResponse.deserializeBinary
);


/**
 * @param {!proto.topic.ListTopicForCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.topic.ListTopicForCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.topic.ListTopicForCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.topic.TopicServiceClient.prototype.listTopicsForCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/topic.TopicService/listTopicsForCard',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicsForCard,
      callback);
};


/**
 * @param {!proto.topic.ListTopicForCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.topic.ListTopicForCardResponse>}
 *     Promise that resolves to the response
 */
proto.topic.TopicServicePromiseClient.prototype.listTopicsForCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/topic.TopicService/listTopicsForCard',
      request,
      metadata || {},
      methodDescriptor_TopicService_listTopicsForCard);
};


module.exports = proto.topic;

