import {action, computed, makeObservable, observable, runInAction,} from "mobx";
import {ReviewManual} from "../model/ReviewManual";
import {ReviewManualGRPC, ReviewManualService} from "../service/ReviewManualService";
import {
	ActionType,
	InternalErrorTypes,
	isError,
	IUIError,
	NewUIError,
	NewUIErrorV2,
	UIErrorV2,
} from "../service/cartaError";
import {TimestampDTO, UUID_DTO, UUID_DTO as UUID,} from "../proto/utils_pb";
import {convertDateToTimestamp, replacePlaceholders, unifiedInterceptor,} from "../utils/utils";
import {getUserId} from "../service/AuthService";
import {
	CardFilterResultDTO,
	CardLangFilterResultDTO,
	CreateCardItemsDTO,
	CreateReviewManualRequest,
	CreateReviewManualResponse,
	FilterManualCardsRequest,
	FilterManualCardsResponse,
	ReviewManualConfigDTO,
	ReviewManualDTO,
	ReviewManualOptionsDTO,
} from "../proto/reviewManual_pb";
import {ReviewManualServicePromiseClient} from "proto/reviewManual_grpc_web_pb";
import {CardStore} from "./CardStore";
import {IFromDTO} from "model/model";

import {ReviewManualConfig} from "../model/ReviewManualConfig";
import {ICard} from "../model/CardComposite";
import {Err, Ok, Result} from "../utils/result";
import {EntityKind} from "model/BaseModel";
import {Card} from "../model/Card";
import {CardLang} from "../model/CardLang";
import {Displayable, IDisplayItem} from "../model/interfaces";
import {ReviewBaseStore} from "./ReviewBaseStore";
import {CARTA_PROXY_URL, MAX_LIMIT_REVIEW_MANUAL, MIN_LIMIT_REVIEW_MANUAL} from "consts";
import {ReviewStatDTO} from "../proto/stats_pb";
import {ReviewCard} from "../model/ReviewCard";
import {ReviewCardDTO} from "../proto/review_pb";
import messages from "../text/en.json";
import {ReviewStat} from "../model/ReviewStat";

const reviewClient = new ReviewManualServicePromiseClient(
	CARTA_PROXY_URL!,
	null,
	{withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor]}
);

export class ReviewManualStore extends ReviewBaseStore<
	ReviewManual,
	ReviewManualDTO,
	ReviewCard,
	ReviewCardDTO,
	ReviewStat,
	ReviewStatDTO,
	ReviewManualOptionsDTO,
	ReviewManualGRPC,
	ReviewManualService> {
	public service: ReviewManualService;
	
	// public filterResults: ReviewManualCardFilterResult<ICard>[] | undefined;
	
	cardStore: CardStore;
	
	public cardCursor: TimestampDTO = convertDateToTimestamp(new Date());
	
	constructor(cardStore: CardStore, service: ReviewManualService) {
		super(service, ReviewManual, ReviewCard, ReviewStat);
		
		makeObservable(this, {
			// cardsForNewReview: observable,
			filterResults: observable,

			CreateReview: action,

			getReviewArray: computed,
		});
		
		this.service = service
		this.cardStore = cardStore;
	}
	
	// @computed
	public get getReviewArray(): ReviewManual[] {
		return Array.from(this.map.values());
	}
	
	public FilterManualCards = async (
		opts: ReviewManualConfig
	): Promise<Result<ReviewManualCardFilterResult<ICard>[], IUIError>> => {
		let item = opts.clone();
		let dto = item.intoDTO();
		
		if (isError(dto)) {
			return Err(dto as IUIError);
		}
		
		let user_id = getUserId();
		let user_id_uuid = new UUID().setValue(user_id);
		
		// TODO: Change this once we bring in pagination
		(dto as ReviewManualConfigDTO).setUserid(user_id_uuid);
		
		let req = new FilterManualCardsRequest();
		req.setFilter(dto as ReviewManualConfigDTO);
		
		try {
			const response: FilterManualCardsResponse =
				await reviewClient.filterManualCards(req);
			
			if (response.getCardList() === undefined && response.getCardlangList() === undefined) {
				return Err(NewUIErrorV2(
					ActionType.FilterCards,
					EntityKind.ReviewManual,
					"returned review is undefined",
				))
			}
			
			let results: ReviewManualCardFilterResult<ICard>[] = [];
			
			response.getCardList().forEach((res) => {
				let result = new ReviewManualCardFilterResult(Card);
				const err = result.fromCardFilterResult(res as CardFilterResultDTO);
				
				if (err) {
					return err as IUIError;
				}
				
				results.push(result);
			});
			
			response.getCardlangList().forEach((res) => {
				let result = new ReviewManualCardFilterResult(CardLang);
				const err = result.fromCardLangFilterResult(res as CardLangFilterResultDTO);
				
				if (err) {
					return err as IUIError;
				}
				
				results.push(result);
			});
			
			// TODO we must set the pagination, or have it returned in the object
			runInAction(() => {
				this.filterResults = results;
			});
			
			return Ok(results);
		} catch (err) {
			return Err(NewUIErrorV2(
				ActionType.FilterCards,
				EntityKind.ReviewManual,
				err
			));
		}
	};
	
	/**
	 * There's some nuance involved in creating a review, primarily with cards. We do not populate
	 * the `card_order_list` or `current_card_id` field, this is done by the backend, this minimizes the size of the payload. Also
	 * remember that the `review.card_order_list` and `review.current_card_id` point to `ReviewManualCard`s and not
	 * `Card`s themselves.
	 *
	 * We rely on the backend to provide these. In theory the frontend could provide the ReviewManualCards themselves and this
	 * may be a choice we choose to make in the future, however for now it's fine if the backend provides it.
	 * @param review
	 * @param cards - This is a list of `Card` types that the backend will generate `ReviewManualCard`s from. They are not the same thing
	 * @param config
	 * @constructor
	 */
	// @action
	public CreateReview = async (
		review: ReviewManual,
		cards: CreateCardItemsDTO[],
		config: ReviewManualConfig
	): Promise<Result<ReviewManual, IUIError>> => {
		const err = review.customValidate()
		if (isError(err)){
			console.log(`failed to validate review: ${err}`)
			return Err(err as UIErrorV2)
		}
		
		if (cards.length < MIN_LIMIT_REVIEW_MANUAL || cards.length > MAX_LIMIT_REVIEW_MANUAL) {
			const placeholders = [MIN_LIMIT_REVIEW_MANUAL.toString(), MAX_LIMIT_REVIEW_MANUAL.toString()]
			const errMessage = replacePlaceholders(messages["error.review.manual.card.length.oob"], placeholders)
			return Err(NewUIErrorV2(ActionType.UIValidate, EntityKind.ReviewManual, undefined, errMessage, errMessage))
		}
		
		let reviewManualDTO = review.intoDTO();
		if (isError(reviewManualDTO)) {
			return Err(reviewManualDTO as IUIError);
		}
		
		let reviewConfigDTO = config.intoDTO();
		if (isError(reviewConfigDTO)) {
			return Err(reviewConfigDTO as IUIError);
		}
		
		let user_id = getUserId();
		let user_id_uuid = new UUID().setValue(user_id);
		let config_id = config.id;
		
		(reviewManualDTO as ReviewManualDTO).setUserid(user_id_uuid);
		if (reviewConfigDTO) {
			(reviewConfigDTO as ReviewManualConfigDTO).setUserid(user_id_uuid);
			(reviewManualDTO as ReviewManualDTO).setReviewconfigid(new UUID_DTO().setValue(config_id))
		}
		
		let req = new CreateReviewManualRequest();
		req.setSession(reviewManualDTO as ReviewManualDTO);
		req.setConfig(reviewConfigDTO as ReviewManualConfigDTO);
		req.setCardsList(cards);
		
		try {
			const response: CreateReviewManualResponse =
				await reviewClient.create(req);
			
			if (response.getSession() === undefined) {
				return Err(NewUIErrorV2(ActionType.Create, EntityKind.ReviewManual, "returned review is undefined"));
			}
			
			let review = new ReviewManual();
			const resp = review.fromDTO(response.getSession() as ReviewManualDTO);
			
			if (resp) {
				return Err(NewUIErrorV2(
					ActionType.Create, EntityKind.ReviewManual,
					resp as IUIError,
					"failed to validate response object",
				));
			}
			
			runInAction(() => {
				this.map.set(review.id, review as ReviewManual);
			});
			
			return Ok(review as ReviewManual);
		} catch (err) {
			return Err(NewUIErrorV2(
				ActionType.Create, EntityKind.ReviewManual,
				err,
				`store: failed to create review`
			));
		}
	};
	
	async SaveCardWithNext(review_id: string, next_card_id: string, card: ReviewCard): Promise<Result<ReviewCard, UIErrorV2>> {
		return this.service.SaveCardWithNext(review_id, next_card_id, card)
	}
}

export class ReviewManualCardFilterResult<P extends ICard>
	implements IFromDTO<undefined>, Displayable {
	composite: P;
	id: string;
	avgQuality?: number;
	
	constructor(private composit: new () => P) {
		this.id = ""
		this.composite = new composit()
	}
	
	fromCardFilterResult(t: CardFilterResultDTO): void | IUIError {
		let c = new Card()
		if (t.getCard()) {
			const err = c.fromDTO(t.getCard()!)
			if (err) {
				return err;
			}
		} else {
			return NewUIError(
				"fromCardFilterResult",
				InternalErrorTypes.CardFilterResult,
				`card is undefined: ${JSON.stringify(t)}`
			);
		}
		
		this.id = c.id;
		this.avgQuality = t.getAvgquality()
		this.composite = c as unknown as P;
	}
	
	fromCardLangFilterResult(t: CardLangFilterResultDTO): void | IUIError {
		let c = new CardLang()
		if (t.getCardlang()) {
			const err = c.fromDTO(t.getCardlang()!)
			if (err) {
				return err;
			}
		} else {
			return NewUIError(
				"fromCardLangFilterResult",
				InternalErrorTypes.CardLangFilterResult,
				`cardLang is undefined: ${JSON.stringify(t)}`
			);
		}
		
		this.id = c.id;
		this.avgQuality = t.getAvgquality()
		this.composite = c as unknown as P;
	}
	
	
	fromDTO(t: undefined): void | IUIError {
	}
	
	to1LineString(): String {
		return this.composite.front;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.composite.front,
			description: this.composite.back
		} as IDisplayItem
	}
}

export interface Opts<T> {
	title: string;
	value: T; // days
}

export enum ReviewFilterKind {
	RecentCards,
}

export enum ReviewDurationKind {
	Any,
	Day1,
	Day3,
	Day7,
	Day30,
	HalfYear,
	Year,
	AllTime,
}
