import {ResourceServicePromiseClient} from "proto/resource_grpc_web_pb";
import {
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    EntityKind,
    IGRPCService,
    ListResponse,
} from "model/BaseModel";
import {
    CreateResourceRequest,
    DeleteResourceRequest,
    GetResourceRequest,
    ListResourceByIdsRequest,
    ListResourceRequest,
    ResourceDTO,
    SearchGoogleBooksRequest,
    SearchGoogleBooksResponse,
    UpdateResourceRequest,
} from "proto/resource_pb";
import {ListOptionsRequestDTO, UUID_DTO,} from "proto/utils_pb";
import grpcWeb from "grpc-web";
import {BaseService} from "./BaseService";
import {Resource} from "model/resource/Resource";
import {ActionType, isError, IUIError, NewUIErrorV2} from "./cartaError";
import {Err, Ok, Result} from "utils/result";
import {CARTA_PROXY_URL, CARTA_RESOURCE_PROXY_URL} from "consts";
import {unifiedInterceptor} from "../utils/utils";

const resourceClient = new ResourceServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor]}
);

export class ResourceService extends BaseService<
    Resource,
    ResourceDTO,
    ResourceGRPCImpl
> {
    constructor() {
        super(new ResourceGRPCImpl(), Resource);
    }

    async WebSearch(text: string): Promise<Result<ListResponse<Resource>, IUIError>> {
        let req: SearchGoogleBooksRequest = new SearchGoogleBooksRequest();
        req.setQuery(text);


        console.log("searching google books")
        try {
            const response: SearchGoogleBooksResponse = await resourceClient.searchGoogleBooks(
                req
            );

            if (!response.getItemsList()) {
                return Err(NewUIErrorV2(
                    ActionType.Search,
                    EntityKind.Resource,
                    "Failed to search for resources via external provider",
                ));
            }

            let resources: Resource[] = [];
            response.getItemsList().forEach((x: ResourceDTO) => {
                let r = new Resource();
                r.fromDTO(x);

                if (isError(r)) {
                    return Err(NewUIErrorV2(
                        ActionType.ConvertFromDTO,
                        EntityKind.Resource,
                        r,
                        "Failed to convert resource from DTO",
                    ));
                }

                resources.push(r);
            });


            return Ok({
                items: resources,
                info: response.getInfo()
            } as ListResponse<Resource>);
        } catch (err) {
            return Err(NewUIErrorV2(
                ActionType.Search,
                EntityKind.Resource,
                err,
                "[panic] Failed to convert resource from DTO",
            ));
        }
    };

    // try {
    //
    //     const formattedUrl = getProxyUrl(url);
    //
    //     console.log("formatted url", formattedUrl)
    //     // Extract metadata TODO: Check that resource proxy is working
    //     const response = await axios.get(formattedUrl, {});
    //     const html = response.data;
    //     const $ = cheerio.load(html);
    //
    //     const title = $('meta[property="og:title"]').attr('content') || $('title').text();
    //     const description = $('meta[property="og:description"]').attr('content') || $('meta[name="description"]').attr('content') || '';
    //     const imageUrl = $('meta[property="og:image"]').attr('content');
    //
    //     return Ok({
    //         title,
    //         description,
    //         image: imageUrl,
    //     });
    // } catch (error) {
    //     console.error('Error fetching metadata:', error);
    //     return Err(NewUIErrorV2(ActionType.InternetRequest, EntityKind.Resource, error, 'Failed to fetch metadata'));
    // }
}


export class ResourceGRPCImpl
    implements IGRPCService<
        ResourceDTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<ResourceDTO>
    > {
    setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
        let req = new ListResourceByIdsRequest();
        req.setIdsList(ids);

        return req;
    }

    async listByIDs(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ResourceDTO> | undefined> {
        let x = await resourceClient.listByIds(req as ListResourceByIdsRequest, meta);

        return {
            items: x.getItemsList() as ResourceDTO[],
            info: x.getInfo(),
        } as ListResponse<ResourceDTO>;
    }

    async create(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ResourceDTO | undefined> {
        let x = await resourceClient.create(req as CreateResourceRequest, meta);
        return x.getResource() as ResourceDTO;
    }

    setupCreateReq(dto: ResourceDTO): DTOCreatorRequestType {
        let req = new CreateResourceRequest();
        req.setResource(dto);

        return req;
    }

    async list(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ResourceDTO> | undefined> {
        let x = await resourceClient.list(req as ListResourceRequest, meta);

        return {
            items: x.getItemsList() as ResourceDTO[],
            info: x.getInfo(),
        } as ListResponse<ResourceDTO>;
    }

    setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
        let req = new ListResourceRequest();
        req.setOpts(dto);

        return req;
    }

    async delete(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<void> {
        const x = await resourceClient.delete(req as DeleteResourceRequest, meta);
        return;
    }

    setupDeleteReq(id: string): DTOCreatorRequestType {
        let req = new DeleteResourceRequest();
        req.setResourceId(new UUID_DTO().setValue(id));

        return req;
    }

    async get(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ResourceDTO | undefined> {
        const x = await resourceClient.get(req as GetResourceRequest, meta);
        return x.getResource() as ResourceDTO;
    }

    setupGetReq(id: string): DTOCreatorRequestType {
        let req = new GetResourceRequest()
        req.setResourceId(new UUID_DTO().setValue(id))

        return req;
    }

    async update(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ResourceDTO | undefined> {
        const x = await resourceClient.update(req as UpdateResourceRequest, meta);
        return x.getItem() as ResourceDTO;
    }

    setupUpdateReq(dto: ResourceDTO): DTOCreatorRequestType {
        let req = new UpdateResourceRequest();
        req.setItem(dto);

        return req
    }
}

