import {
	CreateTopicRequest,
	DeleteTopicRelationshipRequest,
	DeleteTopicRelationshipResponse,
	DeleteTopicRequest,
	GetTopicChildrenRequest,
	GetTopicChildrenResponse,
	GetTopicGraphRequest,
	GetTopicGraphResponse,
	GetTopicRequest,
	ListTopicByIdsRequest,
	ListTopicRequest,
	TopicDTO,
	TopicRelationshipEnumDTO,
	TopicRelationshipGraphDTO,
	UpdateTopicRequest,
} from "proto/topic_pb";
import {
	ActionType,
	InternalError,
	InternalErrorTypes,
	isError,
	IUIError,
	NewUIError,
	NewUIErrorV2,
	UIErrorV2,
} from "./cartaError";
import {ListOptionsRequestDTO, UUID_DTO,} from "proto/utils_pb";
import {TopicServicePromiseClient} from "proto/topic_grpc_web_pb";
import {IsUUIDValid, SimpleDisplayItem} from "utils/utils";
import {convertDTOToTopicGraph, Topic} from "model/topic";
import {TopicGraph, TopicRelation, TopicRelationshipData,} from "model/graph";
import {BaseService} from "./BaseService";
import {DTOCreatorRequestType, DTOCreatorResponseType, EntityKind, IGRPCService, ListResponse,} from "model/BaseModel";
import grpcWeb from "grpc-web";
import {getUserId} from "service/AuthService";
import {CARTA_PROXY_URL} from "consts";
import {Err, Result} from "../utils/result";

const topicClient = new TopicServicePromiseClient(CARTA_PROXY_URL!, null, {
	withCredentials: true,
});

export class TopicGRPCImpl
	implements IGRPCService<
		TopicDTO,
		DTOCreatorRequestType,
		DTOCreatorResponseType<TopicDTO>
	> {
	setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
		let req = new ListTopicByIdsRequest();
		req.setIdsList(ids);
		
		return req;
	}
	
	async listByIDs(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ListResponse<TopicDTO> | undefined> {
		let x = await topicClient.listByIds(req as ListTopicByIdsRequest, meta);
		
		return {
			items: x.getItemsList() as TopicDTO[],
			info: x.getInfo(),
		} as ListResponse<TopicDTO>;
	}
	
	async create(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<TopicDTO | undefined> {
		let x = await topicClient.create(req as CreateTopicRequest, meta);
		return x.getTopic() as TopicDTO;
	}
	
	setupCreateReq(dto: TopicDTO): DTOCreatorRequestType {
		let req = new CreateTopicRequest();
		req.setTopic(dto);
		
		return req;
	}
	
	async list(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ListResponse<TopicDTO> | undefined> {
		let x = await topicClient.list(req as ListTopicRequest, meta);
		
		return {
			items: x.getItemsList() as TopicDTO[],
			info: x.getInfo(),
		} as ListResponse<TopicDTO>;
	}
	
	setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
		let req = new ListTopicRequest();
		req.setOpts(dto);
		
		return req;
	}
	
	async delete(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<void> {
		const x = await topicClient.delete(req as DeleteTopicRequest, meta);
		return;
	}
	
	setupDeleteReq(id: string): DTOCreatorRequestType {
		let req = new DeleteTopicRequest();
		req.setTopicId(new UUID_DTO().setValue(id));
		
		return req;
	}
	
	async get(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<TopicDTO | undefined> {
		const x = await topicClient.get(req as GetTopicRequest, meta);
		return x.getTopic() as TopicDTO;
	}
	
	setupGetReq(id: string): DTOCreatorRequestType {
		let req = new GetTopicRequest()
		req.setTopicId(new UUID_DTO().setValue(id))
		
		return req;
	}
	
	setupUpdateReq(dto: TopicDTO): DTOCreatorRequestType {
		let req = new UpdateTopicRequest();
		req.setTopic(dto);
		
		return req;
	}
	
	async update(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<TopicDTO | undefined> {
		const x = await topicClient.update(req as UpdateTopicRequest, meta);
		return x.getTopic() as TopicDTO;
	}
}

export class TopicService extends BaseService<Topic, TopicDTO, TopicGRPCImpl> {
	constructor() {
		super(new TopicGRPCImpl(), Topic);
	}
	
	// CreateTopicRelationships = async (topic: Topic, relationship: TopicRelationshipData): Promise<TopicRelationshipData | UIError> => {
	//     let dto = convertTopicRelationDataToDTO(relationship)
	//
	//     topic = sanitizeTopic(topic)
	//
	//     let req = new CreateTopicRelationshipRequest()
	//     req.setGraph(dto)
	//
	//     let token = getAuthToken()
	//     let meta = {"x-grpc-authorization": token};
	//
	//     try {
	//         const response: CreateTopicRelationshipResponse = await topicClient.createTopicRelationship(req)
	//
	//         if (response.getGraph() === undefined) {
	//             return NewUIError("CreateTopicRelationships", InternalErrorTypes.CreateTopicGraph, `returned topic graph is undefined`)
	//         }
	//
	//         let resp = convertDTOToTopicGraph(response.getGraph() as TopicRelationshipGraphDTO)
	//
	//         if (!isError(resp)) {
	//             return resp as TopicRelationshipData
	//         } else {
	//             return NewUIError("CreateTopicRelationships", InternalErrorTypes.CreateTopicGraph, `failed to create topic graph: base topic (Value = topic: ${topic})`, undefined, resp as InternalError)
	//         }
	//     } catch (err) {
	//         return NewUIError("CreateTopicRelationships", InternalErrorTypes.CreateTopicGraph, `failed to create topic graph: base topic (Value = topic: ${topic}) - Err(Value = ${err})`);
	//     }
	// }
	
	DeleteRelationship = async (
		id1: string,
		id2: string,
		rel: TopicRelationshipEnumDTO
	): Promise<null | IUIError> => {
		let req = new DeleteTopicRelationshipRequest();
		req.setUserid(new UUID_DTO().setValue(getUserId()));
		req.setTopicid1(new UUID_DTO().setValue(id1));
		req.setTopicid2(new UUID_DTO().setValue(id2));
		req.setRelationship(rel);
		
		try {
			const response: DeleteTopicRelationshipResponse =
				await topicClient.deleteTopicRelationship(req);
			return null;
		} catch (err) {
			return NewUIError(
				"DeleteRelationship",
				InternalErrorTypes.CreateTopicGraph,
				`failed to delete relationship (ID1 = ${id1} - ID2 = ${id2} - err: (Value = ${err})`,
				undefined
			);
		}
	};
	
	async FetchChildren(parentId: string, depth: number = 1): Promise<Result<TopicRelationshipData, UIErrorV2>> {
		if (!IsUUIDValid(parentId)) {
			return Err(NewUIErrorV2(ActionType.List, EntityKind.TopicRelation, "invalid parentId", "invalid parentId"))
		}
		
		if (depth > 3) {
			depth = 3
		}
		
		let req: GetTopicChildrenRequest = new GetTopicChildrenRequest();
		req.setDepth(depth);
		req.setParentId(new UUID_DTO().setValue(parentId));
		
		try {
			let resp: GetTopicChildrenResponse = await topicClient.listTopicChildren(req)
			
			if (resp.getGraph() === undefined) {
				return Err(NewUIErrorV2(ActionType.List, EntityKind.TopicRelation, "response topicGraph is undefined", "response topicGraph is undefined"))
			}
			
			const graph = convertDTOToTopicGraph(
				resp.getGraph() as TopicRelationshipGraphDTO
			);
			
			return graph
		} catch (e) {
			const err = UIErrorV2.fromException(e, ActionType.List, EntityKind.TopicRelation)
			return Err(err)
		}
	}
}

/**
 * Returns Topics that are not children of this card. Including the subject This should be paired with the getViableChildren method call
 * that also looks at older generation relationships to prevent cycles
 * @param graph
 * @param subject
 */
export const getAvailableChildren = (
	graph: TopicGraph,
	subject: Topic,
	topics: Topic[]
): Topic[] => {
	let topicRelations: TopicRelation[] | undefined = graph.relationships.get(
		subject.id
	);
	
	let availableChildren: Topic[] = [];
	
	if (topicRelations) {
		// Add self
		let self: TopicRelation = {
			id: subject.id,
			relationship: TopicRelationshipEnumDTO.PARENTCHILD,
		};
		topicRelations.push(self);
		
		let contains = false;
		topics.forEach((topic) => {
			for (const relation of topicRelations!) {
				if (topic.id === relation.id) {
					contains = true;
					break;
				}
			}
			
			if (!contains) {
				availableChildren.push(topic);
			}
			contains = false;
		});
	}
	
	return availableChildren;
};


/**
 * Returns a set of viable children for a parent. This prevents parents from adding their parents as children. The backend will
 * add further validation to thi.s
 * @param graph
 * @param parent
 * @param topics
 */
export const getViableChildren = (
	graph: TopicGraph,
	parent: Topic,
	topics: Topic[]
): Topic[] => {
	let invalidChildren: Map<string, null> = new Map();
	
	graph.relationships.forEach((children, relParent) => {
		let res = children.find(
			(topic) =>
				topic.id === parent.id &&
				topic.relationship === TopicRelationshipEnumDTO.PARENTCHILD
		);
		
		if (res) {
			// this parent cannot be a valid child as well as all its parents
			invalidChildren.set(relParent, null);
			
			// TODO we also need to scan this ones parents
		}
	});
	
	return Array.from(graph.topics.values()).filter(
		(topic) => !invalidChildren.has(topic.id)
	);
};

export const convertTopictoSimpleDisplayItem = (
	topic: Topic
): SimpleDisplayItem => {
	return {
		id: topic.id,
		title: topic.topic,
		color: topic.color,
	};
};

// export const convertDTOToTopic = (topic: TopicDTO): Topic | InternalError => {
//     let id = ""
//     let userId = ""
//
//     if (topic.getId()) {
//         if (topic.getId()!.getValue()) {
//             id = topic.getId()!.getValue()
//         } else {
//             return NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topicID is empty '' - topic: ${topic}"`)
//         }
//     } else {
//         return NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topicID is undefined '' - topic: ${topic}"`)
//     }
//
//     if (topic.getUserid()) {
//         if (topic.getUserid()!.getValue()) {
//             userId = topic.getUserid()!.getValue()
//         } else {
//             return NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topic userID is empty '' - topic: ${topic}"`)
//         }
//     } else {
//      return   NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topic userID is undefined '' - topic: ${topic}"`)
//     }
//
//     if (!topic.getTopic()) {
//         return   NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topic string is empty '' - topic: ${topic}"`)
//     }
//
//     if (!topic.getCreatedon()) {
//       return NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topic created date is empty '' - topic: ${topic}"`)
//     }
//
//     if (!topic.getUpdatedon()) {
//       return NewInternalError( "sanitizeTopic", InternalErrorTypes.InvalidTopic, `topic updated date is empty '' - topic: ${topic}"`)
//     }
//
//     const createdOn = convertTimestampToDate(topic.getCreatedon()!)
//     const updatedOn = convertTimestampToDate(topic.getUpdatedon()!)
//     const archivedOn: Date | undefined = (topic.getArchivedon() ? convertTimestampToDate(topic.getArchivedon()!) : undefined)
//
//      return  {
//         id: id,
//         userId: userId,
//         topic: topic.getTopic(),
//         color: topic.getColor(),
//         description: undefined,
//         createdOn: createdOn,
//         updatedOn: updatedOn,
//         archivedOn: archivedOn
//     }
// }
