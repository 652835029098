import {ActionType, InternalErrorTypes, isError, IUIError, NewUIErrorV2} from "./cartaError";
import {getUserId} from "./AuthService";
import {
	BaseModel,
	DTOCreatorRequestType,
	DTOCreatorResponseType, EntityKind,
	IGRPCReviewService,
	IGRPCService, IReviewRespStat, IReviewRespStatDTO
} from "../model/BaseModel";
import {Err, Ok, Result} from "../utils/result";
import {ListOptionsRequestDTO, UUID_DTO} from "../proto/utils_pb";
import { BaseService } from "./BaseService";
import {IsUUIDValid} from "../utils/utils";
import {IReceiveOnlyModel} from "../model/model";

export interface IReviewBaseService<MODEL, SUB_MODEL, MODEL_STAT> {
	// Save allows the user to save the review with the associated cards that have been reviewed
	// Save(model: Model, cards: T[]): Promise<Result<Model, IUIError>>;
	// Complete provides the user with the ability to complete the review
	Complete(model: MODEL): Promise<Result<MODEL, IUIError>>;
	// ListCards allows the user to list the cards associated with the review
	ListCards(review_id: string): Promise<Result<SUB_MODEL[], IUIError>>;
	// SaveCard allows the user to save the card (usually used to update the card metadata)
	SaveCard(review_id: string, card: SUB_MODEL): Promise<Result<SUB_MODEL, IUIError>>;
	// GetStats allows the user to get the stats associated with the review
	GetStat(review_id: string): Promise<Result<MODEL_STAT, IUIError>>;
}

export abstract class ReviewBaseService<
	MODEL extends BaseModel<MODEL, MODEL_DTO>,
	MODEL_DTO,
	SUB_MODEL extends BaseModel<SUB_MODEL, SUB_MODEL_DTO>,
	SUB_MODEL_DTO,
	MODEL_STAT extends IReceiveOnlyModel<MODEL_STAT, MODEL_STAT_DTO>,
	MODEL_STAT_DTO,
	CONFIG_DTO,
	MODEL_REVIEW_CLIENT extends  IGRPCReviewService<MODEL_DTO, SUB_MODEL_DTO, MODEL_STAT_DTO, CONFIG_DTO, DTOCreatorRequestType, DTOCreatorResponseType<MODEL_DTO>>>
	extends BaseService<MODEL, MODEL_DTO, MODEL_REVIEW_CLIENT>
	implements IReviewBaseService<MODEL, SUB_MODEL, MODEL_STAT> {
	
	client: MODEL_REVIEW_CLIENT
	
	protected constructor(client: MODEL_REVIEW_CLIENT,  private modelConstructor1: new () => MODEL, private cardConstructor: new () => SUB_MODEL, private statConstructor: new () => MODEL_STAT) {
		super(client, modelConstructor1);
		this.client = client;
	}
	
	async ListCards(review_id: string): Promise<Result<SUB_MODEL[], IUIError>> {
		const actionType = ActionType.ListReviewCards
		
		if (!IsUUIDValid(review_id)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewCard,
					undefined,
					`invalid id: ${review_id}`
				)
			)
		}
		
		const req: DTOCreatorRequestType = this.client.setupListCardsReq(review_id)
		
		try {
			const response: SUB_MODEL_DTO[] | undefined = await this.client.listCards(
				req,
			);
			
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						new this.cardConstructor().TYPE,
						undefined,
						`entity response is undefined`
					)
				)
			}
			
			let items: SUB_MODEL[] = []
			
			response.map((dto: SUB_MODEL_DTO) => {
				let newM = new this.cardConstructor()
				const err = newM.fromDTO(dto)
				if (!err) {
					items.push(newM)
				} else {
					return Err(
						NewUIErrorV2(
							ActionType.ConvertToDTO,
							newM.TYPE,
							err as IUIError,
							`failed to convert returned entity: ${newM}`,
						)
					)
				}
			})
			
			return Ok(items)
		} catch (err) {
			return Err(
				NewUIErrorV2(
					actionType,
					new this.cardConstructor().TYPE,
					err as IUIError
				))
		}
	}
	
	async ListCardsByConfig(review_id: string, config: CONFIG_DTO): Promise<Result<SUB_MODEL[], IUIError>> {
		const actionType = ActionType.ListReviewCards
		
		if (!IsUUIDValid(review_id)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewCard,
					undefined,
					`invalid id: ${review_id}`
				)
			)
		}
		
		const req: DTOCreatorRequestType = this.client.setupListCardsByConfigReq(review_id, config)
		
		try {
			const response: SUB_MODEL_DTO[] | undefined = await this.client.listCardsByConfig(
				req,
			);
			
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						new this.cardConstructor().TYPE,
						undefined,
						`entity response is undefined`
					)
				)
			}
			
			let items: SUB_MODEL[] = []
			
			response.map((dto: SUB_MODEL_DTO) => {
				let newM = new this.cardConstructor()
				const err = newM.fromDTO(dto)
				if (!err) {
					items.push(newM)
				} else {
					return Err(
						NewUIErrorV2(
							ActionType.ConvertToDTO,
							newM.TYPE,
							err as IUIError,
							`failed to convert returned entity: ${newM}`,
						)
					)
				}
			})
			
			return Ok(items)
		} catch (err) {
			return Err(
				NewUIErrorV2(
					actionType,
					new this.cardConstructor().TYPE,
					err as IUIError
				))
		}
	}
	
	
	async Complete(m: MODEL): Promise<Result<MODEL, IUIError>> {
		const actionType = ActionType.Complete
		
		m.userId = getUserId()
		let validation = m.customValidate();
		
		if (isError(validation)) {
			return Err(
				NewUIErrorV2(
					ActionType.Validate,
					m.TYPE,
					`failed to validate: ${validation}`,
				)
			)
		}
		
		const dto = m.intoDTO()
		
		if (isError(dto)) {
			return Err(
				NewUIErrorV2(
					ActionType.ConvertToDTO,
					m.TYPE,
					`failed to convert: ${dto}`,
				)
			)
		}
		
		const req: DTOCreatorRequestType = this.client.setupCompleteReq(dto as MODEL_DTO)
		
		try {
			const response: MODEL_DTO | undefined = await this.client.complete(
				req,
			);
			
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						m.TYPE,
						undefined,
						`created entity response is undefined`
					)
				)
			}
			
			const err = m.fromDTO(response)
			
			if (!err) {
				return Ok(m);
			} else {
				return Err(
					NewUIErrorV2(
						ActionType.ConvertFromDTO,
						m.TYPE,
						err as IUIError,
						`failed to convert returned entity: ${JSON.stringify(m)}`,
					)
				)
			}
		} catch (err) {
			return Err(
				NewUIErrorV2(
					actionType,
					m.TYPE,
					err as IUIError
				))
		}
	}
	
	async SaveCard(reviewId: string, card: SUB_MODEL): Promise<Result<SUB_MODEL, IUIError>> {
		const actionType = ActionType.SaveReviewCards
		
		if (!IsUUIDValid(reviewId)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewCard,
					undefined,
					`invalid reviewId: ${reviewId}`
				)
			)
		}
		
		let sanitize = card.sanitize();
		if (isError(sanitize)) {
			return Err(
				NewUIErrorV2(
					ActionType.Sanitize,
					new this.cardConstructor().TYPE,
					`failed to sanitize: ${sanitize}`,
				)
			)
		}
		
		let validation = (sanitize as SUB_MODEL).customValidate();
		
		if (isError(validation)) {
			return Err(
				NewUIErrorV2(
					ActionType.Validate,
					new this.cardConstructor().TYPE,
					`failed to validate: ${validation}`,
				)
			)
		}
		
		const dto = card.intoDTO()
		
		if (isError(dto)) {
			return Err(
				NewUIErrorV2(
					ActionType.ConvertToDTO,
					new this.cardConstructor().TYPE,
					`failed to convert: ${dto}`,
				)
			)
		}
		
		const req: DTOCreatorRequestType = this.client.setupSaveCardReq(reviewId, dto as SUB_MODEL_DTO)
		
		try {
			const response: SUB_MODEL_DTO | undefined = await this.client.saveCard(req);
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						new this.cardConstructor().TYPE,
						undefined,
						`list entity response is undefined`
					)
				)
			}
			
			let newM = new this.cardConstructor()
			const err = newM.fromDTO(response as SUB_MODEL_DTO)
			if (err) {
				return Err(
					NewUIErrorV2(
						ActionType.ConvertToDTO,
						newM.TYPE,
						err as IUIError,
						`failed to convert returned entity: ${newM}`,
					)
				)
			}
			
			return Ok(newM)
		} catch (err) {
			return Err(
				NewUIErrorV2(
					actionType,
					new this.cardConstructor().TYPE,
					err as IUIError
				))
		}
	}
	
	async GetStat(reviewId: string): Promise<Result<MODEL_STAT, IUIError>> {
		const actionType = ActionType.GetStats
		
		if (!IsUUIDValid(reviewId)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewStat,
					undefined,
					`invalid reviewId: ${reviewId}`
				)
			)
		}
		
		const req: DTOCreatorRequestType = this.client.setupGetStatsReq(reviewId)
		
		try {
			const response: MODEL_STAT_DTO | undefined = await this.client.getStats(
				req,
			);
			
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						new this.modelConstructor1().TYPE,
						undefined,
						`entity response is undefined`
					)
				)
			}
			
			let newM = new this.statConstructor()
			
			const err = newM.fromDTO(response as MODEL_STAT_DTO)
			
			if (!err) {
				return Ok(newM);
			} else {
				return Err(
					NewUIErrorV2(
						ActionType.ConvertToDTO,
						newM.TYPE,
						err as IUIError,
						`failed to convert returned entity: ${newM}`,
					)
				)
			}
		} catch (err) {
			return Err(
				NewUIErrorV2(
					actionType,
					new this.statConstructor().TYPE,
					err as IUIError
				))
		}
	}
}