import {TopicServicePromiseClient} from "../proto/topic_grpc_web_pb";
import {CARTA_PROXY_URL} from "../consts";
import {unifiedInterceptor} from "../utils/utils";
import {StatsServicePromiseClient} from "../proto/stats_grpc_web_pb";

export const SimpleUnaryInterceptor = function () {
};
export const topicClient = new TopicServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {'withCredentials': true, 'unaryInterceptors': [unifiedInterceptor]}
);
export const statsClient = new StatsServicePromiseClient(
	CARTA_PROXY_URL!,
	null,
	{'withCredentials': true, 'unaryInterceptors': [unifiedInterceptor]}
);