import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import {GuardProps} from 'types/auth';
import {observer} from "mobx-react";
import {useStores} from "hooks/useStores";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = observer(({children}: GuardProps) => {
    // TODO: we should probably use the useAuth here?
    const {authenticationState} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const {authStore} = useStores();

    useEffect(() => {
        handleIsLogged()
            .then((res) => {
                if (!res) {
                    navigate('/login', {
                        state: {
                            from: location.pathname
                        },
                        replace: true
                    });
                } else {
                }
            })
    }, [navigate, location]);


    const handleIsLogged = async () =>  await authStore.AuthCheck()

    return children;
});

export default AuthGuard;
