import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "./cartaError";
import {ReviewSM2ServicePromiseClient} from "../proto/reviewSM2_grpc_web_pb";
import {
    CompleteReviewSM2Request,
    CreateReviewSM2Request,
    DeleteReviewSM2Request,
    GetReviewSM2Request,
    ListCardsToReviewByConfigRequest,
    ListReviewSM2ByIdsRequest,
    ListReviewSM2Request,
    ListSM2CardsRequest,
    ReviewSM2DTO,
    ReviewSM2FilterConfigDTO,
    SaveReviewSM2SessionCardsRequest,
    UpdateReviewSM2Request,
} from "../proto/reviewSM2_pb";
import {ReviewSM2} from "../model/ReviewSM2";
import {ListOptionsRequestDTO, ListOptionsResponseDTO, ProgressStateEnumDTO, UUID_DTO,} from "../proto/utils_pb";
import {ReviewCard} from "../model/ReviewCard";
import {IReview, IReviewStat} from "../model/Review";
import {
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    EntityKind,
    IGRPCReviewService,
    IGRPCService, IReviewRespStatDTO,
    ListResponse,
} from "../model/BaseModel";
import grpcWeb from "grpc-web";
import {ReviewBaseService} from "./ReviewBaseService";
import {Err, Ok, Result} from "../utils/result";
import {TagDTO} from "proto/tag_pb";
import {CARTA_PROXY_URL} from "consts";
import {ReviewCardDTO} from "../proto/review_pb";
import {GetReviewSM2StatsRequest, ReviewStatDTO} from "../proto/stats_pb";
import {unifiedInterceptor} from "../utils/utils";
import {ReviewManualDTO} from "../proto/reviewManual_pb";
import {ReviewStat} from "../model/ReviewStat";
import {statsClient} from "../stores/clients";

const reviewSM2Client = new ReviewSM2ServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor]
});

export interface ReviewSM2Save {
    review: ReviewSM2;
    availableCards: ReviewCard[];
}

export interface ReviewSM2SaveCards {
    currentCards: ReviewCard[];
    availableCards: ReviewCard[];
}

export interface CompleteReviewSM2 {
    review: ReviewSM2;
    stats: ReviewStat;
}

export interface ICompleteReview {
    review: IReview;
    stats: IReviewStat;
}

export class ReviewSM2Service extends ReviewBaseService<
    ReviewSM2,
    ReviewSM2DTO,
    ReviewCard,
    ReviewCardDTO,
    ReviewStat,
    ReviewStatDTO,
    ReviewSM2FilterConfigDTO,
    ReviewSM2GRPC
> {
    constructor() {
        super(new ReviewSM2GRPC(), ReviewSM2, ReviewCard, ReviewStat);
    }
    
    CreateReview = async (
        review: ReviewSM2,
        config: ReviewSM2FilterConfigDTO,
        firstCard: ReviewCard
    ): Promise<Result<ReviewSM2, IUIError>> => {
        const now = new Date();
        review.updatedOn = now;
        review.progressState = ProgressStateEnumDTO.IN_PROGRESS;
        firstCard.updatedOn = now;
        
        let reviewSanitized = review.sanitize();
        
        const err = reviewSanitized.customValidate();
        if (isError(err)) {
            return Err(
                NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.ReviewSM2,
                    `failed to validate: ${reviewSanitized.id} - ${err}`
                )
            );
        }
        
        const reviewDTO = reviewSanitized.intoDTO();
        if (isError(reviewDTO)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    EntityKind.ReviewSM2,
                    `failed to convert: ${reviewSanitized.id} - ${reviewDTO}`
                )
            );
        }
        
        let firstCardSanitized = firstCard.sanitize();
        const err2 = firstCardSanitized.customValidate();
        if (isError(err2)) {
            return Err(
                NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.ReviewSM2Card,
                    `failed to validate: ${firstCardSanitized.id} - ${err2}`
                )
            );
        }
        
        const reviewCardDTO = firstCardSanitized.intoDTO();
        if (isError(reviewCardDTO)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    EntityKind.ReviewSM2Card,
                    `failed to convert: ${firstCardSanitized.id} - ${reviewCardDTO}`
                )
            );
        }
        
        // TODO: I need to catch these errors and return them
        let req = new CreateReviewSM2Request();
        req.setReview(reviewDTO as ReviewSM2DTO);
        req.setCardsList([firstCardSanitized.intoDTO() as ReviewCardDTO]);
        
        try {
            const res = await this.client.create(req);
            if (res === undefined) {
                return Err(
                    NewUIErrorV2(
                        ActionType.Create,
                        EntityKind.ReviewSM2,
                        `failed to create`
                    )
                );
            }
            
            let review = new ReviewSM2();
            const err = review.fromDTO(res);
            
            if (err) {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertFromDTO,
                        EntityKind.ReviewSM2,
                        `failed to convert: ${res} - ${err}`
                    )
                );
            }
            
            return Ok(review);
        } catch (err) {
            return Err(
                NewUIErrorV2(
                    ActionType.Create,
                    EntityKind.ReviewSM2,
                    `[panic]: failed to create: ${review.id} - ${err}`
                )
            );
        }
    };
    
    ResumeReview = async (
        review: ReviewSM2,
        config: ReviewSM2FilterConfigDTO
    ): Promise<
        Result<[review: ReviewSM2, reviewCards: ReviewCard[]], IUIError>
    > => {
        let obj: [review: ReviewSM2, reviewCards: ReviewCard[]] = [review, []];
        
        const now = new Date();
        review.updatedOn = now;
        review.progressState = ProgressStateEnumDTO.IN_PROGRESS;
        
        // TODO: I need to catch these errors and return them
        try {
            const updateReview = await this.Update(review);
            
            
            if (updateReview.ok) {
                obj[0] = updateReview.value;
            } else {
                return Err(
                    NewUIError(
                        "ResumeReview",
                        InternalErrorTypes.ResumeReviewSM2,
                        `failed to update review: ${review.id} to resume - ${updateReview.error}`
                    )
                );
            }
        } catch (err) {
            return Err(
                NewUIError(
                    "ResumeReview",
                    InternalErrorTypes.ResumeReviewSM2,
                    `failed to update review: ${review.id} to resume - ${err}`
                )
            );
        }
        
        try {
            const fetchCards = await this.ListCardsByConfig(review.id, config);
            
            
            if (fetchCards.ok) {
                obj[1] = fetchCards.value;
            } else {
                return Err(
                    NewUIError(
                        "ResumeReview",
                        InternalErrorTypes.ResumeReviewSM2,
                        `failed to fetch cards for review: ${review.id} to resume - ${fetchCards.error}`
                    )
                );
            }
        } catch (e) {
            return Err(
                NewUIError(
                    "ResumeReview",
                    InternalErrorTypes.ResumeReviewSM2,
                    `failed to fetch cards for review: ${review.id} to resume - ${e}`
                )
            );
        }
        
        return Ok(obj);
    };
    
    // CompleteReview = async (
    // 	review: ReviewSM2
    // ): Promise<CompleteReviewSM2 | IUIError> => {
    // 	// TODO: Setup Nested Sanitize for cards
    // 	review = review.sanitize();
    //
    // 	let dto = review.convertReviewSM2ToDTO();
    //
    // 	let req = new CompleteReviewSM2Request();
    // 	req.setReview(dto);
    //
    // 	let token = getAuthToken();
    // 	let meta = {"x-grpc-authorization": token};
    //
    // 	try {
    // 		const response: CompleteReviewSM2Response =
    // 			await sm2Client.completeReviewSM2(req);
    //
    // 		if (response.getReview() === undefined) {
    // 			return NewUIError(
    // 				"CompleteReviewSM2",
    // 				InternalErrorTypes.CompleteReviewSM2,
    // 				`returned reviewSM2 is undefined`
    // 			);
    // 		}
    // 		if (response.getStats() === undefined) {
    // 			return NewUIError(
    // 				"CompleteReviewSM2",
    // 				InternalErrorTypes.CompleteReviewSM2,
    // 				`returned reviewSM2 stats is undefined`
    // 			);
    // 		}
    //
    // 		let review = new ReviewSM2();
    // 		const errT = review.fromDTO(response.getReview()!);
    // 		if (errT) {
    // 			return NewUIError(
    // 				"CompleteReviewSM2",
    // 				InternalErrorTypes.CompleteReviewSM2,
    // 				`failed to get reviewSM2 after completion: base review (Value = review: ${review})`,
    // 				undefined,
    // 				errT
    // 			);
    // 		}
    //
    // 		let stats = new ReviewSM2Stat();
    // 		const errY = stats.fromDTO(response.getStats()!);
    // 		if (errY) {
    // 			return NewUIError(
    // 				"CompleteReviewSM2",
    // 				InternalErrorTypes.CompleteReviewSM2,
    // 				`failed to get reviewSM2 stat after completion: base review (Value = review: ${review})`,
    // 				undefined,
    // 				errY as IUIError
    // 			);
    // 		}
    //
    // 		return {
    // 			review: review,
    // 			stats: stats,
    // 		};
    // 	} catch (err) {
    // 		return NewUIError(
    // 			"CompleteReviewSM2",
    // 			InternalErrorTypes.CompleteReviewSM2,
    // 			`failed to create reviewSM2: base review (Value = review: ${JSON.stringify(
    // 				review
    // 			)}) - Err(Value = ${JSON.stringify(err)})`
    // 		);
    // 	}
    // };
    
    // ListReviews = async (
    // 	opts: ListOptionsRequestDTO
    // ): Promise<ReviewSM2[] | IUIError> => {
    // 	let req = new ListReviewSM2Request();
    // 	req.setOpts(opts);
    //
    // 	try {
    // 		const response: ListReviewSM2Response = await sm2Client.list(
    // 			req,
    // 		);
    //
    // 		let reviews: ReviewSM2[] = [];
    //
    // 		response.getReviewList().forEach((item, index) => {
    // 			let res = new ReviewSM2();
    // 			res.fromDTO(item);
    //
    // 			if (!isError(res)) {
    // 				reviews.push(res as ReviewSM2);
    // 			} else {
    // 				// LogE(res);
    // 			}
    // 		});
    //
    // 		return reviews;
    // 	} catch (err) {
    // 		return NewUIError(
    // 			"ListReviews",
    // 			InternalErrorTypes.CreateReviewSM2,
    // 			`failed to list reviewSM2: (Value = opts: ${JSON.stringify(
    // 				opts
    // 			)}) - Err(Value = ${JSON.stringify(err)})`
    // 		);
    // 	}
    // };
    
    // ListAvailableSM2Cards = async (
    // 	opts: ReviewSM2FilterConfigDTO
    // ): Promise<ReviewSM2Card[] | IUIError> => {
    // 	let req = new ListAvailableSM2CardsRequest();
    // 	// req.setReviewid()
    // 	req.setOpts(opts);
    //
    // 	let token = getAuthToken();
    // 	let meta = {"x-grpc-authorization": token};
    //
    // 	try {
    // 		const response: ListAvailableSM2CardsResponse =
    // 			await sm2Client.listAvailableSM2Cards(req);
    //
    // 		let cards: ReviewSM2Card[] = [];
    //
    // 		response.getCardsList().forEach((dto, index) => {
    // 			let card = new ReviewSM2Card();
    // 			let res = card.fromDTO(dto);
    //
    // 			if (res) {
    // 				// LogE(res as InternalError);
    // 			}
    //
    // 			cards.push(card);
    // 		});
    //
    // 		return cards;
    // 	} catch (err) {
    // 		return NewUIError(
    // 			"ListNewReviewSM2CardsRequest",
    // 			InternalErrorTypes.ListSM2CardsForNewReview,
    // 			`failed to list new cards for review:  Err(Value = ${JSON.stringify(
    // 				err
    // 			)})`
    // 		);
    // 	}
    // };
    
    // SaveReviewSM2 = async (
    // 	review: ReviewSM2
    // ): Promise<ReviewSM2Save | IUIError> => {
    // 	review = review.sanitize();
    //
    // 	let dto = review.convertReviewSM2ToDTO();
    //
    // 	let req = new SaveReviewSM2Request();
    // 	req.setReview(dto);
    //
    // 	let token = getAuthToken();
    // 	let meta = {"x-grpc-authorization": token};
    //
    // 	try {
    // 		const response: SaveReviewSM2Response = await sm2Client.save(
    // 			req,
    // 			meta
    // 		);
    //
    // 		if (response.getReview() === undefined) {
    // 			return NewUIError(
    // 				"SaveReviewSM2",
    // 				InternalErrorTypes.SaveReviewSM2,
    // 				`returned reviewSM2 is undefined`
    // 			);
    // 		}
    // 		if (response.getAvailablecardsList() === undefined) {
    // 			return NewUIError(
    // 				"SaveReviewSM2",
    // 				InternalErrorTypes.SaveReviewSM2,
    // 				`returned reviewSM2 cards list undefined`
    // 			);
    // 		}
    //
    // 		let review = new ReviewSM2();
    // 		let err = review.fromDTO(response.getReview()!);
    // 		if (err) {
    // 			return NewUIError(
    // 				"SaveReviewSM2",
    // 				InternalErrorTypes.SaveReviewSM2,
    // 				`failed to save reviewSM2: base review (Value = review: ${review.id})`,
    // 				undefined,
    // 				err
    // 			);
    // 		}
    //
    // 		let cards: ReviewSM2Card[] = [];
    //
    // 		response.getAvailablecardsList().forEach((dto) => {
    // 			let card = new ReviewSM2Card();
    // 			const err = card.fromDTO(dto);
    //
    //
    // 			if (err) {
    // 				return NewUIError(
    // 					"SaveReviewSM2",
    // 					InternalErrorTypes.SaveReviewSM2,
    // 					`failed to convert available card reviewSM2: (Value = review: ${dto.getId()})`,
    // 					undefined,
    // 					err
    // 				);
    // 			}
    // 			cards.push(card);
    // 		});
    //
    // 		return {
    // 			availableCards: cards,
    // 			review: review,
    // 		};
    // 	} catch (err) {
    // 		return NewUIError(
    // 			"SaveReviewSM2",
    // 			InternalErrorTypes.SaveReviewSM2,
    // 			`failed to save reviewSM2: base review (Value = review: ${JSON.stringify(
    // 				review
    // 			)}) - Err(Value = ${JSON.stringify(err)})`
    // 		);
    // 	}
    // };
    
    // SaveReviewSM2SessionCards = async (
    // 	reviewId: string,
    // 	cards: ReviewSM2Card[]
    // ): Promise<ReviewSM2SaveCards | IUIError> => {
    // 	const origin = "SaveReviewSM2SessionCards"
    // 	const errorKind = InternalErrorTypes.InvalidReviewSM2Card
    //
    // 	let dto: ReviewCardDTO[] = []
    // 	cards.forEach((card) => {
    // 		const cardDTO = card.intoDTO();
    // 		if (isError(cardDTO)) {
    // 			LogError(origin, errorKind, ``, cardDTO as IUIError)
    // 		} else {
    // 			dto.push(cardDTO as ReviewCardDTO)
    // 		}
    // 	})
    //
    // 	let req = new SaveReviewSM2SessionCardsRequest();
    // 	req.setReviewid(new UUID_DTO().setValue(reviewId));
    // 	req.setSm2cardsList(dto)
    //
    // 	let token = getAuthToken();
    // 	let meta = {"x-grpc-authorization": token};
    //
    // 	try {
    // 		const response: SaveReviewSM2SessionCardsResponse =
    // 			await sm2Client.saveReviewSM2SessionCards(req);
    //
    // 		if (response.getCardsList() === undefined) {
    // 			return NewUIError(
    // 				"SaveReviewSM2SessionCards",
    // 				InternalErrorTypes.SaveReviewSM2,
    // 				`returned reviewSM2Card is undefined`
    // 			);
    // 		}
    // 		if (response.getAvailableList() === undefined) {
    // 			return NewUIError(
    // 				"SaveReviewSM2",
    // 				InternalErrorTypes.SaveReviewSM2,
    // 				`returned reviewSM2 cards list undefined`
    // 			);
    // 		}
    //
    // 		let sm2_cards: ReviewSM2Card[] = [];
    // 		let available_cards: ReviewSM2Card[] = [];
    //
    // 		response.getCardsList().forEach((dto) => {
    // 			let sm2_card = new ReviewSM2Card();
    // 			const err = sm2_card.fromDTO(dto);
    //
    // 			if (err) {
    // 				return NewUIError(
    // 					"SaveReviewSM2SessionCards",
    // 					InternalErrorTypes.SaveReviewSM2,
    // 					`failed to save reviewSM2Card: base review (Value = reviewCardDTO: ${dto})`,
    // 					undefined,
    // 					err
    // 				);
    // 			} else {
    // 				sm2_cards.push(sm2_card);
    // 			}
    // 		});
    //
    // 		response.getAvailableList().forEach((dto) => {
    // 			let sm2_card = new ReviewSM2Card();
    // 			const err = sm2_card.fromDTO(dto);
    //
    // 			if (err) {
    // 				return NewUIError(
    // 					"SaveReviewSM2SessionCards",
    // 					InternalErrorTypes.SaveReviewSM2,
    // 					`failed to save reviewSM2Card: base review (Value = reviewCardDTO: ${dto})`,
    // 					undefined,
    // 					err
    // 				);
    // 			} else {
    // 				available_cards.push(sm2_card);
    // 			}
    // 		});
    //
    // 		return {
    // 			currentCards: sm2_cards,
    // 			availableCards: available_cards,
    // 		};
    // 	} catch (err) {
    // 		return NewUIError(
    // 			"SaveReviewSM2SessionCards",
    // 			InternalErrorTypes.SaveReviewSM2,
    // 			`failed to save reviewSM2Card: base review (Value = review: ${JSON.stringify(
    // 				cards
    // 			)}) - Err(Value = ${JSON.stringify(err)})`
    // 		);
    // 	}
    // };
}

export class ReviewSM2GRPC
    implements IGRPCService<
        ReviewSM2DTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<ReviewSM2DTO>
    >,
        IGRPCReviewService<
            ReviewSM2DTO,
            ReviewCardDTO,
            ReviewStatDTO,
            ReviewSM2FilterConfigDTO,
            DTOCreatorRequestType,
            DTOCreatorResponseType<ReviewSM2DTO>
        > {
    setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
        let req = new ListReviewSM2ByIdsRequest();
        req.setIdsList(ids);
        
        return req;
    }
    
    async listByIDs(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ReviewSM2DTO> | undefined> {
        let x = await reviewSM2Client.listByIds(req as ListReviewSM2ByIdsRequest, meta);

        return {
            items: x.getItemsList() as ReviewSM2DTO[],
            info: x.getInfo(),
        } as ListResponse<ReviewSM2DTO>;
    }
    
    async create(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewSM2DTO | undefined> {
        const x = await reviewSM2Client.create(req as CreateReviewSM2Request, meta);
        return x.getReview() as ReviewSM2DTO;
    }
    
    setupCreateReq(dto: ReviewSM2DTO): DTOCreatorRequestType {
        let req = new CreateReviewSM2Request();
        req.setReview(dto);
        
        return req;
    }
    
    async list(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ReviewSM2DTO> | undefined> {
        const x = await reviewSM2Client.list(req as ListReviewSM2Request, meta);

        return {
            items: x.getItemsList() as ReviewSM2DTO[],
            info: x.getInfo(),
        } as ListResponse<ReviewSM2DTO>;
    }
    
    setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
        let req = new ListReviewSM2Request();
        req.setOpts(dto);
        
        return req;
    }
    
    delete(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<void> {
        return reviewSM2Client.delete(req as DeleteReviewSM2Request, meta).then((x) => {
            return;
        });
    }
    
    setupDeleteReq(id: string): DTOCreatorRequestType {
        let req = new DeleteReviewSM2Request();
        req.setReviewid(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    get(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewSM2DTO | undefined> {
        return reviewSM2Client.get(req as GetReviewSM2Request, meta).then((x) => {
            return x.getReview() as ReviewSM2DTO;
        });
    }
    
    setupGetReq(id: string): DTOCreatorRequestType {
        let req = new GetReviewSM2Request();
        req.setId(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    setupUpdateReq(dto: ReviewSM2DTO): DTOCreatorRequestType {
        let req = new UpdateReviewSM2Request();
        req.setReview(dto);
        
        return req;
    }
    
    update(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewSM2DTO | undefined> {
        return reviewSM2Client.update(req as UpdateReviewSM2Request, meta).then((x) => {
            return x.getReview() as ReviewSM2DTO;
        });
    }
    
    setupCompleteReq(dto: ReviewSM2DTO): DTOCreatorRequestType {
        let req = new CompleteReviewSM2Request();
        req.setReview(dto);
        
        return req;
    }
    
    async complete(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewSM2DTO | undefined> {
        const x = await reviewSM2Client.completeReviewSM2(
            req as CompleteReviewSM2Request,
            meta
        );
        return x.getReview() as ReviewSM2DTO;
    }
    
    setupSaveCardReq(
        review_id: string,
        dto: ReviewCardDTO
    ): DTOCreatorRequestType {
        let req = new SaveReviewSM2SessionCardsRequest();
        req.setSm2cardsList([dto as ReviewCardDTO]);
        req.setReviewid(new UUID_DTO().setValue(review_id));
        
        return req;
    }
    
    async saveCard(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewCardDTO | undefined> {
        try {
            const x = await reviewSM2Client.saveReviewSM2SessionCards(
                req as SaveReviewSM2SessionCardsRequest,
                meta
            );
            
            if (x.getCardsList().length < 1) {
                return undefined;
            }
            
            return x.getCardsList()[0] as ReviewCardDTO;
        } catch (e) {
            
        }
    }
    
    setupGetStatsReq(id: string): DTOCreatorRequestType {
        let req = new GetReviewSM2StatsRequest();
        req.setReviewid(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    async getStats(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewStatDTO | undefined> {
        const x = await statsClient.getReviewSM2Stats(
            req as GetReviewSM2StatsRequest,
            meta
        );
        return x.getStats() as ReviewStatDTO;
    }
    
    setupListCardsReq(id: string): DTOCreatorRequestType {
        let req = new ListSM2CardsRequest();
        req.setReviewid(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    async listCards(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewCardDTO[] | undefined> {
        const x = await reviewSM2Client.listSM2Cards(req as ListSM2CardsRequest, meta);
        return x.getCardsList() as ReviewCardDTO[];
    }
    
    setupListCardsByConfigReq(
        reviewId: string,
        opts: ReviewSM2FilterConfigDTO
    ): DTOCreatorRequestType {
        let req = new ListCardsToReviewByConfigRequest();
        req.setReviewid(new UUID_DTO().setValue(reviewId));
        req.setOpts(opts);
        
        return req;
    }
    
    async listCardsByConfig(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewCardDTO[] | undefined> {
        const x = await reviewSM2Client.listCardsToReviewByConfig(
            req as ListCardsToReviewByConfigRequest,
            meta
        );
        return x.getCardsList() as ReviewCardDTO[];
    }
}
