import {BaseModel, EntityKind} from "../model/BaseModel";
import {ErrorStatusDTO} from "proto/utils_pb";
import * as Sentry from "@sentry/react";
import {Err, Result} from "../utils/result";
import {ENVIRONMENT, GRPC_CARTA_ERROR_HEADER} from "../consts";
import {Metadata, Status} from "grpc-web";
import {Code} from "model/test_utils.test";
import {Identifiable} from "../model/model";

export interface IGrpcError {
	status: Status;
	message: any;
	metadata: Metadata;
	customError: ErrorStatusDTO | undefined;
}

export class GrpcError extends Error {
	grpcStatus: Status;
	grpcMessage: any;
	customError: ErrorStatusDTO | undefined
	metadata: Metadata;
	
	constructor(grpcStatus: Status, grpcMessage: any, grpcMetadata: Metadata) {
		super(`[Code]: ${convertErrorStatusToName(grpcStatus.code)}\n[Status]: ${JSON.stringify(grpcStatus)}\n[Message]: ${grpcMessage}\n[Metadata]: ${JSON.stringify(grpcMetadata)}`);
		this.grpcStatus = grpcStatus;
		this.grpcMessage = grpcMessage;
		this.metadata = grpcMetadata;
		this.customError = GrpcError.extractCustomError(grpcMetadata);
	}
	
	static extractCustomError = (metadata: Metadata): ErrorStatusDTO | undefined => {
		return metadata[GRPC_CARTA_ERROR_HEADER] ? convertNumberToErrorStatus(Number(metadata[GRPC_CARTA_ERROR_HEADER])) : undefined
	}
	
	convertGrpcErrorToJSON = (grpcStatus: Status, grpcMessage: any, grpcMetadata: Metadata): IGrpcError => {
		return {
			status: grpcStatus,
			message: grpcMessage,
			metadata: grpcMetadata,
			customError: GrpcError.extractCustomError(this.metadata),
		}
	}
}

export enum InternalErrorTypes {
	Unimplemented,
	Authentication,
	
	AuthenticationGoogle,
	Internal,
	Unknown,
	InvalidCard,
	InvalidCardDTO,
	InvalidTopic,
	InvalidRelTopic,
	InvalidTag,
	InvalidTagDTO,
	InvalidResource,
	InvalidResourceDTO,
	InvalidReview,
	InvalidReviewCard,
	InvalidReviewCardDTO,
	InvalidReviewSM2Card,
	InvalidReviewSM2CardDTO,
	InvalidUUID,
	InvalidLanguageDTO,
	
	InvalidTopicGraph,
	
	GetTopic,
	GetTag,
	GetCard,
	GetResources,
	GetReview,
	ListTopics,
	ListTopicsByIds,
	ListTopicsForCard,
	ListTags,
	ListCards,
	ListCardsByIds,
	ListReviews,
	ListResources,
	CreateCard,
	CreateReview,
	CreateTag,
	CreateTopic,
	CreateResource,
	UpdateCard,
	UpdateReview,
	UpdateTopic,
	UpdateTag,
	LocalNotFound,
	SaveManualReview,
	SaveReviewManualCard,
	SaveReviewCard,
	GetTopicGraph,
	ConvertTopicGraph,
	SearchTopics,
	SearchCards,
	SearchTags,
	SearchResources,
	ArchiveCard,
	DeleteCard,
	CreateTopicGraph,
	DeleteTopic,
	ListResourcesForTopics,
	CreateReviewSM2,
	CompleteReviewSM2,
	GetReviewSM2,
	DeleteReview,
	DeleteReviewSM2,
	ListReviewSM2,
	ListSM2CardsForNewReview,
	SaveReviewSM2,
	SaveReviewSM2Card,
	StartReviewSM2,
	GetTopicStat,
	ValidateReview,
	GetReviewSM2Stats,
	CardTagRelation,
	CardResourceRelation,
	TopicCardRelation,
	TopicResourceRelation,
	TopicTagRelation,
	TopicRelation,
	FunctionNotImplemented,
	ListReviewCards,
	OrderCards,
	GetCardComposite,
	InvalidCardComposite,
	UpdateCardComposite,
	ListCardComposite,
	MethodUndefined,
	InvalidReviewManualConfig,
	InvalidReviewSM2Config,
	InvalidReviewManualFilterResult,
	CardFilterResult,
	CardLangFilterResult,
	ResumeReviewSM2,
	ResumeReviewManual,
	UpdateCardInReviewManualCard
}

export enum ActionType {
	StripeCreateCheckoutSession = "stripe create checkout session",
	InternetRequest = "internet request",
	StripeProductAndPrice = "stripe product and price",
	Sanitize = "sanitize",
	Create = "create",
	Update = "update",
	Delete = "delete",
	List = "list",
	ListByIDs = "list By IDs",
	Get = "get",
	Search = "search",
	Archive = "archive",
	Save = "save",
	Start = "start",
	Complete = "complete",
	Resume = "resume",
	Convert = "convert",
	Validate = "validate",
	UIValidate = "ui validate",
	ConvertToDTO = "convert to dto",
	GetTopicGraph = "get topic graph",
	ConvertFromDTO = "convert from dto",
	Unimplemented = "unimplemented",
	Combine = "combine",
	ListReviewCards = "list review cards",
	SaveReviewCards = "save review cards",
	GetStats = "get stats",
	UpdateCardInReviewManualCard = "update card in review manual card",
	UpdateCardInReviewSM2Card = "update card in review snm2 card",
	SetOngoingReview = "set ongoing review",
	SetSelectedReview = "set selected review",
	FilterCards = "filter cards",
	MediaUpload = "Media Upload",
	Authenticate = "Authenticate",
	HeartBeat = "HeartBeat",
	Logout = "Logout",
	StripePortalCheckoutSession = "stripe portal checkout session",
	GRPC = "grpc",
	Navigate = "navigate",
	MissingData = "missing data",
}

export enum ErrorCode {
	DTOValidation = 0,
	UndefinedResponse = 1,
}

export interface CartaError extends Error {
	origin?: string;
	errorCode: InternalErrorTypes | ActionType;
	message: string;
}

export interface InternalError extends CartaError {
}


export interface IUIError extends CartaError {
	userMessage?: string;
	description?: string;
}

export class UIError implements IUIError {
	constructor(
		errorCode: InternalErrorTypes,
		message: string,
		err?: any,
		userMessage?: string,
		origin?: string
	) {
		this.errorCode = errorCode;
		this.message = message;
		this.name = errorCode.toString();
		this.origin = origin;
		this.err = err;
		this.userMessage = userMessage;
	}
	
	errorCode: InternalErrorTypes;
	message: string;
	name: string;
	err: any;
	origin?: string;
	userMessage?: string;
}

export function LogErrorToProvider(error: UIErrorV2, ctx?: string, panicMessage?: any): Result<never, UIErrorV2> {
	let context = ctx ? ctx : "Error: ";
	if (ENVIRONMENT !== "production" && ENVIRONMENT !== "staging") {
		console.log(`${context}: `, error);
		return Err(error)
	}
	
	Sentry.captureException(error, {
		extra: {
			code: error.errorCode.toUpperCase(),
			kind: error.kind.toUpperCase(),
			message: `${context}${error.message}`,
			errorStatus: convertErrorStatusToName(error.errorStatus),
			additionalErrors: error.additionalErrors,
			panicMessage: panicMessage,
		},
	});
	
	return Err(error)
}

/**
 * This error will be sent to the UI for the user to view / respond to. DO NOT LEAK SENSITIVE INFORMATION HERE
 * @param origin
 * @param code
 * @param logMessage - This is not shown to the user but is used for internal logging
 * @param userMessage - This optional message can be shown to the user. Ideally the component should decide the message.
 * @param err
 * @constructor
 */
export const NewUIError = (
	origin: string,
	code: InternalErrorTypes,
	logMessage: string,
	userMessage?: string,
	err?: CartaError,
	e?: any
): IUIError => {
	const ie = {
		name: origin,
		origin: origin,
		errorCode: code,
		message: logMessage,
		userMessage: userMessage,
		trace: err,
	} as IUIError;
	
	// LogU(ie);
	
	return ie;
};

/**
 * This error will be sent to the UI for the user to view / respond to. DO NOT LEAK SENSITIVE INFORMATION HERE
 * @constructor
 * @param status
 */

export const convertErrorStatusToName = (status: ErrorStatusDTO | undefined): string => {
	if (status === undefined) {
		return 'NO_ERROR_STATUS';
	}
	
	console.log("convertErrorStatusToName: ", status)
	
	switch (status) {
		case ErrorStatusDTO.AUTH_REDIRECT_LOGIN_WITH_DIFFERENT_PROVIDER:
			return 'AUTH_REDIRECT_LOGIN_WITH_DIFFERENT_PROVIDER';
		case ErrorStatusDTO.AUTH_REDIRECT_LOGIN:
			return 'AUTH_REDIRECT_LOGIN';
		case ErrorStatusDTO.AUTH_REDIRECT_REGISTER:
			return 'AUTH_REDIRECT_REGISTER';
		case ErrorStatusDTO.AUTH_RETRY:
			return 'AUTH_RETRY';
		case ErrorStatusDTO.AUTH_MISSING_EMAIL:
			return 'AUTH_MISSING_EMAIL';
		case ErrorStatusDTO.AUTH_UNVERIFIED_EMAIL:
			return 'AUTH_UNVERIFIED_EMAIL';
		case ErrorStatusDTO.AUTH_INVALID_EMAIL:
			return 'AUTH_INVALID_EMAIL';
		case ErrorStatusDTO.AUTH_EMAIL_EXISTS:
			return 'AUTH_EMAIL_EXISTS';
		case ErrorStatusDTO.AUTH_UNSUPPORTED_PROVIDER:
			return 'AUTH_UNSUPPORTED_PROVIDER';
		case ErrorStatusDTO.AUTH_MISSING_USER_SUBSCRIPTION:
			return 'AUTH_MISSING_USER_SUBSCRIPTION';
		case ErrorStatusDTO.AUTH_MISSING_USER:
			return 'AUTH_MISSING_USER';
		case ErrorStatusDTO.OBJECT_LIMIT_EXCEEDED:
			return 'OBJECT_LIMIT_EXCEEDED';
		default:
			return 'UNKNOWN_ERROR';
	}
}

export const convertNumberToErrorStatus = (status: number): ErrorStatusDTO => {
	switch (status) {
		case 0:
			return ErrorStatusDTO.AUTH_REDIRECT_LOGIN_WITH_DIFFERENT_PROVIDER;
		case 1:
			return ErrorStatusDTO.AUTH_REDIRECT_LOGIN;
		case 2:
			return ErrorStatusDTO.AUTH_REDIRECT_REGISTER;
		case 3:
			return ErrorStatusDTO.AUTH_RETRY;
		case 4:
			return ErrorStatusDTO.AUTH_MISSING_EMAIL;
		case 5:
			return ErrorStatusDTO.AUTH_UNVERIFIED_EMAIL;
		case 6:
			return ErrorStatusDTO.AUTH_INVALID_EMAIL;
		case 7:
			return ErrorStatusDTO.AUTH_EMAIL_EXISTS;
		case 8:
			return ErrorStatusDTO.AUTH_UNSUPPORTED_PROVIDER;
		case 9:
			return ErrorStatusDTO.AUTH_MISSING_USER_SUBSCRIPTION;
		case 10:
			return ErrorStatusDTO.AUTH_MISSING_USER;
		case 11:
			return ErrorStatusDTO.OBJECT_LIMIT_EXCEEDED;
		default:
			return ErrorStatusDTO.UNDEFINED_ERROR;
	}
}

const grpcStatusToString = (code: Code): string => {
	switch (code) {
		case Code.OK:
			return "OK";
		case Code.CANCELLED:
			return "CANCELLED";
		case Code.UNKNOWN:
			return "UNKNOWN";
		case Code.INVALID_ARGUMENT:
			return "INVALID_ARGUMENT";
		case Code.DEADLINE_EXCEEDED:
			return "DEADLINE_EXCEEDED";
		case Code.NOT_FOUND:
			return "NOT_FOUND";
		case Code.ALREADY_EXISTS:
			return "ALREADY_EXISTS";
		case Code.PERMISSION_DENIED:
			return "PERMISSION_DENIED";
		case Code.RESOURCE_EXHAUSTED:
			return "RESOURCE_EXHAUSTED";
		case Code.FAILED_PRECONDITION:
			return "FAILED_PRECONDITION";
		case Code.ABORTED:
			return "ABORTED";
		case Code.OUT_OF_RANGE:
			return "OUT_OF_RANGE";
		case Code.UNIMPLEMENTED:
			return "UNIMPLEMENTED";
		case Code.INTERNAL:
			return "INTERNAL";
		case Code.UNAVAILABLE:
			return "UNAVAILABLE";
		case Code.DATA_LOSS:
			return "DATA_LOSS";
		case Code.UNAUTHENTICATED:
			return "UNAUTHENTICATED";
	}
	
	return "UNKNOWN";
}

export class UIErrorV2 extends Error {
	errorCode: ActionType;
	kind: EntityKind;
	userMessage?: string;
	e?: any;
	errorStatus?: ErrorStatusDTO;
	additionalErrors: any[];
	obj?: any;
	
	constructor(
		code: ActionType,
		kind: EntityKind,
		e?: any,
		message?: string,
		userMessage?: string,
		errorStatus?: ErrorStatusDTO,
		additionalErrors?: string[],
		obj: any = undefined
	) {
		super(message);
		
		this.kind = kind;
		this.errorCode = code;
		this.name = code.toString();
		this.message = message ?? ''; // Initialize message
		this.e = e;
		this.additionalErrors = additionalErrors || [];
		this.userMessage = userMessage;
		this.errorStatus = errorStatus;
		this.obj = obj;
		
		// Captures stack trace, excluding constructor call from stack
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UIErrorV2);
		}
		
		// Prevents appending the original error's message/stack multiple times
		if (e instanceof Error) {
			this.appendOriginalError(e);
		}
		
		Object.setPrototypeOf(this, UIErrorV2.prototype);
	}
	
	// Override stack getter to append custom formatted error message with stack trace
	get stack(): string {
		return `${this.toString()}\n${super.stack}`;
	}
	
	// Utility to append original error
	private appendOriginalError(e: Error): void {
		if (!this.message.includes(e.message)) {
			this.message += ` | Original error: ${e.message}`;
		}
		
		if (!this.stack?.includes(<string>e.stack)) {
			// @ts-ignore
			this.stack += `\nOriginal error stack:\n${e.stack}`;
		}
	}
	
	public static fromException<T extends Identifiable>(err: any, actionType: ActionType, entity: EntityKind, message?: string, userMessage?: string, obj?: T): UIErrorV2 {
		if (err instanceof UIErrorV2) {
			return err;
		}
		
		let objMsg = '';
		if (obj) {
			objMsg = `${entity} (id = ${obj.id})`;
		}
		
		const msg = message ? `Unexpected error: ${message} - ${objMsg}` : `Unexpected error - ${objMsg}`;
		const usrMessage = userMessage ? userMessage : 'An unexpected error occurred. Please try again later.';
		
		return NewUIErrorV2(actionType, entity, err, msg, usrMessage, undefined, undefined, obj);
	}
	
	public static formatError(
		code: ActionType,
		kind: EntityKind,
		e?: any,
		message?: string,
		userMessage?: string,
		errorStatus?: ErrorStatusDTO,
		additionalErrors?: string[],
		obj?: any
	): string {
		let formattedMessage = `[Kind]: ${kind}\n[Code]: ${code.toUpperCase()}`;
		
		// Only add the message if it's not null or undefined
		if (message && message.trim() !== '') {
			formattedMessage += `\n[Message]: ${message}`;
		}
		
		// Avoid error repetition
		const formattedErrors = new Set<string>();
		
		const appendError = (err: any, index: number) => {
			const errStr = err?.toString() || '';
			if (errStr && !formattedErrors.has(errStr)) {
				formattedMessage += `\n[Errors: ${index + 1}]: ${JSON.stringify(errStr, null, 2)}`;
				formattedErrors.add(errStr);
			}
		};
		
		// Add Obj
		if (obj) {
			formattedMessage += `\n[Obj]: ${JSON.stringify(obj, null, 2)}`;
		}
		
		// Add error details if available
		if (e) {
			formattedMessage += `\n[Error]: ${JSON.stringify(e, null, 2)}`
		}
		
		// Add error status if available
		if (errorStatus) {
			formattedMessage += `\n[Status]: ${convertErrorStatusToName(errorStatus)}`;
		}
		
		// Include any additional errors if provided and not repeated
		if (additionalErrors && additionalErrors.length > 0) {
			additionalErrors.forEach((error, index) => appendError(error, index));
		}
		
		return formattedMessage;
	}
	
	toString(): string {
		return UIErrorV2.formatError(
			this.errorCode,
			this.kind,
			this.e,
			this.message,
			this.userMessage,
			this.errorStatus,
			this.additionalErrors
		);
	}
	
	appendError(error: any): void {
		if (!this.additionalErrors.includes(error)) {
			this.additionalErrors.push(error);
		}
	}
	
	appendMessage(message: string): void {
		if (!this.message.includes(message)) {
			this.message += ` | ${message}`;
		}
	}
}

export const NewUIErrorV2 = (code: ActionType, kind: EntityKind, e?: any, message?: string, userMessage?: string, errorStatus?: ErrorStatusDTO, additionalErrors?: string[], obj?: any): UIErrorV2 => {
	let messageFormatted = UIErrorV2.formatError(code, kind, e, message, userMessage, errorStatus, additionalErrors);
	return new UIErrorV2(code, kind, e, messageFormatted, userMessage, errorStatus, additionalErrors, obj);
}

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const NewInternalError = (
	origin: string,
	code: InternalErrorTypes,
	message: string,
	err?: InternalError
): InternalError => {
	const ie = {
		name: origin,
		origin: origin,
		errorCode: code,
		message: message,
		trace: err,
	} as InternalError;
	
	LogE(ie);
	
	return ie;
};

export const isError = (obj: any): boolean => {
	return obj.hasOwnProperty("errorCode");
};

export const isErrorUnd = (obj: Object | undefined): boolean => {
	if (obj) {
		return obj.hasOwnProperty("errorCode");
	}
	
	return false;
};

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const LogError = (
	origin: string,
	code: InternalErrorTypes,
	message: string,
	err?: InternalError | any
) => {
};

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const LogE = (err: InternalError) => {
	console.debug("InternalError: ", JSON.stringify(err));
};

/**
 * These errors will be sent to the internal logger for triage
 * @param err
 * @param message
 * @constructor
 */
export const LogU = (err: IUIError, message?: string) => {
	console.error(err);
};

