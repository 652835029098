import {ActionType, isError, IUIError, NewUIErrorV2,} from "service/cartaError";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	convertFromDTOToString,
	ListItem,
	NewUUID,
	ResourceKindObj,
	validateHumanName,
} from "utils/utils";
import {ResourceCompositeDTO, ResourceDTO, ResourceKind, ResourceOriginDTO} from "proto/resource_pb";
import {UUID_DTO} from "proto/utils_pb";
import {IDisplayItem, Listable} from "../interfaces";
import {BaseModel, EntityKind, IOwnedModel, ValidListable} from "../BaseModel";
import {ResourceComposite} from "model/resource/ResourceComposite";
import {Err, Ok, Result} from "utils/result";
import {ResourceMetadata} from "./ResourceMetadata";
import {MAX_RESOURCE_METADATA_PER_RESOURCE} from "../../consts";

export const UNKNOWN_TITLE_DEFAULT = "Unknown Title";
export const UNKNOWN_AUTHOR_DEFAULT = "Unknown";

export interface IResource extends IOwnedModel<any> {
	_title: string;
	_authors: string[];
	_kind: ResourceKind;
	_createdOn: Date;
	_updatedOn: Date;
	_subtitle?: string;
	_thumbnail?: string;
	_archivedOn?: Date;
	_description?: string;
	_link?: string;
	composite?: ResourceComposite;
}

export class Resource extends BaseModel<Resource, ResourceDTO> implements Listable {
	title: string;
	authors: string[];
	kind: ResourceKind;
	origin: ResourceOriginDTO;
	thumbnail?: string;
	archivedOn?: Date;
	link?: string;
	composite?: ResourceComposite
	
	constructor() {
		super();
		this.origin = ResourceOriginDTO.LOCAL;
		this.authors = [];
		this.kind = ResourceKind.UNKNOWN;
		this.title = "";
	}
	
	toListItem(): ListItem {
		let thumbnail = "";
		
		if (this.composite) {
			if (this.composite.metadata.length > 0) {
				const metadata = this.composite.metadata[0];
				
				if (metadata.smallThumbnail) {
					thumbnail = metadata.smallThumbnail;
				}
				
				return {
					id: this.id,
					title: this.title,
					imageUrl: thumbnail,
					metadata1: this.authors.join(", "),
					metadata2: ResourceKindMap.get(this.kind) as string,
					metadata3: this.link,
				};
			} else {
				if (this.thumbnail) {
					thumbnail = this.thumbnail;
				}
			}
		}
		
		return {
			id: this.id,
			title: this.title,
			imageUrl: thumbnail,
			metadata1: this.authors.join(", "),
			metadata2: ResourceKindMap.get(this.kind) as string,
			metadata3: this.link,
		};
	}
	
	init(): Resource {
		return new Resource();
	}
	
	TYPE: EntityKind = EntityKind.Resource;
	
	to1LineString(): String {
		return `${this.title} - ${this.authors}`;
	}
	
	toDisplayable(): IDisplayItem {
		let thumbnail = "";
		
		if (this.composite) {
			if (this.composite.metadata.length > 0) {
				const metadata = this.composite.metadata[0];
				
				if (metadata.smallThumbnail) {
					thumbnail = metadata.smallThumbnail;
				}
				
				return {
					id: this.id,
					title: this.title,
					imageUrl: thumbnail,
					metadata1: this.authors.join(", "),
					metadata2: ResourceKindMap.get(this.kind) as string,
				};
			} else {
				if (this.thumbnail) {
					thumbnail = this.thumbnail;
				}
			}
		}
		
		return {
			id: this.id,
			imageUrl: thumbnail,
			title: this.title,
			metadata1: this.authors.join(", "),
		} as IDisplayItem;
	}
	
	clone(): Resource {
		let temp = Object.assign({}, this);
		let newCard = new Resource();
		
		newCard.id = temp.id;
		newCard.userId = temp.userId;
		newCard.title = temp.title;
		newCard.authors = temp.authors;
		newCard.kind = temp.kind;
		newCard.title = temp.title;
		newCard.link = temp.link;
		if (temp.composite) {
			newCard.composite = temp.composite.clone();
		}
		newCard.thumbnail = temp.thumbnail;
		newCard.createdOn = temp.createdOn;
		newCard.updatedOn = temp.updatedOn;
		newCard.archivedOn = temp.archivedOn;
		
		return newCard;
	}
	
	static fromJSON(temp: IResource): Resource {
		let resource = new Resource();
		
		resource.id = temp.id;
		resource.userId = temp.userId;
		resource.title = temp._title;
		resource.authors = temp._authors;
		resource.title = temp._title;
		resource.kind = temp._kind;
		resource.link = temp._link;
		// resource._metadata. = temp._metadata; // TODO: We should just be storing IDs and fetching from the DB
		resource.thumbnail = temp._thumbnail;
		resource.createdOn = new Date(temp._createdOn);
		resource.updatedOn = new Date(temp._updatedOn);
		resource.archivedOn = temp._archivedOn
		if (temp.composite) {
			resource.composite = temp.composite.clone();
		}
		
		return resource;
	}
	
	fromDTO(dto: ResourceDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
		this.title = convertFromDTOToString('title', this.TYPE, dto.getTitle(), false);
		this.authors = dto.getAuthorsList();
		this.kind = dto.getKind();
		this.title = dto.getTitle();
		this.link = dto.getLink();
		this.thumbnail = dto.getThumbnail();
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedOn())!;
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedOn())!;
		this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedOn(), true);
		
		let composite;
		if (dto.getComposite()) {
			composite = new ResourceComposite();
			composite.fromDTO(dto.getComposite()!);
		}
		this.composite = composite;
	}
	
	intoDTO(): IUIError | ResourceDTO {
		let resource = this
		
		let dto = new ResourceDTO();
		dto.setId(new UUID_DTO().setValue(resource.id));
		dto.setUserId(new UUID_DTO().setValue(resource.userId));
		
		dto.setAuthorsList(resource.authors);
		dto.setKind(resource.kind);
		dto.setTitle(resource.title);
		dto.setLink(resource.link ? resource.link : "");
		dto.setThumbnail(resource.thumbnail ? resource.thumbnail : "");
		dto.setUpdatedOn(convertDateToTimestamp(resource.updatedOn));
		dto.setCreatedOn(convertDateToTimestamp(resource.createdOn));
		
		if (resource.composite) {
			let compositeDTO = resource.composite.intoDTO();
			if (isError(compositeDTO)) {
				return compositeDTO as IUIError
			}
			
			dto.setComposite(compositeDTO as ResourceCompositeDTO);
		}
		
		if (resource.archivedOn) {
			dto.setArchivedOn(convertDateToTimestamp(resource.archivedOn));
		}
		
		return dto;
	}
	
	sanitize(): Resource {
		return this;
	}
	
	customValidate(): Resource | IUIError {
		let res: Result<void, IUIError>
		res = Resource.ResourceAuthorsValidator(this.authors.join(", "));
		if (!res.ok) {
			return res.error;
		}
		res = Resource.ResourceTitleValidator(this.title);
		if (!res.ok) {
			return res.error;
		}
		if (this.link) {
			res = Resource.ResourceLinkValidator(this.link);
			if (!res.ok) {
				return res.error;
			}
		}
		if (this.composite) {
			res = Resource.ResourceMetadataValidator(this.composite!.metadata);
			if (!res.ok) {
				return res.error;
			}
		}
		
		return this; // Return `this` if all validators pass
	}
	
	// TODO -we need to pass in the actual resource object, not a field
	static ResourceTitleValidator(title: string): Result<void, IUIError> {
		if (title.length === 0) {
			const errMsg = "title is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Resource,
				errMsg, errMsg, errMsg
			))
		}
		
		if (title.length > 200) {
			const errMsg = "title cannot exceed 200 characters";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Resource,
				errMsg, errMsg, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	static ResourceAuthorsValidator(authors: string): Result<void, IUIError> {
		if (authors.length != 0) {
			
			for (let i = 0; i < authors.length; i++) {
				const element = authors[i];
				if (element.length === 0) {
					const errMsg = "individual author is empty";
					return Err<IUIError>(NewUIErrorV2(
						ActionType.Validate,
						EntityKind.Resource,
						errMsg, errMsg, errMsg
					))
				}
				
				if (element.length > 100) {
					const errMsg = "individual author cannot exceed 100 characters";
					return Err<IUIError>(NewUIErrorV2(
						ActionType.Validate,
						EntityKind.Resource,
						errMsg, errMsg, errMsg
					))
				}
				
				// if (!validateHumanName(element)) {
				// 	const errMsg = "individual author contains illegal characters";
				// 	return Err<IUIError>(NewUIErrorV2(
				// 		ActionType.Validate,
				// 		EntityKind.Resource,
				// 		errMsg, errMsg, errMsg
				// 	))
				// }
			}
		} else {
			const errMsg = "author is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Resource,
				errMsg, errMsg, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	static ResourceLinkValidator(link: string): Result<void, IUIError> {
		if (link) {
			if (link.length > 200) {
				const errMsg = "link cannot exceed 200 characters - use a link shortener if necessary";
				return Err<IUIError>(NewUIErrorV2(
					ActionType.Validate,
					EntityKind.Resource,
					errMsg, errMsg, errMsg
				))
			}
		}
		
		return Ok(undefined)
	}
	
	static ResourceMetadataValidator(metadata: ResourceMetadata[]): Result<void, IUIError> {
		if (metadata.length > MAX_RESOURCE_METADATA_PER_RESOURCE) {
			const errMsg = `too many metadata items, only ${MAX_RESOURCE_METADATA_PER_RESOURCE} allowed`;
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Resource,
				errMsg, errMsg, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	
	public static Validators: Array<(item: any) => Result<void, IUIError>> = [
		Resource.ResourceTitleValidator,
		Resource.ResourceAuthorsValidator,
		Resource.ResourceLinkValidator,
		Resource.ResourceMetadataValidator
	]
}

export const ResourceKindFromStr = (kind: string): ResourceKind => {
	// @ts-ignore
	switch (kind.toLowerCase()) {
		case "book":
			return ResourceKind.BOOK;
		case "website":
			return ResourceKind.WEBSITE;
		case "journal":
			return ResourceKind.JOURNAL;
		case "textbook":
			return ResourceKind.TEXTBOOK;
		case  "text book":
			return ResourceKind.TEXTBOOK;
		case "unknown":
			return ResourceKind.UNKNOWN;
		case "magazine":
			return ResourceKind.MAGAZINE;
		default:
			return ResourceKind.UNKNOWN;
	}
}

export const ResourceKinds: ResourceKindObj[] = [
	{title: "Book", kind: ResourceKind.BOOK},
	{title: "Journal", kind: ResourceKind.JOURNAL},
	{title: "Website", kind: ResourceKind.WEBSITE},
	{title: "Text Book", kind: ResourceKind.TEXTBOOK},
	{title: "Magazine", kind: ResourceKind.MAGAZINE},
	{title: "Unknown", kind: ResourceKind.UNKNOWN},
];

export const ResourceKindMap: Map<ResourceKind, string> = new Map<
	ResourceKind,
	string
>([
	[ResourceKind.BOOK, "Book"],
	[ResourceKind.JOURNAL, "Journal"],
	[ResourceKind.WEBSITE, "Website"],
	[ResourceKind.TEXTBOOK, "Text Book"],
	[ResourceKind.MAGAZINE, "Magazine"],
	[ResourceKind.UNKNOWN, "Unknown"],
]);

export const convertStringToDate = (dateStr: string): Date | undefined => {
	let ans = Date.parse(dateStr);
	
	if (ans === 0) {
		return undefined;
	}
	return new Date(ans);
};

export class StringListable implements ValidListable {
	private _id: string;
	private _title: string;
	
	constructor(value: string) {
		this._id = NewUUID();
		this._title = value;
	}
	
	toListItem(): ListItem {
		return {
			id: this._id,
			title: this.title,
		};
	}
	
	get title(): string {
		return this._title;
	}
	
	set title(value: string) {
		this._title = value;
	}
	
	get id(): string {
		return this._id;
	}
	
	set id(value: string) {
		this._id = value;
	}
}