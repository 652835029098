import {ListOptionsRequestDTO, ProgressStateEnumDTO, UUID_DTO,} from "../proto/utils_pb";
import {ReviewManual} from "../model/ReviewManual";
import {ReviewManualServicePromiseClient} from "../proto/reviewManual_grpc_web_pb";
import {
	CompleteManualReviewRequest,
	CreateReviewManualRequest,
	DeleteReviewManualRequest,
	GetReviewManualRequest,
	ListCardsToReviewByConfigRequest,
	ListManualCardsRequest,
	ListReviewManualByIdsRequest,
	ListReviewManualRequest,
	ReviewManualDTO,
	ReviewManualOptionsDTO,
	SaveManualReviewSessionCardRequest,
	UpdateManualReviewRequest,
} from "proto/reviewManual_pb";
import {IsUUIDValid, unifiedInterceptor} from "utils/utils";
import {
	DTOCreatorRequestType,
	DTOCreatorResponseType,
	EntityKind,
	IGRPCReviewService,
	IGRPCService, IReviewRespStat, IReviewRespStatDTO,
	ListResponse,
} from "model/BaseModel";
import grpcWeb from "grpc-web";
import {ReviewBaseService} from "./ReviewBaseService";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2, UIErrorV2,} from "./cartaError";
import {Err, Ok, Result} from "utils/result";
import {CARTA_PROXY_URL} from "consts";
import {GetReviewManualStatsRequest, ReviewStatDTO} from "../proto/stats_pb";
import {ReviewCardDTO} from "../proto/review_pb";
import {ReviewCard} from "../model/ReviewCard";

import {ReviewStat} from "../model/ReviewStat";
import {statsClient} from "../stores/clients";

const reviewManualClient = new ReviewManualServicePromiseClient(
	CARTA_PROXY_URL!,
	null,
	{withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor]}
);

export class ReviewManualService extends ReviewBaseService<
	ReviewManual,
	ReviewManualDTO,
	ReviewCard,
	ReviewCardDTO,
	ReviewStat,
	ReviewStatDTO,
	ReviewManualOptionsDTO,
	ReviewManualGRPC
> {
	constructor() {
		super(
			new ReviewManualGRPC(),
			ReviewManual,
			ReviewCard,
			ReviewStat
		);
	}
	
	ResumeReview = async (
		review: ReviewManual
	): Promise<
		[review: ReviewManual, reviewCards: ReviewCard[]] | IUIError
	> => {
		let obj: [review: ReviewManual, reviewCards: ReviewCard[]] = [
			review,
			[],
		];
		
		const now = new Date();
		review.updatedOn = now;
		review.progressState = ProgressStateEnumDTO.IN_PROGRESS;
		
		// TODO: I need to catch these errors and return them
		try {
			const updateReview = await this.Update(review);
			
			if (updateReview.ok) {
				obj[0] = updateReview.value;
			} else {
				return NewUIError(
					"ResumeReview",
					InternalErrorTypes.ResumeReviewSM2,
					`failed to update review: ${review.id} to resume - ${updateReview.error}`
				);
			}
		} catch (err) {
			return NewUIError(
				"ResumeReview",
				InternalErrorTypes.ResumeReviewSM2,
				`failed to update review: ${review.id} to resume - ${err}`
			);
		}
		
		try {
			const fetchCards = await this.ListCards(review.id);
			
			if (fetchCards.ok) {
				obj[1] = fetchCards.value;
			} else {
				return NewUIError(
					"ResumeReview",
					InternalErrorTypes.ResumeReviewSM2,
					`failed to fetch cards for review: ${review.id} to resume - ${fetchCards.error}`
				);
			}
		} catch (e) {
			return NewUIError(
				"ResumeReview",
				InternalErrorTypes.ResumeReviewSM2,
				`failed to fetch cards for review: ${review.id} to resume - ${e}`
			);
		}
		
		return obj;
	};
	
	async SaveCardWithNext(
		reviewId: string,
		next_card_id: string,
		card: ReviewCard
	): Promise<Result<ReviewCard, UIErrorV2>> {
		const actionType = ActionType.SaveReviewCards;
		
		if (!IsUUIDValid(reviewId)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewCard,
					undefined,
					`invalid reviewId: ${reviewId}`
				)
			);
		}
		
		if (!IsUUIDValid(next_card_id)) {
			return Err(
				NewUIErrorV2(
					actionType,
					EntityKind.ReviewCard,
					undefined,
					`invalid next_card id: ${next_card_id}`
				)
			);
		}
		
		const now = new Date();
		
		card.cardId = card.composite.card.id;
		card.endAt = now;
		card.updatedOn = now;
		
		let validation = card.customValidate();
		
		if (isError(validation)) {
			return Err(
				NewUIErrorV2(
					ActionType.Validate,
					EntityKind.ReviewCard,
					`failed to validate: ${validation}`
				)
			);
		}
		
		const dto = card.intoDTO();
		
		if (isError(dto)) {
			return Err(
				NewUIErrorV2(
					ActionType.ConvertToDTO,
					EntityKind.ReviewCard,
					`failed to convert: ${dto}`
				)
			);
		}
		
		const req: DTOCreatorRequestType = this.client.setupSaveCardWithNextReq(
			reviewId,
			next_card_id,
			dto as ReviewCardDTO
		);
		
		try {
			const response: ReviewCardDTO | undefined =
				await this.client.saveCard(req);
			
			
			if (response === undefined) {
				return Err(
					NewUIErrorV2(
						actionType,
						EntityKind.ReviewCard,
						undefined,
						`list entity response is undefined`
					)
				);
			}
			
			let newM = new ReviewCard();
			const err = newM.fromDTO(response as ReviewCardDTO);
			if (err) {
				return Err(
					NewUIErrorV2(
						ActionType.ConvertToDTO,
						newM.TYPE,
						err as IUIError,
						`failed to convert returned entity: ${newM}`
					)
				);
			}
			
			
			return Ok(newM);
		} catch (err) {
			return Err(
				NewUIErrorV2(actionType, EntityKind.ReviewCard, err as IUIError)
			);
		}
	}
}

export class ReviewManualGRPC
	implements IGRPCService<
		ReviewManualDTO,
		DTOCreatorRequestType,
		DTOCreatorResponseType<ReviewManualDTO>
	>,
		IGRPCReviewService<
			ReviewManualDTO,
			ReviewCardDTO,
			ReviewStatDTO,
			ReviewManualOptionsDTO,
			DTOCreatorRequestType,
			DTOCreatorResponseType<ReviewManualDTO>
		> {
	setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
		let req = new ListReviewManualByIdsRequest();
		req.setIdsList(ids);
		
		return req;
	}
	
	async listByIDs(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ListResponse<ReviewManualDTO> | undefined> {
		let x = await reviewManualClient.listByIds(req as ListReviewManualByIdsRequest, meta);
		
		return {
			items: x.getItemsList() as ReviewManualDTO[],
			info: x.getInfo(),
		} as ListResponse<ReviewManualDTO>;
	}
	
	create(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewManualDTO | undefined> {
		return reviewManualClient
			.create(req as CreateReviewManualRequest, meta)
			.then((x) => {
				return x.getSession() as ReviewManualDTO;
			});
	}
	
	setupCreateReq(dto: ReviewManualDTO): DTOCreatorRequestType {
		let req = new CreateReviewManualRequest();
		req.setSession(dto);
		
		return req;
	}
	
	async list(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ListResponse<ReviewManualDTO> | undefined> {
		const x = await reviewManualClient.list(
			req as ListReviewManualRequest,
			meta
		);
		
		return {
			items: x.getItemsList() as ReviewManualDTO[],
			info: x.getInfo(),
		} as ListResponse<ReviewManualDTO>;
	}
	
	setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
		let req = new ListReviewManualRequest();
		req.setOpts(dto);
		
		return req;
	}
	
	delete(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<void> {
		return reviewManualClient
			.delete(req as DeleteReviewManualRequest, meta)
			.then((x) => {
				return;
			});
	}
	
	setupDeleteReq(id: string): DTOCreatorRequestType {
		let req = new DeleteReviewManualRequest();
		req.setReviewid(new UUID_DTO().setValue(id));
		
		return req;
	}
	
	get(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewManualDTO | undefined> {
		return reviewManualClient
			.get(req as GetReviewManualRequest, meta)
			.then((x) => {
				return x.getSession() as ReviewManualDTO;
			});
	}
	
	setupGetReq(id: string): DTOCreatorRequestType {
		let req = new GetReviewManualRequest();
		req.setId(new UUID_DTO().setValue(id));
		
		return req;
	}
	
	setupUpdateReq(dto: ReviewManualDTO): DTOCreatorRequestType {
		let req = new UpdateManualReviewRequest();
		req.setReview(dto);
		
		return req;
	}
	
	update(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewManualDTO | undefined> {
		return reviewManualClient
			.updateManualReview(req as UpdateManualReviewRequest, meta)
			.then((x) => {
				return x.getReview() as ReviewManualDTO;
			});
	}
	
	// setupSaveReq(dto: ReviewManualDTO, cardDTO: ReviewCardDTO): DTOCreatorRequestType {
	// 	let req = new SaveManualR()
	// 	req.setId(dto.getId()!)
	// 	req.setCard(cardDTO)
	//
	// 	return req
	// }
	//
	// save(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<ReviewManualDTO | undefined> {
	// 	return manualReviewClient.saveManualReviewCardSession(req as SaveManualReviewSessionCardRequest, meta)
	// 		.then((x) => {
	// 			return x. as ReviewManualDTO
	// 		})
	// }
	
	setupCompleteReq(dto: ReviewManualDTO): DTOCreatorRequestType {
		let req = new CompleteManualReviewRequest();
		req.setReview(dto);
		
		return req;
	}
	
	async complete(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewManualDTO | undefined> {
		const x = await reviewManualClient.completeManualReview(
			req as CompleteManualReviewRequest,
			meta
		);
		return x.getReview() as ReviewManualDTO;
	}
	
	setupSaveCardReq(
		review_id: string,
		dto: ReviewCardDTO
	): DTOCreatorRequestType {
		let req = new SaveManualReviewSessionCardRequest();
		req.setCard(dto);
		
		return req;
	}
	
	async saveCard(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewCardDTO | undefined> {
		const x = await reviewManualClient.saveManualReviewCardSession(
			req as SaveManualReviewSessionCardRequest,
			meta
		);
		return x.getCard() as ReviewCardDTO;
	}
	
	setupSaveCardWithNextReq(
		review_id: string,
		next_review_card_id: string,
		dto: ReviewCardDTO
	): DTOCreatorRequestType {
		let req = new SaveManualReviewSessionCardRequest();
		req.setId(new UUID_DTO().setValue(review_id));
		req.setNextReviewCardId(new UUID_DTO().setValue(next_review_card_id));
		req.setCard(dto);
		
		return req;
	}
	
	setupGetStatsReq(id: string): DTOCreatorRequestType {
		let req = new GetReviewManualStatsRequest();
		req.setId(new UUID_DTO().setValue(id));
		
		return req;
	}
	
	async getStats(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewStatDTO | undefined> {
		const x = await statsClient.getReviewManualStats(
			req as GetReviewManualStatsRequest,
			meta
		);
		return x.getStats() as ReviewStatDTO;
	}
	
	setupListCardsReq(id: string): DTOCreatorRequestType {
		let req = new ListManualCardsRequest();
		req.setReviewid(new UUID_DTO().setValue(id));
		
		return req;
	}
	
	async listCards(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewCardDTO[] | undefined> {
		const x = await reviewManualClient.listManualCards(
			req as ListManualCardsRequest,
			meta
		);
		return x.getManualcardsList() as ReviewCardDTO[];
	}
	
	setupListCardsByConfigReq(
		reviewId: string,
		config: ReviewManualOptionsDTO
	): DTOCreatorRequestType {
		let req = new ListCardsToReviewByConfigRequest();
		req.setReviewid(new UUID_DTO().setValue(reviewId));
		req.setOptions(config);
		
		return req;
	}
	
	async listCardsByConfig(
		req: DTOCreatorRequestType,
		meta?: grpcWeb.Metadata
	): Promise<ReviewCardDTO[] | undefined> {
		const x = await reviewManualClient.listCardsToReviewByConfig(
			req as ListCardsToReviewByConfigRequest,
			meta
		);
		return x.getCardList() as ReviewCardDTO[];
	}
}
