import { BaseService } from "./BaseService";
import { CardLang } from "../model/CardLang";
import {
  DTOCreatorRequestType,
  DTOCreatorResponseType,
  IGRPCService,
  ListResponse,
} from "../model/BaseModel";
import grpcWeb from "grpc-web";
import {
  CardLangDTO,
  CreateCardLangRequest,
  DeleteCardLangRequest,
  ListCardLangByIdsRequest,
  ListCardLangRequest,
  UpdateCardLangRequest,
} from "../proto/cardLang_pb";
import {
  ListOptionsRequestDTO,
  ListOptionsResponseDTO,
  UUID_DTO,
} from "../proto/utils_pb";
import { CardLangServicePromiseClient } from "../proto/cardLang_grpc_web_pb";
import { CardDTO } from "../proto/card_pb";
import {CARTA_PROXY_URL} from "consts";
import {unifiedInterceptor} from "../utils/utils";

const cardLangClient = new CardLangServicePromiseClient(
  CARTA_PROXY_URL!,
  null,
  { withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor] }
);

// interface IGRPCMethods<Req, Resp> {
// 	Create(
// 		request: Req,
// 		metadata?: grpcWeb.Metadata
// 	): Promise<Resp>;
//
// 	List(
// 		request: Req,
// 		metadata?: grpcWeb.Metadata
// 	): Promise<Resp>;
//
// 	Delete(
// 		request: Req,
// 		metadata?: grpcWeb.Metadata
// 	): Promise<Resp>;
//
// 	Update(
// 		request: Req,
// 		metadata?: grpcWeb.Metadata
// 	): Promise<Resp>;
// }
//
// export abstract class BaseGRPCImpl<S extends IGRPCMethods<any, any>> implements IGRPCMethods<cardLang_pb.CreateCardLangRequest, cardLang_pb.CardLangDTO> {
// 	service: S;
// 	constructor(private s: S) {
// 		this.service = s
// 	}
// 	create(request: cardLang_pb.CreateCardLangRequest, metadata?: grpcWeb.Metadata): Promise<cardLang_pb.CardLangDTO> {
// 		return this.service.Create(request, metadata)
// 	}
//
// 	List(request: cardLang_pb.ListCardLangRequest, metadata?: grpcWeb.Metadata): Promise<cardLang_pb.CardLangDTO> {
// 		return this.service.List(request, metadata)
// 	}
//
// 	Delete(request: cardLang_pb.DeleteCardLangRequest, metadata?: grpcWeb.Metadata): Promise<cardLang_pb.CardLangDTO> {
// 		return this.service.Delete(request, metadata)
// 	}
//
// 	Update(request: cardLang_pb.UpdateCardLangRequest, metadata?: grpcWeb.Metadata): Promise<cardLang_pb.CardLangDTO> {
// 		return this.service.Update(request, metadata)
// 	}
//
// }

export class CardLangGRPCImpl
  implements
    IGRPCService<
      CardLangDTO,
      DTOCreatorRequestType,
      DTOCreatorResponseType<CardLangDTO>
    >
{
  
  setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
    let req = new ListCardLangByIdsRequest();
    req.setIdsList(ids);
    
    return req;
  }
  
  async listByIDs(
      req: DTOCreatorRequestType,
      meta?: grpcWeb.Metadata
  ): Promise<ListResponse<CardLangDTO> | undefined> {
    let x = await cardLangClient.listByIds(req as ListCardLangByIdsRequest, meta);
    return {
        items: x.getItemsList() as CardLangDTO[],
        info: x.getInfo(),
    } as ListResponse<CardLangDTO>
  }
  
  async create(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<CardLangDTO | undefined> {
    let x = await cardLangClient.create(req as CreateCardLangRequest, meta);
    return x.getDto() as CardLangDTO;
  }

  setupCreateReq(dto: CardLangDTO): DTOCreatorRequestType {
    let req = new CreateCardLangRequest();
    req.setDto(dto);

    return req;
  }

  async list(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<ListResponse<CardLangDTO> | undefined> {
    let x = await cardLangClient.list(req as ListCardLangRequest, meta);

    return {
      items: x.getItemsList() as CardLangDTO[],
      info: x.getInfo(),
    } as ListResponse<CardLangDTO>
  }

  setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
    let req = new ListCardLangRequest();
    req.setOpts(dto);

    return req;
  }

  async delete(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<void> {
    const x = await cardLangClient.delete(req as DeleteCardLangRequest, meta);
    return;
  }

  setupDeleteReq(id: string): DTOCreatorRequestType {
    let req = new DeleteCardLangRequest();
    req.setId(new UUID_DTO().setValue(id));

    return req;
  }

  get(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<CardLangDTO | undefined> {
    return Promise.resolve(undefined);
  }

  setupGetReq(id: string): DTOCreatorRequestType {
    return {};
  }

  setupUpdateReq(dto: CardLangDTO): DTOCreatorRequestType {
    let req = new UpdateCardLangRequest();
    req.setDto(dto);

    return req;
  }

  async update(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<CardLangDTO | undefined> {
    const x = await cardLangClient.update(req as UpdateCardLangRequest, meta);
    return x.getDto() as CardLangDTO;
  }
}

export class CardLangService extends BaseService<
  CardLang,
  CardLangDTO,
  CardLangGRPCImpl
> {
  constructor() {
    super(new CardLangGRPCImpl(), CardLang);
  }
}
