import {ActionType, isError, IUIError, NewUIErrorV2} from "./cartaError";
import {getUserId} from "./AuthService";
import {
    BaseModel,
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    IGRPCService,
    ListResponse,
} from "../model/BaseModel";
import {Err, Ok, Result} from "../utils/result";
import {ListOptionsRequestDTO, UUID_DTO} from "../proto/utils_pb";

export interface IBaseService<Model> {
    Create(model: Model): Promise<Result<Model, IUIError>>;

    List(
        opts: ListOptionsRequestDTO,
        m: Model
    ): Promise<Result<ListResponse<Model>, IUIError>>;

    Get(id: string, m: Model): Promise<Result<Model | undefined, IUIError>>;

    Update(model: Model): Promise<Result<Model, IUIError>>; //
    Delete(id: string): Promise<Result<void, IUIError>>;
}

export abstract class BaseService<
    MODEL extends BaseModel<MODEL, MODEL_DTO>,
    MODEL_DTO,
    CLIENT extends IGRPCService<
        MODEL_DTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<MODEL_DTO>
    >
> implements IBaseService<MODEL> {
    client: CLIENT;

    protected constructor(
        client: CLIENT,
        private modelConstructor: new () => MODEL
    ) {
        this.client = client;
    }

    async Create(m: MODEL): Promise<Result<MODEL, IUIError>> {
        const actionType = ActionType.Create;

        m.userId = getUserId();
        
        const sanitizedModel = m.sanitize();
        if (isError(sanitizedModel)) {
            return Err(
                NewUIErrorV2(
                    ActionType.Sanitize,
                    m.TYPE,
                    `failed to sanitize: ${sanitizedModel}`
                )
            );
        }

        let validation = m.customValidate();

        if (isError(validation)) {
            console.log("isError(validation): ", isError(validation));
            return Err(
                NewUIErrorV2(
                    ActionType.Validate,
                    m.TYPE,
                    `failed to validate: ${validation}`
                )
            );
        }

        const dto = m.intoDTO();
        
        if (isError(dto)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    m.TYPE,
                    `failed to convert: ${dto}`
                )
            );
        }

        const req: DTOCreatorRequestType = this.client.setupCreateReq(
            dto as MODEL_DTO
        );

        try {
            const response: MODEL_DTO | undefined = await this.client.create(req);

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `created entity response is undefined`
                    )
                );
            }

            const err = m.fromDTO(response);

            if (!err) {
                return Ok(m);
            } else {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertToDTO,
                        m.TYPE,
                        err as IUIError,
                        `failed to convert returned entity: ${JSON.stringify(m)}`
                    )
                );
            }
        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async Update(m: MODEL): Promise<Result<MODEL, IUIError>> {
        const actionType = ActionType.Create;

        m.userId = getUserId();
        let validation = m.customValidate();

        

        if (isError(validation)) {
            return Err(
                NewUIErrorV2(
                    ActionType.Validate,
                    m.TYPE,
                    `failed to validate: ${validation}`
                )
            );
        }

        const dto = m.intoDTO();
        
        if (isError(dto)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    m.TYPE,
                    `failed to convert: ${dto}`
                )
            );
        }

        

        const req: DTOCreatorRequestType = this.client.setupUpdateReq(
            dto as MODEL_DTO
        );

        try {
            const response: MODEL_DTO | undefined = await this.client.update(req);

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `created entity response is undefined`
                    )
                );
            }

            const err = m.fromDTO(response);

            if (!err) {
                return Ok(m);
            } else {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertToDTO,
                        m.TYPE,
                        err as IUIError,
                        `failed to convert returned entity: ${JSON.stringify(m)}`
                    )
                );
            }
        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async List(
        opts: ListOptionsRequestDTO,
        m: MODEL
    ): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.List;

        const req: DTOCreatorRequestType = this.client.setupListReq(opts);

        try {
            const response: ListResponse<MODEL_DTO> | undefined =
                await this.client.list(req);

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `list entity response is undefined`
                    )
                );
            }

            let items: MODEL[] = [];

            response.items.map((dto: MODEL_DTO) => {
                let newM = new this.modelConstructor();
                const err = newM.fromDTO(dto);
                if (!err) {
                    items.push(newM);
                } else {
                    return Err(
                        NewUIErrorV2(
                            ActionType.ConvertToDTO,
                            newM.TYPE,
                            err as IUIError,
                            `failed to convert returned entity: ${JSON.stringify(newM)}`
                        )
                    );
                }
            });

            return Ok({
                items: items,
                info: response.info,
            } as ListResponse<MODEL>);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async ListT(
        opts: ListOptionsRequestDTO,
        m: MODEL
    ): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.List;

        const req: DTOCreatorRequestType = this.client.setupListReq(opts);

        try {
            const response: ListResponse<MODEL_DTO> | undefined =
                await this.client.list(req);

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `list entity response is undefined`
                    )
                );
            }

            let items: MODEL[] = [];

            response.items.map((dto: MODEL_DTO) => {
                let newM = new this.modelConstructor();
                const err = newM.fromDTO(dto);
                if (!err) {
                    items.push(newM);
                } else {
                    return Err(
                        NewUIErrorV2(
                            ActionType.ConvertToDTO,
                            newM.TYPE,
                            err as IUIError,
                            `failed to convert returned entity: ${JSON.stringify(newM)}`
                        )
                    );
                }
            });

            return Ok({
                info: response.info,
                items: items,
            });
        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async ListByIDs(
        ids: UUID_DTO[],
        m: MODEL
    ): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.ListByIDs;

        const req: DTOCreatorRequestType = this.client.setupListByIDsReq(ids);

        try {
            const response: ListResponse<MODEL_DTO> | undefined = await this.client.listByIDs(
                req
            );

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `list entity response is undefined`
                    )
                );
            }

            let items: MODEL[] = [];

            response.items.map((dto: MODEL_DTO) => {
                let newM = new this.modelConstructor();
                const err = newM.fromDTO(dto);
                if (!err) {
                    items.push(newM);
                } else {
                    return Err(
                        NewUIErrorV2(
                            ActionType.ConvertToDTO,
                            newM.TYPE,
                            err as IUIError,
                            `failed to convert returned entity: ${JSON.stringify(newM)}`
                        )
                    );
                }
            });

            let res: ListResponse<MODEL> = {
                items: items,
                info: response.info,
            }

            return Ok(res);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async Delete(id: string): Promise<Result<void, IUIError>> {
        const actionType = ActionType.Delete;

        const req: DTOCreatorRequestType = this.client.setupDeleteReq(id);

        let model: MODEL = new this.modelConstructor();
        // model.init();

        try {
            await this.client.delete(req);

            return Ok(undefined);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err as IUIError));
        }
    }

    async Get(id: string): Promise<Result<MODEL | undefined, IUIError>> {
        const actionType = ActionType.Get;
        const req: DTOCreatorRequestType = this.client.setupGetReq(id);

        let type: MODEL = {} as MODEL;
        type = new this.modelConstructor();

        try {
            const response = await this.client.get(req);
            

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        type.TYPE,
                        undefined,
                        `get entity response is undefined`
                    )
                );
            }

            const err = type.fromDTO(response as MODEL_DTO);
            if (err) {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertFromDTO,
                        type.TYPE,
                        `failed to convert: ${type}`
                    )
                );
            }

            return Ok(type as MODEL);
        } catch (err) {
            console.error("BaseService Get error", err);
            return Err(NewUIErrorV2(actionType, type.TYPE, err as IUIError));
        }
    }
}
