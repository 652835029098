import {BaseModel, EntityKind} from "./BaseModel";
import {ComparatorDTO, InclusionModifierEnumDTO, ReviewManualConfigDTO} from "proto/reviewManual_pb";
import {convertDateToTimestamp, convertFromDTOToID, convertTimestampToDate, ListItem, NewUUID} from "utils/utils";
import {IDisplayItem} from "./interfaces";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {EnumReviewCardKindDTO, UUID_DTO} from "proto/utils_pb";
import {TopicRelationshipEnumOptsDto} from "proto/topic_pb";
import {DEFAULT_LIMIT, MAX_LIMIT, MAX_LIMIT_REVIEW_MANUAL} from "consts";


export class ReviewManualConfig extends BaseModel<ReviewManualConfig, ReviewManualConfigDTO> {
    time_from?: Date;
    time_to?: Date;
    avgQuality?: number;
    avgQualityComparator?: ComparatorDTO;
    languages?: string[];
    topics?: string[];
    tags?: string[];
    reviewCardKind: EnumReviewCardKindDTO
    tags_modifier: InclusionModifierEnumDTO;
    topics_modifier: InclusionModifierEnumDTO;
    language_modifier: InclusionModifierEnumDTO;
    cards?: string[];
    cards_modifier?: InclusionModifierEnumDTO;
    card_langs?: string[];
    card_langs_modifier?: InclusionModifierEnumDTO;
    limit: number;
    id: string;
    userId: string;
    topicRelationshipOpts: TopicRelationshipEnumOptsDto;
    topicRelationshipDepth: number = 1;
    
    constructor() {
        super();
        this.id = NewUUID();
        this.userId = "";
        this.limit = DEFAULT_LIMIT;
        this.topics_modifier = InclusionModifierEnumDTO.ONLY;
        this.tags_modifier = InclusionModifierEnumDTO.ONLY;
        this.language_modifier = InclusionModifierEnumDTO.INCLUDE;
        this.reviewCardKind = EnumReviewCardKindDTO.REVIEWCARDKIND_STANDARD
        this.topicRelationshipOpts = TopicRelationshipEnumOptsDto.OPTNONE
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.topics ? this.topics.join(", ") : "",
            metadata1: this.tags ? this.tags.join(", ") : "",
        }
    }
    
    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }
    
    to1LineString(): String {
        throw new Error("Method not implemented.");
    }
    
    init(): ReviewManualConfig {
        return new ReviewManualConfig()
    }
    
    TYPE: EntityKind = EntityKind.ReviewManualConfig;
    
    sanitize(): ReviewManualConfig {
        return this;
    }
    
    customValidate(): ReviewManualConfig | IUIError {
        if (this.limit > MAX_LIMIT_REVIEW_MANUAL) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", `Limit cannot exceed ${MAX_LIMIT_REVIEW_MANUAL}`);
        }
        
        return this
    }
    
    static default(): ReviewManualConfig {
        let config = new ReviewManualConfig();
        const from_relative = new Date();
        from_relative.setDate(from_relative.getDate() - 365 * 10);
        config.time_from = from_relative;
        
        config.time_to = new Date();
        config.limit = 30;
        
        config.topics_modifier = InclusionModifierEnumDTO.INCLUDE;
        
        return config;
    }
    
    clone(): ReviewManualConfig {
        const newConfig = new ReviewManualConfig();
        
        // Use a deep cloning approach for each property.
        newConfig.id = this.id;
        newConfig.userId = this.userId;
        newConfig.time_from = this.time_from ? new Date(this.time_from.getTime()) : undefined;
        newConfig.time_to = this.time_to ? new Date(this.time_to.getTime()) : undefined;
        newConfig.limit = this.limit;
        newConfig.cards_modifier = this.cards_modifier;
        newConfig.cards = this.cards ? [...this.cards] : undefined;
        newConfig.card_langs_modifier = this.card_langs_modifier;
        newConfig.card_langs = this.card_langs ? [...this.card_langs] : undefined;
        newConfig.topics_modifier = this.topics_modifier;
        newConfig.topicRelationshipOpts = this.topicRelationshipOpts;
        newConfig.topicRelationshipDepth = this.topicRelationshipDepth;
        newConfig.topics = this.topics ? [...this.topics] : undefined;
        newConfig.avgQuality = this.avgQuality;
        newConfig.avgQualityComparator = this.avgQualityComparator;
        newConfig.languages = this.languages ? [...this.languages] : undefined;
        newConfig.language_modifier = this.language_modifier;
        newConfig.tags_modifier = this.tags_modifier;
        newConfig.tags = this.tags ? [...this.tags] : undefined;
        newConfig.reviewCardKind = this.reviewCardKind;
        newConfig.createdOn = new Date(this.createdOn.getTime());
        newConfig.updatedOn = new Date(this.updatedOn.getTime());
        
        return newConfig;
    }
    
    fromDTO(dto: ReviewManualConfigDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
        this.cards = dto.getCardsList().map((x) => x.getValue());
        this.card_langs = dto.getCardLangsList().map((x) => x.getValue());
        this.cards_modifier = dto.getCardsModifier()
        this.card_langs_modifier = dto.getCardsLangsModifier()
        this.languages = dto.getLanguagesList().map((x) => x.getValue());
        this.language_modifier = dto.getLanguageFiltersModifier()
        this.limit = dto.getLimit();
        this.avgQuality = dto.getAvgquality();
        this.avgQualityComparator = dto.getAvgqualitycomparator();
        this.topicRelationshipOpts = dto.getTopicrelationshipopts()
        this.topicRelationshipDepth = dto.getTopicrelationshipdepth()
        this.topics_modifier = dto.getTopicsModifier();
        this.topics = dto.getTopicsList().map((x) => x.getValue());
        this.tags_modifier = dto.getTagsModifier();
        this.tags = dto.getTagsList().map((x) => x.getValue());
        this.createdOn = convertTimestampToDate(dto.getCreatedon()!);
        this.updatedOn = convertTimestampToDate(dto.getUpdatedon()!);
        this.reviewCardKind = dto.getReviewCardKind()
        
        dto.getTimeFrom()
            ? (this.time_from = convertTimestampToDate(dto.getTimeFrom()!))
            : (this.time_from = undefined);
        dto.getTimeTo()
            ? (this.time_to = convertTimestampToDate(dto.getTimeTo()!))
            : (this.time_to = undefined);
    }
    
    intoDTO(): IUIError | ReviewManualConfigDTO {
        const dto = new ReviewManualConfigDTO();
        
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserid(new UUID_DTO().setValue(this.userId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        dto.setReviewCardKind(this.reviewCardKind);
        dto.setLimit(this.limit);
        dto.setTopicsModifier(this.topics_modifier);
        dto.setTagsModifier(this.tags_modifier);
        dto.setLanguageFiltersModifier(this.language_modifier);
        dto.setTopicrelationshipopts(this.topicRelationshipOpts);
        dto.setTopicrelationshipdepth(this.topicRelationshipDepth);
        if (this.cards_modifier) {
            dto.setCardsModifier(this.cards_modifier);
        }
        if (this.card_langs_modifier) {
            dto.setCardsLangsModifier(this.card_langs_modifier);
        }
        
        dto.setAvgqualitycomparator(this.avgQualityComparator ?? ComparatorDTO.LT);
        if (this.avgQuality) {
            dto.setAvgquality(this.avgQuality);
        }
        
        if (this.time_from) {
            dto.setTimeFrom(convertDateToTimestamp(this.time_from));
        }
        
        if (this.time_to) {
            dto.setTimeTo(convertDateToTimestamp(this.time_to));
        }
        
        if (this.topics?.length) {
            dto.setTopicsList(this.topics.map(topic => new UUID_DTO().setValue(topic)));
        }
        
        if (this.tags?.length) {
            dto.setTagsList(this.tags.map(tag => new UUID_DTO().setValue(tag)));
        }
        
        if (this.languages?.length) {
            dto.setLanguagesList(this.languages.map(language => new UUID_DTO().setValue(language)));
        }
        
        if (this.cards?.length) {
            dto.setCardsList(this.cards.map(card => new UUID_DTO().setValue(card)));
            if (this.cards_modifier) {
                dto.setCardsModifier(this.cards_modifier);
            }
        }
        
        if (this.card_langs?.length) {
            dto.setCardLangsList(this.card_langs.map(cardLang => new UUID_DTO().setValue(cardLang)));
            if (this.card_langs_modifier) {
                dto.setCardsLangsModifier(this.card_langs_modifier);
            }
        }
        
        return dto;
    }
    
}