import {StatsServicePromiseClient} from "proto/stats_grpc_web_pb";
import {Err, Ok, Result} from "utils/result";
import {GeneralStatDTO, GetGeneralStatsRequest, ReviewTimelineStatsDTO, StatOptsDTO} from "proto/stats_pb";
import {TimelineIntDTO, UUID_DTO} from "proto/utils_pb";
import {IFromDTO} from "model/model";
import {ActionType, IUIError, NewUIErrorV2, UIErrorV2} from "service/cartaError";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {getUserId} from "service/AuthService";
import {EntityKind} from "model/BaseModel";
import {CARTA_PROXY_URL} from "consts";

export class Timeline<T> {
    value: T
    date: Date

    constructor(value: T, date: Date) {
        this.value = value
        this.date = date
    }

    static fromTimelineIntDTO(dto: TimelineIntDTO): Timeline<number> {
        let timeline = new Timeline<number>(0, new Date())
        timeline.value = dto.getValue()
        timeline.date = new Date(dto.getTimestamp()!.getSeconds()! * 1000)

        return timeline
    }
}

//message ReviewTimelineStatsDTO {
//   repeated utils.TimelineIntDTO reviewSM2ReviewedTimeline = 1;
//   repeated utils.TimelineIntDTO reviewSM2CardReviewedTimeline = 2;
//   repeated utils.TimelineIntDTO reviewManualReviewedTimeline = 3;
//   repeated utils.TimelineIntDTO reviewManualCardReviewedTimeline = 4;
//   repeated utils.TimelineIntDTO review_manual_quality_timeline = 5;
//   repeated utils.TimelineIntDTO review_sm2_quality_timeline = 6;
// }
export class ReviewTimelimeStat implements IFromDTO<ReviewTimelineStatsDTO> {
    public reviewSM2ReviewedTimeline: Timeline<number>[];
    public reviewSM2CardReviewedTimeline: Timeline<number>[];
    public reviewManualReviewedTimeline: Timeline<number>[];
    public reviewManualCardReviewedTimeline: Timeline<number>[];
    public reviewManualQualityTimeline: Timeline<number>[];
    public reviewSM2QualityTimeline: Timeline<number>[];

    constructor() {
        this.reviewSM2ReviewedTimeline = []
        this.reviewSM2CardReviewedTimeline = []
        this.reviewManualReviewedTimeline = []
        this.reviewManualCardReviewedTimeline = []
        this.reviewManualQualityTimeline = []
        this.reviewSM2QualityTimeline = []
    }

    fromDTO(dto: ReviewTimelineStatsDTO): void | IUIError {
        this.reviewSM2ReviewedTimeline = dto.getReviewsm2reviewedtimelineList().map(Timeline.fromTimelineIntDTO)
        this.reviewSM2CardReviewedTimeline = dto.getReviewsm2cardreviewedtimelineList().map(Timeline.fromTimelineIntDTO)
        this.reviewManualReviewedTimeline = dto.getReviewmanualreviewedtimelineList().map(Timeline.fromTimelineIntDTO)
        this.reviewManualCardReviewedTimeline = dto.getReviewmanualcardreviewedtimelineList().map(Timeline.fromTimelineIntDTO)
        this.reviewManualQualityTimeline = dto.getReviewManualQualityTimelineList().map(Timeline.fromTimelineIntDTO)
        this.reviewSM2QualityTimeline = dto.getReviewSm2QualityTimelineList().map(Timeline.fromTimelineIntDTO)
    }
}

// Convert the above into a TypeScript class
export class GeneralStat implements IFromDTO<GeneralStatDTO> {
    public totalTopics: number;
    public totalCards: number;
    public totalResources: number;
    public totalTopicRelationships: number;
    public totalManualReviews: number;
    public totalManualReviewCards: number;
    public totalSM2Reviews: number;
    public totalSM2Cards: number;

    public topicTimeline: Timeline<number>[];
    public cardTimeline: Timeline<number>[];
    public cardLangTimeline: Timeline<number>[];
    public topicRelationshipTimeline: Timeline<number>[];
    public resourceTimeline: Timeline<number>[];
    public reviewSM2CardTimeline: Timeline<number>[];
    public reviewSM2Timeline: Timeline<number>[];
    public manualReviewTimeline: Timeline<number>[];
    public manualReviewCardTimeline: Timeline<number>[];

    // Setup a default constructor to initialize the class with default values
    constructor() {
        this.totalTopics = 0;
        this.totalCards = 0;
        this.totalResources = 0;
        this.totalTopicRelationships = 0;
        this.totalManualReviews = 0;
        this.totalManualReviewCards = 0;
        this.totalSM2Reviews = 0;
        this.totalSM2Cards = 0;

        this.topicTimeline = [];
        this.cardTimeline = [];
        this.cardLangTimeline = [];
        this.topicRelationshipTimeline = [];
        this.resourceTimeline = [];
        this.reviewSM2CardTimeline = [];
        this.reviewSM2Timeline = [];
        this.manualReviewTimeline = [];
        this.manualReviewCardTimeline = [];
    }

    fromDTO(dto: GeneralStatDTO): void | IUIError {
        this.cardTimeline = dto.getCreatedCardsList().map(Timeline.fromTimelineIntDTO)
        this.topicTimeline = dto.getCreatedTopicsList().map(Timeline.fromTimelineIntDTO)
        this.reviewSM2CardTimeline = dto.getCreatedSm2ReviewCardsList().map(Timeline.fromTimelineIntDTO)
        this.reviewSM2Timeline = dto.getCreatedSm2ReviewsList().map(Timeline.fromTimelineIntDTO)
        this.manualReviewTimeline = dto.getCreatedManualReviewsList().map(Timeline.fromTimelineIntDTO)
        this.manualReviewCardTimeline = dto.getCreatedManualReviewCardsList().map(Timeline.fromTimelineIntDTO)
        this.resourceTimeline = dto.getCreatedResourcesList().map(Timeline.fromTimelineIntDTO)
        this.cardLangTimeline = dto.getCreatedCardLangsList().map(Timeline.fromTimelineIntDTO)


        this.totalCards = dto.getNumCards()
        this.totalTopics = dto.getNumTopics()
        this.totalResources = dto.getNumResources()
        this.totalSM2Cards = dto.getNumSm2ReviewCards()
        this.totalSM2Reviews = dto.getNumSm2Reviews()
        this.totalManualReviews = dto.getNumManualReviews()
        this.totalManualReviewCards = dto.getNumManualReviewCards()
    }
}


interface StatResponse {
    generalStats: GeneralStat;
    reviewTimelineStats: ReviewTimelimeStat;
}

export class StatsStore {
    public service: StatsServicePromiseClient;
    
    // @observable
    generalStats: GeneralStat | undefined
    // @observable
    reviewTimelineStats: ReviewTimelimeStat | undefined

    constructor(service: StatsServicePromiseClient) {
        this.service = service;

        makeObservable(this, {
            generalStats: observable,
            reviewTimelineStats: observable,
            GetStats: action,
            GeneralStats: computed,
        });
    }
    
    Clear() {
        this.generalStats = undefined
        this.reviewTimelineStats = undefined
    }

    // @computed
    get GeneralStats(): GeneralStat | undefined {
        return this.generalStats;
    }
    
    // @action
    async GetStats(opts: StatOptsDTO): Promise<Result<StatResponse, IUIError>> {
        let req: GetGeneralStatsRequest = new GetGeneralStatsRequest();

        req.setOpts(opts)

        const userId = getUserId()
        if (userId) {
            req.setUserid(new UUID_DTO().setValue(userId))
        } else {
            throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "User ID not found")
        }

        try {
            let res = await this.service.getGeneralStats(req);

            let stats: GeneralStat;
            if (res.getStats()) {
                console.log("res: ", res.getStats())
                stats = new GeneralStat();
                stats.fromDTO(res.getStats()!);

                runInAction(() => {
                    this.generalStats = stats;
                });
            } else {
                throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "Stats is undefined")
            }

            let reviewStats: ReviewTimelimeStat;
            if (res.getReviewtimelinestats()) {
                reviewStats = new ReviewTimelimeStat();
                reviewStats.fromDTO(res.getReviewtimelinestats()!);

                runInAction(() => {
                    this.reviewTimelineStats = reviewStats;
                });

            } else {
                throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "Review Stats is undefined")
            }

            return Ok({
                generalStats: stats,
                reviewTimelineStats: reviewStats
            })

        } catch (err) {
            return Err(NewUIErrorV2(ActionType.GetStats, EntityKind.Stats, err));
        }
    }
}