import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import useAuth from 'hooks/useAuth';
import {AuthenticationStateEnum} from "stores/AuthenticationStore";
import {APP_DEFAULT_PATH} from "../../consts";

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { authenticationState } = useAuth();

  const enableRipple = authenticationState.state === AuthenticationStateEnum.LoggedIn && {
    component: Link,
    to: !to ? APP_DEFAULT_PATH : to,
    sx
  };
  
  
  return (
      <>
        {/*@ts-ignore*/}
        <ButtonBase disableRipple {...enableRipple}>
          {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
        </ButtonBase>
      </>
  );
};

export default LogoSection;
