// source: card.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var tag_pb = require('./tag_pb.js');
goog.object.extend(proto, tag_pb);
var topic_pb = require('./topic_pb.js');
goog.object.extend(proto, topic_pb);
var resource_pb = require('./resource_pb.js');
goog.object.extend(proto, resource_pb);
var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var cardMedia_pb = require('./cardMedia_pb.js');
goog.object.extend(proto, cardMedia_pb);
goog.exportSymbol('proto.card.ArchiveCardRequest', null, global);
goog.exportSymbol('proto.card.ArchiveCardResponse', null, global);
goog.exportSymbol('proto.card.BaseCardDTO', null, global);
goog.exportSymbol('proto.card.CardCompositeDTO', null, global);
goog.exportSymbol('proto.card.CardDTO', null, global);
goog.exportSymbol('proto.card.CardOriginDTO', null, global);
goog.exportSymbol('proto.card.CardStatsDTO', null, global);
goog.exportSymbol('proto.card.CardStatusDTO', null, global);
goog.exportSymbol('proto.card.CreateCardRequest', null, global);
goog.exportSymbol('proto.card.CreateCardResponse', null, global);
goog.exportSymbol('proto.card.CreateCardWithTopicRelationshipRequest', null, global);
goog.exportSymbol('proto.card.CreateCardWithTopicRelationshipResponse', null, global);
goog.exportSymbol('proto.card.CreateResourceRelationRequest', null, global);
goog.exportSymbol('proto.card.CreateResourceRelationResponse', null, global);
goog.exportSymbol('proto.card.CreateTagRelationRequest', null, global);
goog.exportSymbol('proto.card.CreateTagRelationResponse', null, global);
goog.exportSymbol('proto.card.CustomCardDTO', null, global);
goog.exportSymbol('proto.card.DeleteCardRequest', null, global);
goog.exportSymbol('proto.card.DeleteCardResponse', null, global);
goog.exportSymbol('proto.card.DeleteResourceRelationRequest', null, global);
goog.exportSymbol('proto.card.DeleteResourceRelationResponse', null, global);
goog.exportSymbol('proto.card.DeleteTagRelationRequest', null, global);
goog.exportSymbol('proto.card.DeleteTagRelationResponse', null, global);
goog.exportSymbol('proto.card.GetCardGlobalStatsRequest', null, global);
goog.exportSymbol('proto.card.GetCardGlobalStatsResponse', null, global);
goog.exportSymbol('proto.card.GetCardRequest', null, global);
goog.exportSymbol('proto.card.GetCardResponse', null, global);
goog.exportSymbol('proto.card.GetCardStatRequest', null, global);
goog.exportSymbol('proto.card.GetCardStatResponse', null, global);
goog.exportSymbol('proto.card.ImportCardRequest', null, global);
goog.exportSymbol('proto.card.ImportCardResponse', null, global);
goog.exportSymbol('proto.card.ListCardByIdsRequest', null, global);
goog.exportSymbol('proto.card.ListCardByIdsResponse', null, global);
goog.exportSymbol('proto.card.ListCardRequest', null, global);
goog.exportSymbol('proto.card.ListCardResponse', null, global);
goog.exportSymbol('proto.card.ListCardStatsByIdsRequest', null, global);
goog.exportSymbol('proto.card.ListCardStatsByIdsResponse', null, global);
goog.exportSymbol('proto.card.ListCardStatsResponse', null, global);
goog.exportSymbol('proto.card.ListCardsForTopicRequest', null, global);
goog.exportSymbol('proto.card.ListCardsForTopicResponse', null, global);
goog.exportSymbol('proto.card.ListUtils', null, global);
goog.exportSymbol('proto.card.ParserOutputDTO', null, global);
goog.exportSymbol('proto.card.StatPerformanceCardDTO', null, global);
goog.exportSymbol('proto.card.TimelineIntDTO', null, global);
goog.exportSymbol('proto.card.TimelineIntervalsDTO', null, global);
goog.exportSymbol('proto.card.UpdateCardRequest', null, global);
goog.exportSymbol('proto.card.UpdateCardResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.CardDTO.repeatedFields_, null);
};
goog.inherits(proto.card.CardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CardDTO.displayName = 'proto.card.CardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CardCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.CardCompositeDTO.repeatedFields_, null);
};
goog.inherits(proto.card.CardCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CardCompositeDTO.displayName = 'proto.card.CardCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CardStatsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.CardStatsDTO.repeatedFields_, null);
};
goog.inherits(proto.card.CardStatsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CardStatsDTO.displayName = 'proto.card.CardStatsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.StatPerformanceCardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.StatPerformanceCardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.StatPerformanceCardDTO.displayName = 'proto.card.StatPerformanceCardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardGlobalStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.GetCardGlobalStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardGlobalStatsRequest.displayName = 'proto.card.GetCardGlobalStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardGlobalStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.GetCardGlobalStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.card.GetCardGlobalStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardGlobalStatsResponse.displayName = 'proto.card.GetCardGlobalStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.GetCardStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardStatRequest.displayName = 'proto.card.GetCardStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.GetCardStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardStatResponse.displayName = 'proto.card.GetCardStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardStatsByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardStatsByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardStatsByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardStatsByIdsRequest.displayName = 'proto.card.ListCardStatsByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardStatsByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardStatsByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardStatsByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardStatsByIdsResponse.displayName = 'proto.card.ListCardStatsByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardsForTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ListCardsForTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardsForTopicRequest.displayName = 'proto.card.ListCardsForTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardsForTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardsForTopicResponse.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardsForTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardsForTopicResponse.displayName = 'proto.card.ListCardsForTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardByIdsRequest.displayName = 'proto.card.ListCardByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardByIdsResponse.displayName = 'proto.card.ListCardByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateCardWithTopicRelationshipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateCardWithTopicRelationshipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateCardWithTopicRelationshipRequest.displayName = 'proto.card.CreateCardWithTopicRelationshipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateCardWithTopicRelationshipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateCardWithTopicRelationshipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateCardWithTopicRelationshipResponse.displayName = 'proto.card.CreateCardWithTopicRelationshipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateResourceRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateResourceRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateResourceRelationRequest.displayName = 'proto.card.CreateResourceRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateResourceRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateResourceRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateResourceRelationResponse.displayName = 'proto.card.CreateResourceRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateTagRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateTagRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateTagRelationRequest.displayName = 'proto.card.CreateTagRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateTagRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateTagRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateTagRelationResponse.displayName = 'proto.card.CreateTagRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteResourceRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteResourceRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteResourceRelationRequest.displayName = 'proto.card.DeleteResourceRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteResourceRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteResourceRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteResourceRelationResponse.displayName = 'proto.card.DeleteResourceRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteTagRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteTagRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteTagRelationRequest.displayName = 'proto.card.DeleteTagRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteTagRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteTagRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteTagRelationResponse.displayName = 'proto.card.DeleteTagRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ImportCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ImportCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ImportCardRequest.displayName = 'proto.card.ImportCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ImportCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ImportCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ImportCardResponse.displayName = 'proto.card.ImportCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ParserOutputDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ParserOutputDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ParserOutputDTO.displayName = 'proto.card.ParserOutputDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CustomCardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CustomCardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CustomCardDTO.displayName = 'proto.card.CustomCardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.TimelineIntDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.TimelineIntDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.TimelineIntDTO.displayName = 'proto.card.TimelineIntDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.TimelineIntervalsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.TimelineIntervalsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.TimelineIntervalsDTO.displayName = 'proto.card.TimelineIntervalsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.BaseCardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.BaseCardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.BaseCardDTO.displayName = 'proto.card.BaseCardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListUtils = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ListUtils, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListUtils.displayName = 'proto.card.ListUtils';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardStatsResponse.displayName = 'proto.card.ListCardStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.CreateCardResponse.repeatedFields_, null);
};
goog.inherits(proto.card.CreateCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateCardResponse.displayName = 'proto.card.CreateCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.CreateCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.CreateCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.CreateCardRequest.displayName = 'proto.card.CreateCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.UpdateCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.UpdateCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.UpdateCardRequest.displayName = 'proto.card.UpdateCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.UpdateCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.UpdateCardResponse.repeatedFields_, null);
};
goog.inherits(proto.card.UpdateCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.UpdateCardResponse.displayName = 'proto.card.UpdateCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.GetCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardRequest.displayName = 'proto.card.GetCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.GetCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.GetCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.GetCardResponse.displayName = 'proto.card.GetCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ListCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardRequest.displayName = 'proto.card.ListCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ListCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.card.ListCardResponse.repeatedFields_, null);
};
goog.inherits(proto.card.ListCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ListCardResponse.displayName = 'proto.card.ListCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteCardRequest.displayName = 'proto.card.DeleteCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.DeleteCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.DeleteCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.DeleteCardResponse.displayName = 'proto.card.DeleteCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ArchiveCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ArchiveCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ArchiveCardRequest.displayName = 'proto.card.ArchiveCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.card.ArchiveCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.card.ArchiveCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.card.ArchiveCardResponse.displayName = 'proto.card.ArchiveCardResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.CardDTO.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ispublic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    front: jspb.Message.getFieldWithDefault(msg, 5, ""),
    back: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    origin: jspb.Message.getFieldWithDefault(msg, 14, 0),
    composite: (f = msg.getComposite()) && proto.card.CardCompositeDTO.toObject(includeInstance, f),
    confidencevaluesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    lastreview: (f = msg.getLastreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    reviewcount: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CardDTO}
 */
proto.card.CardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CardDTO;
  return proto.card.CardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CardDTO}
 */
proto.card.CardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublic(value);
      break;
    case 4:
      var value = /** @type {!proto.card.CardStatusDTO} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFront(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBack(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    case 14:
      var value = /** @type {!proto.card.CardOriginDTO} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 15:
      var value = new proto.card.CardCompositeDTO;
      reader.readMessage(value,proto.card.CardCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConfidencevalues(values[i]);
      }
      break;
    case 17:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setLastreview(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getIspublic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {!proto.card.CardStatusDTO} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFront();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBack();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.card.CardOriginDTO} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.card.CardCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfidencevaluesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      16,
      f
    );
  }
  f = message.getLastreview();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CardDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CardDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isPublic = 3;
 * @return {boolean}
 */
proto.card.CardDTO.prototype.getIspublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setIspublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional CardStatusDTO status = 4;
 * @return {!proto.card.CardStatusDTO}
 */
proto.card.CardDTO.prototype.getStatus = function() {
  return /** @type {!proto.card.CardStatusDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.card.CardStatusDTO} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string front = 5;
 * @return {string}
 */
proto.card.CardDTO.prototype.getFront = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setFront = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string back = 6;
 * @return {string}
 */
proto.card.CardDTO.prototype.getBack = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setBack = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 9;
 * @return {string}
 */
proto.card.CardDTO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setNote = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearNote = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasNote = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.CardDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.CardDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.CardDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CardOriginDTO origin = 14;
 * @return {!proto.card.CardOriginDTO}
 */
proto.card.CardDTO.prototype.getOrigin = function() {
  return /** @type {!proto.card.CardOriginDTO} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.card.CardOriginDTO} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setOrigin = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearOrigin = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CardCompositeDTO composite = 15;
 * @return {?proto.card.CardCompositeDTO}
 */
proto.card.CardDTO.prototype.getComposite = function() {
  return /** @type{?proto.card.CardCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardCompositeDTO, 15));
};


/**
 * @param {?proto.card.CardCompositeDTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated int32 confidenceValues = 16;
 * @return {!Array<number>}
 */
proto.card.CardDTO.prototype.getConfidencevaluesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setConfidencevaluesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.addConfidencevalues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearConfidencevaluesList = function() {
  return this.setConfidencevaluesList([]);
};


/**
 * optional utils.TimestampDTO lastReview = 17;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.CardDTO.prototype.getLastreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 17));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.CardDTO} returns this
*/
proto.card.CardDTO.prototype.setLastreview = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearLastreview = function() {
  return this.setLastreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasLastreview = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int32 reviewCount = 18;
 * @return {number}
 */
proto.card.CardDTO.prototype.getReviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.setReviewcount = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.CardDTO} returns this
 */
proto.card.CardDTO.prototype.clearReviewcount = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardDTO.prototype.hasReviewcount = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.CardCompositeDTO.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CardCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CardCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CardCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    supertypeId: (f = msg.getSupertypeId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 2, 0),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    topic_pb.TopicDTO.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    tag_pb.TagDTO.toObject, includeInstance),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    resource_pb.ResourceDTO.toObject, includeInstance),
    mediaList: jspb.Message.toObjectList(msg.getMediaList(),
    cardMedia_pb.CardMediaDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CardCompositeDTO}
 */
proto.card.CardCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CardCompositeDTO;
  return proto.card.CardCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CardCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CardCompositeDTO}
 */
proto.card.CardCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setSupertypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrd(value);
      break;
    case 3:
      var value = new topic_pb.TopicDTO;
      reader.readMessage(value,topic_pb.TopicDTO.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    case 4:
      var value = new tag_pb.TagDTO;
      reader.readMessage(value,tag_pb.TagDTO.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 5:
      var value = new resource_pb.ResourceDTO;
      reader.readMessage(value,resource_pb.ResourceDTO.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 6:
      var value = new cardMedia_pb.CardMediaDTO;
      reader.readMessage(value,cardMedia_pb.CardMediaDTO.deserializeBinaryFromReader);
      msg.addMedia(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CardCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CardCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CardCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupertypeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      topic_pb.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      tag_pb.TagDTO.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      resource_pb.ResourceDTO.serializeBinaryToWriter
    );
  }
  f = message.getMediaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      cardMedia_pb.CardMediaDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO supertype_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CardCompositeDTO.prototype.getSupertypeId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CardCompositeDTO} returns this
*/
proto.card.CardCompositeDTO.prototype.setSupertypeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.clearSupertypeId = function() {
  return this.setSupertypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardCompositeDTO.prototype.hasSupertypeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 ord = 2;
 * @return {number}
 */
proto.card.CardCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated topic.TopicDTO topics = 3;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.card.CardCompositeDTO.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, topic_pb.TopicDTO, 3));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.card.CardCompositeDTO} returns this
*/
proto.card.CardCompositeDTO.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.card.CardCompositeDTO.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};


/**
 * repeated tag.TagDTO tags = 4;
 * @return {!Array<!proto.tag.TagDTO>}
 */
proto.card.CardCompositeDTO.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.tag.TagDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, tag_pb.TagDTO, 4));
};


/**
 * @param {!Array<!proto.tag.TagDTO>} value
 * @return {!proto.card.CardCompositeDTO} returns this
*/
proto.card.CardCompositeDTO.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.tag.TagDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tag.TagDTO}
 */
proto.card.CardCompositeDTO.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.tag.TagDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated resource.ResourceDTO resources = 5;
 * @return {!Array<!proto.resource.ResourceDTO>}
 */
proto.card.CardCompositeDTO.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.resource.ResourceDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, resource_pb.ResourceDTO, 5));
};


/**
 * @param {!Array<!proto.resource.ResourceDTO>} value
 * @return {!proto.card.CardCompositeDTO} returns this
*/
proto.card.CardCompositeDTO.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.resource.ResourceDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO}
 */
proto.card.CardCompositeDTO.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.resource.ResourceDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * repeated cardMedia.CardMediaDTO media = 6;
 * @return {!Array<!proto.cardMedia.CardMediaDTO>}
 */
proto.card.CardCompositeDTO.prototype.getMediaList = function() {
  return /** @type{!Array<!proto.cardMedia.CardMediaDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, cardMedia_pb.CardMediaDTO, 6));
};


/**
 * @param {!Array<!proto.cardMedia.CardMediaDTO>} value
 * @return {!proto.card.CardCompositeDTO} returns this
*/
proto.card.CardCompositeDTO.prototype.setMediaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cardMedia.CardMediaDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cardMedia.CardMediaDTO}
 */
proto.card.CardCompositeDTO.prototype.addMedia = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cardMedia.CardMediaDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardCompositeDTO} returns this
 */
proto.card.CardCompositeDTO.prototype.clearMediaList = function() {
  return this.setMediaList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.CardStatsDTO.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CardStatsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CardStatsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CardStatsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardStatsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardId: (f = msg.getCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    confidencevaluesList: jspb.Message.toObjectList(msg.getConfidencevaluesList(),
    proto.card.TimelineIntDTO.toObject, includeInstance),
    lastreview: (f = msg.getLastreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    reviewcount: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CardStatsDTO}
 */
proto.card.CardStatsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CardStatsDTO;
  return proto.card.CardStatsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CardStatsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CardStatsDTO}
 */
proto.card.CardStatsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardId(value);
      break;
    case 3:
      var value = new proto.card.TimelineIntDTO;
      reader.readMessage(value,proto.card.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addConfidencevalues(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setLastreview(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CardStatsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CardStatsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CardStatsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CardStatsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getConfidencevaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.card.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getLastreview();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO user_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CardStatsDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CardStatsDTO} returns this
*/
proto.card.CardStatsDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardStatsDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO card_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CardStatsDTO.prototype.getCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CardStatsDTO} returns this
*/
proto.card.CardStatsDTO.prototype.setCardId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.clearCardId = function() {
  return this.setCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardStatsDTO.prototype.hasCardId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TimelineIntDTO confidenceValues = 3;
 * @return {!Array<!proto.card.TimelineIntDTO>}
 */
proto.card.CardStatsDTO.prototype.getConfidencevaluesList = function() {
  return /** @type{!Array<!proto.card.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.TimelineIntDTO, 3));
};


/**
 * @param {!Array<!proto.card.TimelineIntDTO>} value
 * @return {!proto.card.CardStatsDTO} returns this
*/
proto.card.CardStatsDTO.prototype.setConfidencevaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.card.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.TimelineIntDTO}
 */
proto.card.CardStatsDTO.prototype.addConfidencevalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.card.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.clearConfidencevaluesList = function() {
  return this.setConfidencevaluesList([]);
};


/**
 * optional utils.TimestampDTO lastReview = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.CardStatsDTO.prototype.getLastreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.CardStatsDTO} returns this
*/
proto.card.CardStatsDTO.prototype.setLastreview = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.clearLastreview = function() {
  return this.setLastreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardStatsDTO.prototype.hasLastreview = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional int32 reviewCount = 17;
 * @return {number}
 */
proto.card.CardStatsDTO.prototype.getReviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.setReviewcount = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.CardStatsDTO} returns this
 */
proto.card.CardStatsDTO.prototype.clearReviewcount = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CardStatsDTO.prototype.hasReviewcount = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.StatPerformanceCardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.StatPerformanceCardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.StatPerformanceCardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.StatPerformanceCardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f),
    totalreviewcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalmanualreviewcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalsm2reviewcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgconfidence: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.StatPerformanceCardDTO}
 */
proto.card.StatPerformanceCardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.StatPerformanceCardDTO;
  return proto.card.StatPerformanceCardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.StatPerformanceCardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.StatPerformanceCardDTO}
 */
proto.card.StatPerformanceCardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalreviewcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalmanualreviewcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsm2reviewcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgconfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.StatPerformanceCardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.StatPerformanceCardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.StatPerformanceCardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.StatPerformanceCardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalmanualreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalsm2reviewcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvgconfidence();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.StatPerformanceCardDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.StatPerformanceCardDTO} returns this
*/
proto.card.StatPerformanceCardDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.StatPerformanceCardDTO} returns this
 */
proto.card.StatPerformanceCardDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.StatPerformanceCardDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 totalReviewCount = 2;
 * @return {number}
 */
proto.card.StatPerformanceCardDTO.prototype.getTotalreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.StatPerformanceCardDTO} returns this
 */
proto.card.StatPerformanceCardDTO.prototype.setTotalreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 totalManualReviewCount = 3;
 * @return {number}
 */
proto.card.StatPerformanceCardDTO.prototype.getTotalmanualreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.StatPerformanceCardDTO} returns this
 */
proto.card.StatPerformanceCardDTO.prototype.setTotalmanualreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 totalSM2ReviewCount = 4;
 * @return {number}
 */
proto.card.StatPerformanceCardDTO.prototype.getTotalsm2reviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.StatPerformanceCardDTO} returns this
 */
proto.card.StatPerformanceCardDTO.prototype.setTotalsm2reviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 avgConfidence = 5;
 * @return {number}
 */
proto.card.StatPerformanceCardDTO.prototype.getAvgconfidence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.StatPerformanceCardDTO} returns this
 */
proto.card.StatPerformanceCardDTO.prototype.setAvgconfidence = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardGlobalStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardGlobalStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardGlobalStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardGlobalStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardGlobalStatsRequest}
 */
proto.card.GetCardGlobalStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardGlobalStatsRequest;
  return proto.card.GetCardGlobalStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardGlobalStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardGlobalStatsRequest}
 */
proto.card.GetCardGlobalStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardGlobalStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardGlobalStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardGlobalStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardGlobalStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO userId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.GetCardGlobalStatsRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.GetCardGlobalStatsRequest} returns this
*/
proto.card.GetCardGlobalStatsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardGlobalStatsRequest} returns this
 */
proto.card.GetCardGlobalStatsRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardGlobalStatsRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.ListOptionsRequestDTO opts = 2;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.card.GetCardGlobalStatsRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.card.GetCardGlobalStatsRequest} returns this
*/
proto.card.GetCardGlobalStatsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardGlobalStatsRequest} returns this
 */
proto.card.GetCardGlobalStatsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardGlobalStatsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.GetCardGlobalStatsResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardGlobalStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardGlobalStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardGlobalStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardGlobalStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostreviewedList: jspb.Message.toObjectList(msg.getMostreviewedList(),
    proto.card.StatPerformanceCardDTO.toObject, includeInstance),
    strongestList: jspb.Message.toObjectList(msg.getStrongestList(),
    proto.card.StatPerformanceCardDTO.toObject, includeInstance),
    weakestList: jspb.Message.toObjectList(msg.getWeakestList(),
    proto.card.StatPerformanceCardDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardGlobalStatsResponse}
 */
proto.card.GetCardGlobalStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardGlobalStatsResponse;
  return proto.card.GetCardGlobalStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardGlobalStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardGlobalStatsResponse}
 */
proto.card.GetCardGlobalStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.StatPerformanceCardDTO;
      reader.readMessage(value,proto.card.StatPerformanceCardDTO.deserializeBinaryFromReader);
      msg.addMostreviewed(value);
      break;
    case 2:
      var value = new proto.card.StatPerformanceCardDTO;
      reader.readMessage(value,proto.card.StatPerformanceCardDTO.deserializeBinaryFromReader);
      msg.addStrongest(value);
      break;
    case 3:
      var value = new proto.card.StatPerformanceCardDTO;
      reader.readMessage(value,proto.card.StatPerformanceCardDTO.deserializeBinaryFromReader);
      msg.addWeakest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardGlobalStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardGlobalStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardGlobalStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardGlobalStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostreviewedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.StatPerformanceCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.card.StatPerformanceCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.card.StatPerformanceCardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPerformanceCardDTO mostReviewed = 1;
 * @return {!Array<!proto.card.StatPerformanceCardDTO>}
 */
proto.card.GetCardGlobalStatsResponse.prototype.getMostreviewedList = function() {
  return /** @type{!Array<!proto.card.StatPerformanceCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.StatPerformanceCardDTO, 1));
};


/**
 * @param {!Array<!proto.card.StatPerformanceCardDTO>} value
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
*/
proto.card.GetCardGlobalStatsResponse.prototype.setMostreviewedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.StatPerformanceCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.StatPerformanceCardDTO}
 */
proto.card.GetCardGlobalStatsResponse.prototype.addMostreviewed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.StatPerformanceCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
 */
proto.card.GetCardGlobalStatsResponse.prototype.clearMostreviewedList = function() {
  return this.setMostreviewedList([]);
};


/**
 * repeated StatPerformanceCardDTO strongest = 2;
 * @return {!Array<!proto.card.StatPerformanceCardDTO>}
 */
proto.card.GetCardGlobalStatsResponse.prototype.getStrongestList = function() {
  return /** @type{!Array<!proto.card.StatPerformanceCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.StatPerformanceCardDTO, 2));
};


/**
 * @param {!Array<!proto.card.StatPerformanceCardDTO>} value
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
*/
proto.card.GetCardGlobalStatsResponse.prototype.setStrongestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.card.StatPerformanceCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.StatPerformanceCardDTO}
 */
proto.card.GetCardGlobalStatsResponse.prototype.addStrongest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.card.StatPerformanceCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
 */
proto.card.GetCardGlobalStatsResponse.prototype.clearStrongestList = function() {
  return this.setStrongestList([]);
};


/**
 * repeated StatPerformanceCardDTO weakest = 3;
 * @return {!Array<!proto.card.StatPerformanceCardDTO>}
 */
proto.card.GetCardGlobalStatsResponse.prototype.getWeakestList = function() {
  return /** @type{!Array<!proto.card.StatPerformanceCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.StatPerformanceCardDTO, 3));
};


/**
 * @param {!Array<!proto.card.StatPerformanceCardDTO>} value
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
*/
proto.card.GetCardGlobalStatsResponse.prototype.setWeakestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.card.StatPerformanceCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.StatPerformanceCardDTO}
 */
proto.card.GetCardGlobalStatsResponse.prototype.addWeakest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.card.StatPerformanceCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.GetCardGlobalStatsResponse} returns this
 */
proto.card.GetCardGlobalStatsResponse.prototype.clearWeakestList = function() {
  return this.setWeakestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardStatRequest}
 */
proto.card.GetCardStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardStatRequest;
  return proto.card.GetCardStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardStatRequest}
 */
proto.card.GetCardStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.GetCardStatRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.GetCardStatRequest} returns this
*/
proto.card.GetCardStatRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardStatRequest} returns this
 */
proto.card.GetCardStatRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardStatRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardStatsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardStatResponse}
 */
proto.card.GetCardStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardStatResponse;
  return proto.card.GetCardStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardStatResponse}
 */
proto.card.GetCardStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardStatsDTO;
      reader.readMessage(value,proto.card.CardStatsDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardStatsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardStatsDTO card = 1;
 * @return {?proto.card.CardStatsDTO}
 */
proto.card.GetCardStatResponse.prototype.getCard = function() {
  return /** @type{?proto.card.CardStatsDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardStatsDTO, 1));
};


/**
 * @param {?proto.card.CardStatsDTO|undefined} value
 * @return {!proto.card.GetCardStatResponse} returns this
*/
proto.card.GetCardStatResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardStatResponse} returns this
 */
proto.card.GetCardStatResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardStatResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardStatsByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardStatsByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardStatsByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardStatsByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardStatsByIdsRequest}
 */
proto.card.ListCardStatsByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardStatsByIdsRequest;
  return proto.card.ListCardStatsByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardStatsByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardStatsByIdsRequest}
 */
proto.card.ListCardStatsByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardStatsByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardStatsByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardStatsByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.card.ListCardStatsByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.card.ListCardStatsByIdsRequest} returns this
*/
proto.card.ListCardStatsByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.card.ListCardStatsByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardStatsByIdsRequest} returns this
 */
proto.card.ListCardStatsByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardStatsByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardStatsByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardStatsByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardStatsByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.card.CardStatsDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardStatsByIdsResponse}
 */
proto.card.ListCardStatsByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardStatsByIdsResponse;
  return proto.card.ListCardStatsByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardStatsByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardStatsByIdsResponse}
 */
proto.card.ListCardStatsByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardStatsDTO;
      reader.readMessage(value,proto.card.CardStatsDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardStatsByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardStatsByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardStatsByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.CardStatsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardStatsDTO items = 1;
 * @return {!Array<!proto.card.CardStatsDTO>}
 */
proto.card.ListCardStatsByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.card.CardStatsDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.CardStatsDTO, 1));
};


/**
 * @param {!Array<!proto.card.CardStatsDTO>} value
 * @return {!proto.card.ListCardStatsByIdsResponse} returns this
*/
proto.card.ListCardStatsByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.CardStatsDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.CardStatsDTO}
 */
proto.card.ListCardStatsByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.CardStatsDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardStatsByIdsResponse} returns this
 */
proto.card.ListCardStatsByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardsForTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardsForTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardsForTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardsForTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardsForTopicRequest}
 */
proto.card.ListCardsForTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardsForTopicRequest;
  return proto.card.ListCardsForTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardsForTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardsForTopicRequest}
 */
proto.card.ListCardsForTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardsForTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardsForTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardsForTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardsForTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.ListCardsForTopicRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.ListCardsForTopicRequest} returns this
*/
proto.card.ListCardsForTopicRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardsForTopicRequest} returns this
 */
proto.card.ListCardsForTopicRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardsForTopicRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.ListOptionsRequestDTO opts = 2;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.card.ListCardsForTopicRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.card.ListCardsForTopicRequest} returns this
*/
proto.card.ListCardsForTopicRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardsForTopicRequest} returns this
 */
proto.card.ListCardsForTopicRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardsForTopicRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardsForTopicResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardsForTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardsForTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardsForTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardsForTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.card.CardDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardsForTopicResponse}
 */
proto.card.ListCardsForTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardsForTopicResponse;
  return proto.card.ListCardsForTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardsForTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardsForTopicResponse}
 */
proto.card.ListCardsForTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardsForTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardsForTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardsForTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardsForTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardDTO cards = 1;
 * @return {!Array<!proto.card.CardDTO>}
 */
proto.card.ListCardsForTopicResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.card.CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {!Array<!proto.card.CardDTO>} value
 * @return {!proto.card.ListCardsForTopicResponse} returns this
*/
proto.card.ListCardsForTopicResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.CardDTO}
 */
proto.card.ListCardsForTopicResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardsForTopicResponse} returns this
 */
proto.card.ListCardsForTopicResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional utils.ListOptionsRequestDTO info = 2;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.card.ListCardsForTopicResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.card.ListCardsForTopicResponse} returns this
*/
proto.card.ListCardsForTopicResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardsForTopicResponse} returns this
 */
proto.card.ListCardsForTopicResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardsForTopicResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardByIdsRequest}
 */
proto.card.ListCardByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardByIdsRequest;
  return proto.card.ListCardByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardByIdsRequest}
 */
proto.card.ListCardByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.card.ListCardByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.card.ListCardByIdsRequest} returns this
*/
proto.card.ListCardByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.card.ListCardByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardByIdsRequest} returns this
 */
proto.card.ListCardByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.card.CardDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardByIdsResponse}
 */
proto.card.ListCardByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardByIdsResponse;
  return proto.card.ListCardByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardByIdsResponse}
 */
proto.card.ListCardByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardDTO items = 1;
 * @return {!Array<!proto.card.CardDTO>}
 */
proto.card.ListCardByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.card.CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {!Array<!proto.card.CardDTO>} value
 * @return {!proto.card.ListCardByIdsResponse} returns this
*/
proto.card.ListCardByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.CardDTO}
 */
proto.card.ListCardByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardByIdsResponse} returns this
 */
proto.card.ListCardByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.card.ListCardByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.card.ListCardByIdsResponse} returns this
*/
proto.card.ListCardByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardByIdsResponse} returns this
 */
proto.card.ListCardByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateCardWithTopicRelationshipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateCardWithTopicRelationshipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardWithTopicRelationshipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f),
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest}
 */
proto.card.CreateCardWithTopicRelationshipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateCardWithTopicRelationshipRequest;
  return proto.card.CreateCardWithTopicRelationshipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateCardWithTopicRelationshipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest}
 */
proto.card.CreateCardWithTopicRelationshipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateCardWithTopicRelationshipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateCardWithTopicRelationshipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardWithTopicRelationshipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest} returns this
*/
proto.card.CreateCardWithTopicRelationshipRequest.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest} returns this
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO topicId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest} returns this
*/
proto.card.CreateCardWithTopicRelationshipRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateCardWithTopicRelationshipRequest} returns this
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateCardWithTopicRelationshipRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateCardWithTopicRelationshipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateCardWithTopicRelationshipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateCardWithTopicRelationshipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardWithTopicRelationshipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateCardWithTopicRelationshipResponse}
 */
proto.card.CreateCardWithTopicRelationshipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateCardWithTopicRelationshipResponse;
  return proto.card.CreateCardWithTopicRelationshipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateCardWithTopicRelationshipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateCardWithTopicRelationshipResponse}
 */
proto.card.CreateCardWithTopicRelationshipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateCardWithTopicRelationshipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateCardWithTopicRelationshipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateCardWithTopicRelationshipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardWithTopicRelationshipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.CreateCardWithTopicRelationshipResponse.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.CreateCardWithTopicRelationshipResponse} returns this
*/
proto.card.CreateCardWithTopicRelationshipResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateCardWithTopicRelationshipResponse} returns this
 */
proto.card.CreateCardWithTopicRelationshipResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateCardWithTopicRelationshipResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateResourceRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateResourceRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateResourceRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateResourceRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    resourceid: (f = msg.getResourceid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    resourceMetadataId: (f = msg.getResourceMetadataId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateResourceRelationRequest}
 */
proto.card.CreateResourceRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateResourceRelationRequest;
  return proto.card.CreateResourceRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateResourceRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateResourceRelationRequest}
 */
proto.card.CreateResourceRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceMetadataId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateResourceRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateResourceRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateResourceRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateResourceRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourceid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourceMetadataId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateResourceRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateResourceRelationRequest} returns this
*/
proto.card.CreateResourceRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateResourceRelationRequest} returns this
 */
proto.card.CreateResourceRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateResourceRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO resourceId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateResourceRelationRequest.prototype.getResourceid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateResourceRelationRequest} returns this
*/
proto.card.CreateResourceRelationRequest.prototype.setResourceid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateResourceRelationRequest} returns this
 */
proto.card.CreateResourceRelationRequest.prototype.clearResourceid = function() {
  return this.setResourceid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateResourceRelationRequest.prototype.hasResourceid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO resource_metadata_id = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateResourceRelationRequest.prototype.getResourceMetadataId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateResourceRelationRequest} returns this
*/
proto.card.CreateResourceRelationRequest.prototype.setResourceMetadataId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateResourceRelationRequest} returns this
 */
proto.card.CreateResourceRelationRequest.prototype.clearResourceMetadataId = function() {
  return this.setResourceMetadataId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateResourceRelationRequest.prototype.hasResourceMetadataId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateResourceRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateResourceRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateResourceRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateResourceRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateResourceRelationResponse}
 */
proto.card.CreateResourceRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateResourceRelationResponse;
  return proto.card.CreateResourceRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateResourceRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateResourceRelationResponse}
 */
proto.card.CreateResourceRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateResourceRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateResourceRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateResourceRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateResourceRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateTagRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateTagRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateTagRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateTagRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    tagid: (f = msg.getTagid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateTagRelationRequest}
 */
proto.card.CreateTagRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateTagRelationRequest;
  return proto.card.CreateTagRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateTagRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateTagRelationRequest}
 */
proto.card.CreateTagRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTagid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateTagRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateTagRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateTagRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateTagRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateTagRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateTagRelationRequest} returns this
*/
proto.card.CreateTagRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateTagRelationRequest} returns this
 */
proto.card.CreateTagRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateTagRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO tagId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.CreateTagRelationRequest.prototype.getTagid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.CreateTagRelationRequest} returns this
*/
proto.card.CreateTagRelationRequest.prototype.setTagid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateTagRelationRequest} returns this
 */
proto.card.CreateTagRelationRequest.prototype.clearTagid = function() {
  return this.setTagid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateTagRelationRequest.prototype.hasTagid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateTagRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateTagRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateTagRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateTagRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateTagRelationResponse}
 */
proto.card.CreateTagRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateTagRelationResponse;
  return proto.card.CreateTagRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateTagRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateTagRelationResponse}
 */
proto.card.CreateTagRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateTagRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateTagRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateTagRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateTagRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteResourceRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteResourceRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteResourceRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteResourceRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    resourceid: (f = msg.getResourceid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteResourceRelationRequest}
 */
proto.card.DeleteResourceRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteResourceRelationRequest;
  return proto.card.DeleteResourceRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteResourceRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteResourceRelationRequest}
 */
proto.card.DeleteResourceRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteResourceRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteResourceRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteResourceRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteResourceRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourceid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.DeleteResourceRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.DeleteResourceRelationRequest} returns this
*/
proto.card.DeleteResourceRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.DeleteResourceRelationRequest} returns this
 */
proto.card.DeleteResourceRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.DeleteResourceRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO resourceId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.DeleteResourceRelationRequest.prototype.getResourceid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.DeleteResourceRelationRequest} returns this
*/
proto.card.DeleteResourceRelationRequest.prototype.setResourceid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.DeleteResourceRelationRequest} returns this
 */
proto.card.DeleteResourceRelationRequest.prototype.clearResourceid = function() {
  return this.setResourceid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.DeleteResourceRelationRequest.prototype.hasResourceid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteResourceRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteResourceRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteResourceRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteResourceRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteResourceRelationResponse}
 */
proto.card.DeleteResourceRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteResourceRelationResponse;
  return proto.card.DeleteResourceRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteResourceRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteResourceRelationResponse}
 */
proto.card.DeleteResourceRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteResourceRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteResourceRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteResourceRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteResourceRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteTagRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteTagRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteTagRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteTagRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    tagid: (f = msg.getTagid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteTagRelationRequest}
 */
proto.card.DeleteTagRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteTagRelationRequest;
  return proto.card.DeleteTagRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteTagRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteTagRelationRequest}
 */
proto.card.DeleteTagRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTagid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteTagRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteTagRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteTagRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteTagRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.DeleteTagRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.DeleteTagRelationRequest} returns this
*/
proto.card.DeleteTagRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.DeleteTagRelationRequest} returns this
 */
proto.card.DeleteTagRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.DeleteTagRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO tagId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.DeleteTagRelationRequest.prototype.getTagid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.DeleteTagRelationRequest} returns this
*/
proto.card.DeleteTagRelationRequest.prototype.setTagid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.DeleteTagRelationRequest} returns this
 */
proto.card.DeleteTagRelationRequest.prototype.clearTagid = function() {
  return this.setTagid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.DeleteTagRelationRequest.prototype.hasTagid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteTagRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteTagRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteTagRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteTagRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteTagRelationResponse}
 */
proto.card.DeleteTagRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteTagRelationResponse;
  return proto.card.DeleteTagRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteTagRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteTagRelationResponse}
 */
proto.card.DeleteTagRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteTagRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteTagRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteTagRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteTagRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ImportCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ImportCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ImportCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ImportCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ImportCardRequest}
 */
proto.card.ImportCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ImportCardRequest;
  return proto.card.ImportCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ImportCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ImportCardRequest}
 */
proto.card.ImportCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ImportCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ImportCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ImportCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ImportCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO user_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.ImportCardRequest.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.ImportCardRequest} returns this
*/
proto.card.ImportCardRequest.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ImportCardRequest} returns this
 */
proto.card.ImportCardRequest.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ImportCardRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.card.ImportCardRequest.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.card.ImportCardRequest.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.card.ImportCardRequest.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.card.ImportCardRequest} returns this
 */
proto.card.ImportCardRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ImportCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ImportCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ImportCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ImportCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    output: (f = msg.getOutput()) && proto.card.ParserOutputDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ImportCardResponse}
 */
proto.card.ImportCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ImportCardResponse;
  return proto.card.ImportCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ImportCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ImportCardResponse}
 */
proto.card.ImportCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.ParserOutputDTO;
      reader.readMessage(value,proto.card.ParserOutputDTO.deserializeBinaryFromReader);
      msg.setOutput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ImportCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ImportCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ImportCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ImportCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutput();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.ParserOutputDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ParserOutputDTO output = 1;
 * @return {?proto.card.ParserOutputDTO}
 */
proto.card.ImportCardResponse.prototype.getOutput = function() {
  return /** @type{?proto.card.ParserOutputDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.ParserOutputDTO, 1));
};


/**
 * @param {?proto.card.ParserOutputDTO|undefined} value
 * @return {!proto.card.ImportCardResponse} returns this
*/
proto.card.ImportCardResponse.prototype.setOutput = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ImportCardResponse} returns this
 */
proto.card.ImportCardResponse.prototype.clearOutput = function() {
  return this.setOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ImportCardResponse.prototype.hasOutput = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ParserOutputDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ParserOutputDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ParserOutputDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ParserOutputDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    insertedAnkartaCards: jspb.Message.getFieldWithDefault(msg, 1, 0),
    insertAnkiCards: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ParserOutputDTO}
 */
proto.card.ParserOutputDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ParserOutputDTO;
  return proto.card.ParserOutputDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ParserOutputDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ParserOutputDTO}
 */
proto.card.ParserOutputDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsertedAnkartaCards(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsertAnkiCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ParserOutputDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ParserOutputDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ParserOutputDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ParserOutputDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsertedAnkartaCards();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getInsertAnkiCards();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 inserted_ankarta_cards = 1;
 * @return {number}
 */
proto.card.ParserOutputDTO.prototype.getInsertedAnkartaCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.ParserOutputDTO} returns this
 */
proto.card.ParserOutputDTO.prototype.setInsertedAnkartaCards = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 insert_anki_cards = 2;
 * @return {number}
 */
proto.card.ParserOutputDTO.prototype.getInsertAnkiCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.ParserOutputDTO} returns this
 */
proto.card.ParserOutputDTO.prototype.setInsertAnkiCards = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CustomCardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CustomCardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CustomCardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CustomCardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.card.CardStatsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CustomCardDTO}
 */
proto.card.CustomCardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CustomCardDTO;
  return proto.card.CustomCardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CustomCardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CustomCardDTO}
 */
proto.card.CustomCardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = new proto.card.CardStatsDTO;
      reader.readMessage(value,proto.card.CardStatsDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CustomCardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CustomCardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CustomCardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CustomCardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.card.CardStatsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.CustomCardDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.CustomCardDTO} returns this
*/
proto.card.CustomCardDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CustomCardDTO} returns this
 */
proto.card.CustomCardDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CustomCardDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CardStatsDTO stats = 2;
 * @return {?proto.card.CardStatsDTO}
 */
proto.card.CustomCardDTO.prototype.getStats = function() {
  return /** @type{?proto.card.CardStatsDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardStatsDTO, 2));
};


/**
 * @param {?proto.card.CardStatsDTO|undefined} value
 * @return {!proto.card.CustomCardDTO} returns this
*/
proto.card.CustomCardDTO.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CustomCardDTO} returns this
 */
proto.card.CustomCardDTO.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CustomCardDTO.prototype.hasStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.TimelineIntDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.TimelineIntDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.TimelineIntDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.TimelineIntDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.TimelineIntDTO}
 */
proto.card.TimelineIntDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.TimelineIntDTO;
  return proto.card.TimelineIntDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.TimelineIntDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.TimelineIntDTO}
 */
proto.card.TimelineIntDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.TimelineIntDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.TimelineIntDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.TimelineIntDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.TimelineIntDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional utils.TimestampDTO date = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.TimelineIntDTO.prototype.getDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.TimelineIntDTO} returns this
*/
proto.card.TimelineIntDTO.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.TimelineIntDTO} returns this
 */
proto.card.TimelineIntDTO.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.TimelineIntDTO.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.card.TimelineIntDTO.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.TimelineIntDTO} returns this
 */
proto.card.TimelineIntDTO.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.TimelineIntervalsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.TimelineIntervalsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.TimelineIntervalsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.TimelineIntervalsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.TimelineIntervalsDTO}
 */
proto.card.TimelineIntervalsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.TimelineIntervalsDTO;
  return proto.card.TimelineIntervalsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.TimelineIntervalsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.TimelineIntervalsDTO}
 */
proto.card.TimelineIntervalsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.TimelineIntervalsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.TimelineIntervalsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.TimelineIntervalsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.TimelineIntervalsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional utils.TimestampDTO date = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.TimelineIntervalsDTO.prototype.getDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.TimelineIntervalsDTO} returns this
*/
proto.card.TimelineIntervalsDTO.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.TimelineIntervalsDTO} returns this
 */
proto.card.TimelineIntervalsDTO.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.TimelineIntervalsDTO.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.card.TimelineIntervalsDTO.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.TimelineIntervalsDTO} returns this
 */
proto.card.TimelineIntervalsDTO.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.BaseCardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.card.BaseCardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.BaseCardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.BaseCardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ispublic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    front: jspb.Message.getFieldWithDefault(msg, 5, ""),
    back: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    origin: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.BaseCardDTO}
 */
proto.card.BaseCardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.BaseCardDTO;
  return proto.card.BaseCardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.BaseCardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.BaseCardDTO}
 */
proto.card.BaseCardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublic(value);
      break;
    case 4:
      var value = /** @type {!proto.card.CardStatusDTO} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFront(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBack(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    case 11:
      var value = /** @type {!proto.card.CardOriginDTO} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.BaseCardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.BaseCardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.BaseCardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.BaseCardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getIspublic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {!proto.card.CardStatusDTO} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFront();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBack();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.card.CardOriginDTO} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.BaseCardDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.BaseCardDTO} returns this
*/
proto.card.BaseCardDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.BaseCardDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.BaseCardDTO} returns this
*/
proto.card.BaseCardDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isPublic = 3;
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.getIspublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setIspublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional CardStatusDTO status = 4;
 * @return {!proto.card.CardStatusDTO}
 */
proto.card.BaseCardDTO.prototype.getStatus = function() {
  return /** @type {!proto.card.CardStatusDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.card.CardStatusDTO} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string front = 5;
 * @return {string}
 */
proto.card.BaseCardDTO.prototype.getFront = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setFront = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string back = 6;
 * @return {string}
 */
proto.card.BaseCardDTO.prototype.getBack = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setBack = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.card.BaseCardDTO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setNote = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearNote = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasNote = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 8;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.BaseCardDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 8));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.BaseCardDTO} returns this
*/
proto.card.BaseCardDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.BaseCardDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.BaseCardDTO} returns this
*/
proto.card.BaseCardDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.card.BaseCardDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.card.BaseCardDTO} returns this
*/
proto.card.BaseCardDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CardOriginDTO origin = 11;
 * @return {!proto.card.CardOriginDTO}
 */
proto.card.BaseCardDTO.prototype.getOrigin = function() {
  return /** @type {!proto.card.CardOriginDTO} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.card.CardOriginDTO} value
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.setOrigin = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.card.BaseCardDTO} returns this
 */
proto.card.BaseCardDTO.prototype.clearOrigin = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.BaseCardDTO.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListUtils.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListUtils.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListUtils} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListUtils.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListUtils}
 */
proto.card.ListUtils.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListUtils;
  return proto.card.ListUtils.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListUtils} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListUtils}
 */
proto.card.ListUtils.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListUtils.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListUtils.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListUtils} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListUtils.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.card.ListUtils.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.ListUtils} returns this
 */
proto.card.ListUtils.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.card.ListUtils.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.card.ListUtils} returns this
 */
proto.card.ListUtils.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.card.CardStatsDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardStatsResponse}
 */
proto.card.ListCardStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardStatsResponse;
  return proto.card.ListCardStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardStatsResponse}
 */
proto.card.ListCardStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardStatsDTO;
      reader.readMessage(value,proto.card.CardStatsDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.CardStatsDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardStatsDTO cards = 1;
 * @return {!Array<!proto.card.CardStatsDTO>}
 */
proto.card.ListCardStatsResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.card.CardStatsDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.CardStatsDTO, 1));
};


/**
 * @param {!Array<!proto.card.CardStatsDTO>} value
 * @return {!proto.card.ListCardStatsResponse} returns this
*/
proto.card.ListCardStatsResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.CardStatsDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.CardStatsDTO}
 */
proto.card.ListCardStatsResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.CardStatsDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardStatsResponse} returns this
 */
proto.card.ListCardStatsResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional utils.ListOptionsRequestDTO info = 2;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.card.ListCardStatsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.card.ListCardStatsResponse} returns this
*/
proto.card.ListCardStatsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardStatsResponse} returns this
 */
proto.card.ListCardStatsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardStatsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.CreateCardResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f),
    signedUrlsList: jspb.Message.toObjectList(msg.getSignedUrlsList(),
    cardMedia_pb.CardMediaSignedURLDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateCardResponse}
 */
proto.card.CreateCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateCardResponse;
  return proto.card.CreateCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateCardResponse}
 */
proto.card.CreateCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = new cardMedia_pb.CardMediaSignedURLDTO;
      reader.readMessage(value,cardMedia_pb.CardMediaSignedURLDTO.deserializeBinaryFromReader);
      msg.addSignedUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getSignedUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      cardMedia_pb.CardMediaSignedURLDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.CreateCardResponse.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.CreateCardResponse} returns this
*/
proto.card.CreateCardResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateCardResponse} returns this
 */
proto.card.CreateCardResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateCardResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated cardMedia.CardMediaSignedURLDTO signed_urls = 2;
 * @return {!Array<!proto.cardMedia.CardMediaSignedURLDTO>}
 */
proto.card.CreateCardResponse.prototype.getSignedUrlsList = function() {
  return /** @type{!Array<!proto.cardMedia.CardMediaSignedURLDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, cardMedia_pb.CardMediaSignedURLDTO, 2));
};


/**
 * @param {!Array<!proto.cardMedia.CardMediaSignedURLDTO>} value
 * @return {!proto.card.CreateCardResponse} returns this
*/
proto.card.CreateCardResponse.prototype.setSignedUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cardMedia.CardMediaSignedURLDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cardMedia.CardMediaSignedURLDTO}
 */
proto.card.CreateCardResponse.prototype.addSignedUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cardMedia.CardMediaSignedURLDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.CreateCardResponse} returns this
 */
proto.card.CreateCardResponse.prototype.clearSignedUrlsList = function() {
  return this.setSignedUrlsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.CreateCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.CreateCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.CreateCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.CreateCardRequest}
 */
proto.card.CreateCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.CreateCardRequest;
  return proto.card.CreateCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.CreateCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.CreateCardRequest}
 */
proto.card.CreateCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.CreateCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.CreateCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.CreateCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.CreateCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.CreateCardRequest.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.CreateCardRequest} returns this
*/
proto.card.CreateCardRequest.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.CreateCardRequest} returns this
 */
proto.card.CreateCardRequest.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.CreateCardRequest.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.UpdateCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.UpdateCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.UpdateCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.UpdateCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.UpdateCardRequest}
 */
proto.card.UpdateCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.UpdateCardRequest;
  return proto.card.UpdateCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.UpdateCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.UpdateCardRequest}
 */
proto.card.UpdateCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.UpdateCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.UpdateCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.UpdateCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.UpdateCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.UpdateCardRequest.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.UpdateCardRequest} returns this
*/
proto.card.UpdateCardRequest.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.UpdateCardRequest} returns this
 */
proto.card.UpdateCardRequest.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.UpdateCardRequest.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.UpdateCardResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.UpdateCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.UpdateCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.UpdateCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.UpdateCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f),
    signedUrlsList: jspb.Message.toObjectList(msg.getSignedUrlsList(),
    cardMedia_pb.CardMediaSignedURLDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.UpdateCardResponse}
 */
proto.card.UpdateCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.UpdateCardResponse;
  return proto.card.UpdateCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.UpdateCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.UpdateCardResponse}
 */
proto.card.UpdateCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = new cardMedia_pb.CardMediaSignedURLDTO;
      reader.readMessage(value,cardMedia_pb.CardMediaSignedURLDTO.deserializeBinaryFromReader);
      msg.addSignedUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.UpdateCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.UpdateCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.UpdateCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.UpdateCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getSignedUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      cardMedia_pb.CardMediaSignedURLDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.UpdateCardResponse.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.UpdateCardResponse} returns this
*/
proto.card.UpdateCardResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.UpdateCardResponse} returns this
 */
proto.card.UpdateCardResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.UpdateCardResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated cardMedia.CardMediaSignedURLDTO signed_urls = 2;
 * @return {!Array<!proto.cardMedia.CardMediaSignedURLDTO>}
 */
proto.card.UpdateCardResponse.prototype.getSignedUrlsList = function() {
  return /** @type{!Array<!proto.cardMedia.CardMediaSignedURLDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, cardMedia_pb.CardMediaSignedURLDTO, 2));
};


/**
 * @param {!Array<!proto.cardMedia.CardMediaSignedURLDTO>} value
 * @return {!proto.card.UpdateCardResponse} returns this
*/
proto.card.UpdateCardResponse.prototype.setSignedUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cardMedia.CardMediaSignedURLDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cardMedia.CardMediaSignedURLDTO}
 */
proto.card.UpdateCardResponse.prototype.addSignedUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cardMedia.CardMediaSignedURLDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.UpdateCardResponse} returns this
 */
proto.card.UpdateCardResponse.prototype.clearSignedUrlsList = function() {
  return this.setSignedUrlsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardRequest}
 */
proto.card.GetCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardRequest;
  return proto.card.GetCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardRequest}
 */
proto.card.GetCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.GetCardRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.GetCardRequest} returns this
*/
proto.card.GetCardRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardRequest} returns this
 */
proto.card.GetCardRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.GetCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.GetCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.GetCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.card.CardDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.GetCardResponse}
 */
proto.card.GetCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.GetCardResponse;
  return proto.card.GetCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.GetCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.GetCardResponse}
 */
proto.card.GetCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.GetCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.GetCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.GetCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.GetCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardDTO card = 1;
 * @return {?proto.card.CardDTO}
 */
proto.card.GetCardResponse.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.card.GetCardResponse} returns this
*/
proto.card.GetCardResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.GetCardResponse} returns this
 */
proto.card.GetCardResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.GetCardResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardRequest}
 */
proto.card.ListCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardRequest;
  return proto.card.ListCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardRequest}
 */
proto.card.ListCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.card.ListCardRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.card.ListCardRequest} returns this
*/
proto.card.ListCardRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardRequest} returns this
 */
proto.card.ListCardRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.card.ListCardResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ListCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ListCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ListCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.card.CardDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ListCardResponse}
 */
proto.card.ListCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ListCardResponse;
  return proto.card.ListCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ListCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ListCardResponse}
 */
proto.card.ListCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.card.CardDTO;
      reader.readMessage(value,proto.card.CardDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ListCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ListCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ListCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ListCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.card.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardDTO items = 1;
 * @return {!Array<!proto.card.CardDTO>}
 */
proto.card.ListCardResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.card.CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.card.CardDTO, 1));
};


/**
 * @param {!Array<!proto.card.CardDTO>} value
 * @return {!proto.card.ListCardResponse} returns this
*/
proto.card.ListCardResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.card.CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.card.CardDTO}
 */
proto.card.ListCardResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.card.CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.card.ListCardResponse} returns this
 */
proto.card.ListCardResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.card.ListCardResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.card.ListCardResponse} returns this
*/
proto.card.ListCardResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ListCardResponse} returns this
 */
proto.card.ListCardResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ListCardResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteCardRequest}
 */
proto.card.DeleteCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteCardRequest;
  return proto.card.DeleteCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteCardRequest}
 */
proto.card.DeleteCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.DeleteCardRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.DeleteCardRequest} returns this
*/
proto.card.DeleteCardRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.DeleteCardRequest} returns this
 */
proto.card.DeleteCardRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.DeleteCardRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.DeleteCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.DeleteCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.DeleteCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.DeleteCardResponse}
 */
proto.card.DeleteCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.DeleteCardResponse;
  return proto.card.DeleteCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.DeleteCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.DeleteCardResponse}
 */
proto.card.DeleteCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.DeleteCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.DeleteCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.DeleteCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.DeleteCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ArchiveCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ArchiveCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ArchiveCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ArchiveCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ArchiveCardRequest}
 */
proto.card.ArchiveCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ArchiveCardRequest;
  return proto.card.ArchiveCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ArchiveCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ArchiveCardRequest}
 */
proto.card.ArchiveCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ArchiveCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ArchiveCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ArchiveCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ArchiveCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.card.ArchiveCardRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.card.ArchiveCardRequest} returns this
*/
proto.card.ArchiveCardRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.card.ArchiveCardRequest} returns this
 */
proto.card.ArchiveCardRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.card.ArchiveCardRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.card.ArchiveCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.card.ArchiveCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.card.ArchiveCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ArchiveCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.card.ArchiveCardResponse}
 */
proto.card.ArchiveCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.card.ArchiveCardResponse;
  return proto.card.ArchiveCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.card.ArchiveCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.card.ArchiveCardResponse}
 */
proto.card.ArchiveCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.card.ArchiveCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.card.ArchiveCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.card.ArchiveCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.card.ArchiveCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.card.CardStatusDTO = {
  CARDSTATUSDTO_UNKNOWN: 0,
  CARDSTATUSDTO_INCOMPLETE: 1,
  CARDSTATUSDTO_INCORRECT: 2,
  CARDSTATUSDTO_PENDING: 3
};

/**
 * @enum {number}
 */
proto.card.CardOriginDTO = {
  CARDORIGINDTO_UNKNOWN: 0,
  CARDORIGINDTO_LOCAL: 1,
  CARDORIGINDTO_ANKI: 2,
  CARDORIGINDTO_ANKARTA: 3,
  CARDORIGINDTO_CHROMEEXTENSION: 4,
  CARDORIGINDTO_FIREFOXEXTENSION: 5,
  CARDORIGINDTO_SAFARIEXTENSION: 6,
  CARDORIGINDTO_ANDROID: 7,
  CARDORIGINDTO_IOS: 8
};

goog.object.extend(exports, proto.card);
