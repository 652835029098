import {ActionType, IUIError, LogErrorToProvider, NewUIErrorV2} from "../service/cartaError";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	ListItem,
	replacePlaceholders,
} from "../utils/utils";
import {EnumReviewKindDTO, ProgressStateEnumDTO, UUID_DTO} from "../proto/utils_pb";
import {ReviewManualDTO,} from "../proto/reviewManual_pb";
import {IModel, IReceiveOnlyModel} from "./model";
import {IReview, IReviewStat} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {ReviewStatDTO} from "../proto/stats_pb";
import {ReviewCard, ReviewCardStat} from "./ReviewCard";
import {MAX_LIMIT_REVIEW_DESCRIPTION, MAX_LIMIT_REVIEW_NAME} from "../consts";
import messages from "../text/en.json";
import {Card} from "./Card";

export class Fetchable<T extends IModel> {
	private _model: T;
	private _lastFetched?: Date;
	
	constructor(obj: T) {
		this._model = obj
	}
	
	get model(): T {
		return this._model;
	}
	
	set model(value: T) {
		this._model = value;
	}
	
	get lastFetched(): Date | undefined {
		return this._lastFetched;
	}
	
	set lastFetched(value: Date | undefined) {
		this._lastFetched = value;
	}
}

export class ReviewManual extends BaseModel<ReviewManual, ReviewManualDTO> implements IReview {
	name?: string;
	description?: string;
	note?: string;
	cards: ReviewCard[];
	progressState: ProgressStateEnumDTO;
	currentCard: string;
	configId: string;
	kind: EnumReviewKindDTO;
	startAt?: Date;
	endAt?: Date;
	archivedOn?: Date;
	cardOrderList: string[] = []
	
	constructor() {
		super();
		this.kind = EnumReviewKindDTO.MANUAL;
		this.currentCard = "";
		this.note = "";
		this.progressState = ProgressStateEnumDTO.NOT_STARTED;
		this.cards = [];
		this.configId = ""
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.name ? this.name : `Review: ${this.createdOn.toString()}`,
			metadata1: this.description,
		}
	}
	
	static fromConfig = (configId: string): ReviewManual => {
		let review = new ReviewManual();
		review.configId = configId;
		
		return review;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.name ? this.name : "Untitled",
			description: this.description,
		};
	}
	
	to1LineString(): String {
		return `${this.name} - ${this.description}`;
	}
	
	init(): ReviewManual {
		return new ReviewManual()
	}
	
	TYPE: EntityKind = EntityKind.ReviewManual;
	
	clone(): ReviewManual {
		const review = new ReviewManual();
		
		// Use spread operator and direct assignments for primitive and reference types.
		review.cards = [...this.cards];
		review.id = this.id;
		review.configId = this.configId;
		review.userId = this.userId;
		review.name = this.name;
		review.description = this.description;
		review.progressState = this.progressState;
		review.currentCard = this.currentCard;
		review.cardOrderList = [...this.cardOrderList];
		review.note = this.note;
		review.kind = this.kind;
		review.startAt = this.startAt;
		review.endAt = this.endAt;
		review.createdOn = this.createdOn;
		review.updatedOn = this.updatedOn;
		review.archivedOn = this.archivedOn;
		
		return review;
	}
	
	fromDTO = (dto: ReviewManualDTO): void | IUIError => {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
		if (dto.hasReviewconfigid()) {
			this.configId = convertFromDTOToID('configId', this.TYPE, dto.getReviewconfigid()!);
		} else {
			LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "config_id field empty", "config_id cannot be empty"))
		}
		this.name = dto.getName();
		this.description = dto.getDescription();
		this.note = dto.getNote();
		this.progressState = dto.getProgressState();
		this.kind = EnumReviewKindDTO.MANUAL;
		this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true);
		this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true);
		this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true);
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon(), false)!;
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!;
		this.cardOrderList = dto.getCardOrderListList().map((x) => x.getValue())
		if (this.cardOrderList.length === 0) {
			LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "cardOrderList field empty", "cardOrderList cannot be empty"))
		}
		if (dto.hasCurrentCardId()) {
			this.currentCard = convertFromDTOToID('currentCard', EntityKind.ReviewCard, dto.getCurrentCardId()!);
		} else {
			if (this.cardOrderList.length > 0) {
				this.currentCard = this.cardOrderList[0]
			} else {
				LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `reviewId: ${this.id} has no currentCardId or cardOrderList ... defaulting to first`))
			}
		}
	};
	
	customValidate = (): ReviewManual | IUIError => {
		if (this.name && this.name?.length >MAX_LIMIT_REVIEW_NAME) {
			const placeholders = [MAX_LIMIT_REVIEW_NAME.toString()]
			const errMessage = replacePlaceholders(messages["error.review.manual.name.length.oob"], placeholders)
			return NewUIErrorV2(ActionType.UIValidate, this.TYPE, undefined, undefined, errMessage)
		}
		
		if (this.description && this.description?.length > MAX_LIMIT_REVIEW_DESCRIPTION) {
			const placeholders = [MAX_LIMIT_REVIEW_DESCRIPTION.toString()]
			const errMessage = replacePlaceholders(messages["error.review.manual.description.length.oob"], placeholders)
			return NewUIErrorV2(ActionType.UIValidate, this.TYPE, undefined, undefined, errMessage)
		}
		
		if (this.configId.length === 0) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "config_id field empty", "config_id cannot be empty")
		}
		
		if (this.progressState === ProgressStateEnumDTO.IN_PROGRESS) {
			if (this.startAt === undefined) {
				return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "startAt field empty", "startAt cannot be empty if review has began")
			}
		}
		
		if (this.progressState === ProgressStateEnumDTO.COMPLETE) {
			if (this.endAt === undefined) {
				return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "endAt field empty", "endAt cannot be empty if review is complete")
			}
		}
		
		return this.sanitize()
	};
	
	sanitize = (): ReviewManual => {
		this.name = this.name?.trim();
		this.description = this.description?.trim();
		this.note = this.note?.trim();
		
		return this
	};
	
	intoDTO = (): ReviewManualDTO | IUIError => {
		let dto = new ReviewManualDTO();
		
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setUserid(new UUID_DTO().setValue(this.userId));
		dto.setReviewconfigid(new UUID_DTO().setValue(this.configId));
		
		if (this.name) {
			dto.setName(this.name);
		}
		if (this.description) {
			dto.setDescription(this.description);
		}
		
		dto.setNote(this.note ? this.note : "");
		dto.setProgressState(this.progressState);
		
		
		if (this.currentCard) {
			dto.setCurrentCardId(new UUID_DTO().setValue(this.currentCard));
		}
		
		dto.setCardOrderListList(this.cardOrderList.map((id) => {
			return new UUID_DTO().setValue(id)
		}))
		
		if (this.endAt) {
			dto.setEndAt(convertDateToTimestamp(this.endAt));
		}
		if (this.startAt) {
			dto.setStartAt(convertDateToTimestamp(this.startAt));
		}
		if (this.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
		}
		
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		
		return dto;
	};
}

export class ReviewManualStat implements IReceiveOnlyModel<ReviewManualStat, ReviewStatDTO>, IReviewStat {
	id: string = "";
	reviewId: string = "";
	userId: string = "";
	strongestCards: string[] = [];
	weakestCards: string[] = [];
	avgCompletedQuality?: number;
	avgCompletedInterval?: number;
	avgCompletedRepetitions?: number;
	avgQuality?: number;
	avgInterval?: number;
	avgRepetition?: number;
	mostRepeatedCard?: string;
	reviewCards?: ReviewCardStat[] = [];
	
	constructor() {
	}
	
	TYPE: EntityKind = EntityKind.ReviewManualStat;
	
	clone(): ReviewManualStat {
		let stat = new ReviewManualStat();
		stat.id = this.id;
		stat.strongestCards = [...this.strongestCards];
		stat.weakestCards = [...this.weakestCards];
		stat.avgCompletedQuality = this.avgCompletedQuality;
		stat.avgCompletedInterval = this.avgCompletedInterval;
		stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
		stat.avgQuality = this.avgQuality;
		stat.avgInterval = this.avgInterval;
		stat.avgRepetition = this.avgRepetition;
		stat.mostRepeatedCard = this.mostRepeatedCard;
		if (this.reviewCards) {
			stat.reviewCards = [...this.reviewCards];
		}
		
		return stat;
	}
	
	fromDTO(review: ReviewStatDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, review.getId());
		
		this.strongestCards = review
			.getStrongestcardsList()
			.map((x) => x.getValue());
		this.weakestCards = review.getWeakestcardsList().map((x) => x.getValue());
		this.avgCompletedRepetitions = review.getAvgcompletedrepetition();
		this.avgRepetition = review.getAvgrepetition();
		this.avgQuality = review.getAvgquality();
		this.avgCompletedQuality = review.getAvgcompletedquality();
		this.avgCompletedInterval = review.getAvgcompletedinterval();
		this.avgInterval = review.getAvginterval();
		this.reviewCards = review.getReviewcardstatsList().map((x) => {
			let stat = new ReviewCardStat();
			stat.fromDTO(x);
			return stat;
		});
	}
}



