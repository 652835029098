import {makeObservable, ObservableMap, runInAction, toJS,} from "mobx";
import {
	ActionType,
	InternalErrorTypes,
	isError,
	IUIError,
	NewInternalError,
	NewUIError,
	NewUIErrorV2,
	UIErrorV2,
} from "service/cartaError";
import {Card} from "model/Card";
import {UUID_DTO, UUID_DTO as UUID} from "../proto/utils_pb";
import {
	ArchiveCardRequest,
	ArchiveCardResponse,
	CardDTO,
	CardStatusDTO,
	CreateCardWithTopicRelationshipRequest,
	CreateCardWithTopicRelationshipResponse,
	CreateResourceRelationRequest,
	CreateTagRelationRequest,
	DeleteResourceRelationRequest,
	DeleteTagRelationRequest,
	GetCardGlobalStatsRequest,
	ImportCardRequest,
	ImportCardResponse,
	StatPerformanceCardDTO,
} from "proto/card_pb";
import {getUserId} from "service/AuthService";
import {handleFileUploads, IsUUIDValid, ListItem, MapInsertAtHead, NewUUID, unifiedInterceptor} from "utils/utils";
import {CardComposite} from "model/CardComposite";
import {CardGRPCImpl, CardService} from "service/CardService";
import {Tag} from "model/tag";
import {Resource} from "model/resource/Resource";
import {CardMedia, CardMediaSignedURL} from "model/CardMedia";
import {Err, Ok, Result} from "utils/result";
import {IFromDTO} from "model/model";
import {EntityKind, ValidListable} from "model/BaseModel";
import {Topic} from "model/topic";
import {CardSaveOpts} from "components/carta/editor/CardEditor";
import {CardServicePromiseClient} from "proto/card_grpc_web_pb";
import {CARTA_PROXY_URL} from "consts";
import BaseStore from "./BaseStore";

export const cardClient = new CardServicePromiseClient(
	CARTA_PROXY_URL!,
	null,
	{'withCredentials': true, unifiedInterceptors: [unifiedInterceptor]}
);

async function handleMediaUploads(cardMedia: CardMedia[], signedURLs: CardMediaSignedURL[]): Promise<Result<void, IUIError>> {
	try {
		const resp = await handleFileUploads(signedURLs, cardMedia);
		if (!resp.ok) {
			return Err(resp.error as IUIError)
		}
	} catch (e) {
		return Err(e as IUIError)
	}
	
	return Ok(undefined)
}

export class StatPerformanceCard implements IFromDTO<StatPerformanceCardDTO>, ValidListable {
	public card: Card
	public totalReviewCount: number
	public totalManualReviewCount: number
	public totalSM2ReviewCount: number
	public avgConfidence: number
	
	constructor() {
		this.card = new Card()
		this.totalReviewCount = 0
		this.totalManualReviewCount = 0
		this.totalSM2ReviewCount = 0
		this.avgConfidence = 0
		this.id = NewUUID()
	}
	
	id: string;
	
	toListItem(): ListItem {
		return {
			id: this.card.id,
			title: this.card.front,
			metadata1: `${this.totalReviewCount}`,
			metadata2: `${this.totalManualReviewCount}`,
			metadata3: `${this.totalSM2ReviewCount}`,
			metadata4: `${this.avgConfidence}`,
		} as ListItem
	}
	
	fromDTO(t: StatPerformanceCardDTO): void | IUIError {
		this.card.fromDTO(t.getCard()!)
		this.totalReviewCount = t.getTotalreviewcount()
		this.totalManualReviewCount = t.getTotalmanualreviewcount()
		this.totalSM2ReviewCount = t.getTotalsm2reviewcount()
		this.avgConfidence = t.getAvgconfidence()
		
	}
}

interface CardGlobalStats {
	mostReviewed: StatPerformanceCard[]
	strongest: StatPerformanceCard[]
	weakest: StatPerformanceCard[]
}

export class CardStore extends BaseStore<Card, CardDTO, CardGRPCImpl, CardService> {
	// public cardMap: ObservableMap<string, Card> = new ObservableMap();
	// @observable
	public cardCompositeMap: ObservableMap<string, CardComposite> = new ObservableMap();
	service: CardService;
	
	// @observable
	public mostReviewed: StatPerformanceCard[] = [];
	// @observable
	public strongest: StatPerformanceCard[] = [];
	// @observable
	public weakest: StatPerformanceCard[] = [];
	
	constructor(service: CardService) {
		super(service, Card);
		
		// makeObservable(this, {
		//     cardCompositeMap: observable,
		//     mostReviewed: observable,
		//     strongest: observable,
		//     weakest: observable,
		//
		//     updateCard: action,
		//     createCard: action,
		//     createCardWithTopicRelation: action,
		//     archiveCard: action,
		//
		//
		// });
		
		makeObservable(this, {});
		
		
		this.service = service;
	}
	
	public updateCard = async (card: Card, topics: Topic[], tags: Tag[], resources: Resource[], media: CardMedia[], opts?: CardSaveOpts): Promise<Result<Card, IUIError>> => {
		let newCard = card.clone();
		
		let composite = new CardComposite();
		composite.topics = [...topics];
		composite.tags = [...tags];
		composite.resources = [...resources];
		composite.media = [...media];
		
		newCard.composite = composite;
		
		if (opts && opts.isDraft) {
			newCard.status = CardStatusDTO.CARDSTATUSDTO_INCOMPLETE;
		}
		
		console.log("draft: ", opts?.isDraft)
		
		const res = await this.service.UpdateWithMedia(newCard)
		
		if (res.ok) {
			runInAction(() => {
				this.map.delete(card.id);
				this.map = MapInsertAtHead(this.map, res.value.card.id, res.value.card)
			});
			
			const res2 = await handleMediaUploads(media, res.value.signedURLs);
			if (!(res2.ok)) {
				return Err(res2.error as IUIError)
			}
			return Ok(res.value.card)
		} else {
			return Err(res.error as IUIError)
		}
	}
	
	async GetGlobalStats(): Promise<Result<CardGlobalStats, IUIError>> {
		let req: GetCardGlobalStatsRequest = new GetCardGlobalStatsRequest();
		
		const userId = getUserId()
		if (userId) {
			req.setUserid(new UUID_DTO().setValue(userId))
		} else {
			throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "User ID not found")
		}
		
		try {
			let res = await cardClient.statsGetCardGlobalStats(req)
			
			let mostReviewed: StatPerformanceCard[] = [];
			let strongest: StatPerformanceCard[] = [];
			let weakest: StatPerformanceCard[] = [];
			
			res.getMostreviewedList().forEach((dto) => {
				let stat = new StatPerformanceCard();
				stat.fromDTO(dto)
				
				mostReviewed.push(stat)
			})
			
			runInAction(() => {
				this.mostReviewed = mostReviewed;
			});
			
			res.getStrongestList().forEach((dto) => {
				let stat = new StatPerformanceCard();
				stat.fromDTO(dto)
				
				strongest.push(stat)
			})
			
			runInAction(() => {
				this.strongest = strongest;
			});
			
			res.getWeakestList().forEach((dto) => {
				let stat = new StatPerformanceCard();
				stat.fromDTO(dto)
				
				weakest.push(stat)
			})
			
			runInAction(() => {
				this.weakest = weakest;
			});
			
			return Ok(
				{
					mostReviewed: mostReviewed,
					strongest: strongest,
					weakest: weakest
				}
			)
		} catch (err) {
			return Err(NewUIErrorV2(ActionType.GetStats, EntityKind.Stats, err));
		}
	}
	
	public createCard = async (card: Card, media: CardMedia[]): Promise<Result<Card, IUIError>> => {
		const res = await this.service.CreateWithMedia(card)
		
		if (res.ok) {
			runInAction(() => {
				this.map = MapInsertAtHead(this.map, res.value.card.id, res.value.card)
			});
			
			const res2 = await handleMediaUploads(media, res.value.signedURLs);
			if (!(res2.ok)) {
				return Err(res2.error as IUIError)
			}
			return Ok(res.value.card)
		} else {
			return Err(res.error as IUIError)
		}
	}
	
	public createCardWithTopicRelation = async (
		card: Card,
		topicId: string
	): Promise<Card | IUIError> => {
		const errorKind = InternalErrorTypes.CreateCard;
		const origin = "createCardWithTopicRelation";
		
		if (!IsUUIDValid(topicId)) {
			return NewUIError(
				origin,
				errorKind,
				`supplied topicId: ${topicId} is invalid`
			);
		}
		
		const parse = toJS(card);
		
		card.userId = getUserId();
		
		const validatedCard = parse.customValidate();
		if (isError(validatedCard)) {
			return NewUIError(
				origin,
				errorKind,
				"invalid card", ``,
				validatedCard as IUIError
			);
		}
		
		const dto = (validatedCard as Card).intoDTO();
		if (isError(validatedCard)) {
			return NewUIError(
				origin,
				errorKind,
				"", ``,
				dto as IUIError
			);
		}
		
		const req = new CreateCardWithTopicRelationshipRequest();
		req.setCard(dto as CardDTO);
		
		try {
			const response: CreateCardWithTopicRelationshipResponse =
				await cardClient.create(req);
			
			if (response.getCard() === undefined) {
				return NewUIError(
					"CreateCard",
					InternalErrorTypes.CreateCard,
					"returned value undefined"
				);
			}
			
			let createdCard = new Card();
			const err = createdCard.fromDTO(response.getCard() as CardDTO)
			
			if (err) {
				return NewUIError(
					origin,
					errorKind,
					"failed to validate object",
					undefined,
					err
				);
			}
			
			runInAction(() => {
				this.map.set(createdCard.id, createdCard);
			});
			
			return createdCard;
		} catch (err) {
			return NewUIError(
				"CreateCard",
				InternalErrorTypes.CreateCard,
				`store: failed to create cards: Err(Value = ${err})`
			);
		}
	};
	
	// TODO: Implement Archive for BaseStore
	public archiveCard = async (id: string) => {
		if (!IsUUIDValid(id)) {
			return NewUIError(
				"archiveCard",
				InternalErrorTypes.GetCard,
				`supplied id: ${id} is invalid`
			);
		}
		
		const req = new ArchiveCardRequest();
		req.setCardid(new UUID_DTO().setValue(id));
		
		
		try {
			const response: ArchiveCardResponse = await cardClient.archive(
				req,
			);
			runInAction(() => {
				this.map.delete(id);
			});
			return;
		} catch (err) {
			NewInternalError(
				"archiveCard",
				InternalErrorTypes.ArchiveCard,
				`store: failed to fetch cards: Err(Value = ${err})`
			);
		}
	};
	
	ExportAnkarta = async (): Promise<void | IUIError> => {
		const req = new ImportCardRequest();
		
		const user_id = getUserId();
		const user_id_uuid = new UUID().setValue(user_id);
		
		req.setUserId(user_id_uuid);
		
		
		try {
			
			const response: ImportCardResponse = await cardClient.importCard(
				req,
			);
			
			if (!response.hasOutput() && !response.getOutput()) {
				console.error("import card: no output");
			}
		} catch (err) {
			console.error("import card: caught err: ", err);
		}
	};
	
	public addTagRelation = async (
		cardId: string,
		tagId: string
	): Promise<void | IUIError> => {
		if (!IsUUIDValid(cardId)) {
			return NewUIError(
				"addTagRelation",
				InternalErrorTypes.CardTagRelation,
				`supplied cardId: ${cardId} is invalid`
			);
		}
		
		if (!IsUUIDValid(tagId)) {
			return NewUIError(
				"addTagRelation",
				InternalErrorTypes.CardTagRelation,
				`supplied tagId: ${tagId} is invalid`
			);
		}
		
		const req = new CreateTagRelationRequest();
		req.setCardid(new UUID_DTO().setValue(cardId));
		req.setTagid(new UUID_DTO().setValue(tagId));
		
		
		try {
			await cardClient.createTagRelationship(req);
			return;
		} catch (err) {
			return NewUIError(
				"addTagRelation",
				InternalErrorTypes.CardTagRelation,
				`store: failed to add tag relation: Err(Value = ${err}`
			);
		}
	};
	
	public deleteTagRelation = async (
		cardId: string,
		tagId: string
	): Promise<void | IUIError> => {
		if (!IsUUIDValid(cardId)) {
			return NewUIError(
				"deleteTagRelation",
				InternalErrorTypes.CardTagRelation,
				`supplied cardId: ${cardId} is invalid`
			);
		}
		
		if (!IsUUIDValid(tagId)) {
			return NewUIError(
				"deleteTagRelation",
				InternalErrorTypes.CardTagRelation,
				`supplied tagId: ${tagId} is invalid`
			);
		}
		
		const req = new DeleteTagRelationRequest();
		req.setCardid(new UUID_DTO().setValue(cardId));
		req.setTagid(new UUID_DTO().setValue(tagId));
		
		
		try {
			await cardClient.deleteTagRelationship(req);
			return;
		} catch (err) {
			return NewUIError(
				"deleteTagRelation",
				InternalErrorTypes.CardTagRelation,
				`store: failed to delete tag relation: Err(Value = ${err}`
			);
		}
	};
	
	public addResourceRelation = async (
		cardId: string,
		resourceId: string
	): Promise<void | IUIError> => {
		if (!IsUUIDValid(cardId)) {
			return NewUIError(
				"addResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`supplied cardId: ${cardId} is invalid`
			);
		}
		
		if (!IsUUIDValid(resourceId)) {
			return NewUIError(
				"addResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`supplied resourceId: ${resourceId} is invalid`
			);
		}
		
		const req = new CreateResourceRelationRequest();
		req.setCardid(new UUID_DTO().setValue(cardId));
		req.setResourceid(new UUID_DTO().setValue(resourceId));
		
		
		try {
			await cardClient.createResourceRelationship(req);
		} catch (err) {
			return NewUIError(
				"addResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`store: failed to add resource relation: Err(Value = ${err}`
			);
		}
	};
	
	public deleteResourceRelation = async (
		cardId: string,
		resourceId: string
	): Promise<void | IUIError> => {
		if (!IsUUIDValid(cardId)) {
			return NewUIError(
				"deleteResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`supplied cardId: ${cardId} is invalid`
			);
		}
		
		if (!IsUUIDValid(resourceId)) {
			return NewUIError(
				"deleteResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`supplied resourceId: ${resourceId} is invalid`
			);
		}
		
		const req = new DeleteResourceRelationRequest();
		req.setCardid(new UUID_DTO().setValue(cardId));
		req.setResourceid(new UUID_DTO().setValue(resourceId));
		
		
		try {
			await cardClient.deleteResourceRelationship(req);
		} catch (err) {
			return NewUIError(
				"deleteResourceRelation",
				InternalErrorTypes.CardResourceRelation,
				`store: failed to delete resource relation: Err(Value = ${err}`
			);
		}
	};
}
