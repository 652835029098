// source: stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var review_pb = require('./review_pb.js');
goog.object.extend(proto, review_pb);
var topic_pb = require('./topic_pb.js');
goog.object.extend(proto, topic_pb);
goog.exportSymbol('proto.stats.CardStatDTO', null, global);
goog.exportSymbol('proto.stats.GeneralStatDTO', null, global);
goog.exportSymbol('proto.stats.GetGeneralStatsRequest', null, global);
goog.exportSymbol('proto.stats.GetGeneralStatsResponse', null, global);
goog.exportSymbol('proto.stats.GetReviewManualStatsRequest', null, global);
goog.exportSymbol('proto.stats.GetReviewManualStatsResponse', null, global);
goog.exportSymbol('proto.stats.GetReviewSM2StatsRequest', null, global);
goog.exportSymbol('proto.stats.GetReviewSM2StatsResponse', null, global);
goog.exportSymbol('proto.stats.GetTopicGlobalStatsRequest', null, global);
goog.exportSymbol('proto.stats.GetTopicGlobalStatsResponse', null, global);
goog.exportSymbol('proto.stats.GetTopicStatRequest', null, global);
goog.exportSymbol('proto.stats.GetTopicStatResponse', null, global);
goog.exportSymbol('proto.stats.ReviewCardStatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewStatCompositeDTO', null, global);
goog.exportSymbol('proto.stats.ReviewStatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewTimelineStatsDTO', null, global);
goog.exportSymbol('proto.stats.StatOptsDTO', null, global);
goog.exportSymbol('proto.stats.StatPerformanceTopicDTO', null, global);
goog.exportSymbol('proto.stats.TopicStatDTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetReviewSM2StatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetReviewSM2StatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetReviewSM2StatsRequest.displayName = 'proto.stats.GetReviewSM2StatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetReviewSM2StatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetReviewSM2StatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetReviewSM2StatsResponse.displayName = 'proto.stats.GetReviewSM2StatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetReviewManualStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetReviewManualStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetReviewManualStatsRequest.displayName = 'proto.stats.GetReviewManualStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetReviewManualStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetReviewManualStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetReviewManualStatsResponse.displayName = 'proto.stats.GetReviewManualStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetTopicStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetTopicStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetTopicStatRequest.displayName = 'proto.stats.GetTopicStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetTopicStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetTopicStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetTopicStatResponse.displayName = 'proto.stats.GetTopicStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.StatPerformanceTopicDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.StatPerformanceTopicDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.StatPerformanceTopicDTO.displayName = 'proto.stats.StatPerformanceTopicDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetTopicGlobalStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetTopicGlobalStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetTopicGlobalStatsRequest.displayName = 'proto.stats.GetTopicGlobalStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetTopicGlobalStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.GetTopicGlobalStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.stats.GetTopicGlobalStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetTopicGlobalStatsResponse.displayName = 'proto.stats.GetTopicGlobalStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetGeneralStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetGeneralStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetGeneralStatsRequest.displayName = 'proto.stats.GetGeneralStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetGeneralStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetGeneralStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetGeneralStatsResponse.displayName = 'proto.stats.GetGeneralStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.StatOptsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.StatOptsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.StatOptsDTO.displayName = 'proto.stats.StatOptsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewTimelineStatsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewTimelineStatsDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewTimelineStatsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewTimelineStatsDTO.displayName = 'proto.stats.ReviewTimelineStatsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GeneralStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.GeneralStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.GeneralStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GeneralStatDTO.displayName = 'proto.stats.GeneralStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewStatCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewStatCompositeDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewStatCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewStatCompositeDTO.displayName = 'proto.stats.ReviewStatCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewStatDTO.displayName = 'proto.stats.ReviewStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewCardStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.ReviewCardStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewCardStatDTO.displayName = 'proto.stats.ReviewCardStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.CardStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.CardStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.CardStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.CardStatDTO.displayName = 'proto.stats.CardStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.TopicStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.TopicStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.TopicStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.TopicStatDTO.displayName = 'proto.stats.TopicStatDTO';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetReviewSM2StatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetReviewSM2StatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetReviewSM2StatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewSM2StatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetReviewSM2StatsRequest}
 */
proto.stats.GetReviewSM2StatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetReviewSM2StatsRequest;
  return proto.stats.GetReviewSM2StatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetReviewSM2StatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetReviewSM2StatsRequest}
 */
proto.stats.GetReviewSM2StatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetReviewSM2StatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetReviewSM2StatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetReviewSM2StatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewSM2StatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewID = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetReviewSM2StatsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetReviewSM2StatsRequest} returns this
*/
proto.stats.GetReviewSM2StatsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetReviewSM2StatsRequest} returns this
 */
proto.stats.GetReviewSM2StatsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetReviewSM2StatsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetReviewSM2StatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetReviewSM2StatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetReviewSM2StatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewSM2StatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto.stats.ReviewStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetReviewSM2StatsResponse}
 */
proto.stats.GetReviewSM2StatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetReviewSM2StatsResponse;
  return proto.stats.GetReviewSM2StatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetReviewSM2StatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetReviewSM2StatsResponse}
 */
proto.stats.GetReviewSM2StatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.ReviewStatDTO;
      reader.readMessage(value,proto.stats.ReviewStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetReviewSM2StatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetReviewSM2StatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetReviewSM2StatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewSM2StatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.ReviewStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewStatDTO stats = 1;
 * @return {?proto.stats.ReviewStatDTO}
 */
proto.stats.GetReviewSM2StatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewStatDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.ReviewStatDTO, 1));
};


/**
 * @param {?proto.stats.ReviewStatDTO|undefined} value
 * @return {!proto.stats.GetReviewSM2StatsResponse} returns this
*/
proto.stats.GetReviewSM2StatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetReviewSM2StatsResponse} returns this
 */
proto.stats.GetReviewSM2StatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetReviewSM2StatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetReviewManualStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetReviewManualStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetReviewManualStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewManualStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetReviewManualStatsRequest}
 */
proto.stats.GetReviewManualStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetReviewManualStatsRequest;
  return proto.stats.GetReviewManualStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetReviewManualStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetReviewManualStatsRequest}
 */
proto.stats.GetReviewManualStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetReviewManualStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetReviewManualStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetReviewManualStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewManualStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetReviewManualStatsRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetReviewManualStatsRequest} returns this
*/
proto.stats.GetReviewManualStatsRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetReviewManualStatsRequest} returns this
 */
proto.stats.GetReviewManualStatsRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetReviewManualStatsRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetReviewManualStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetReviewManualStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetReviewManualStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewManualStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto.stats.ReviewStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetReviewManualStatsResponse}
 */
proto.stats.GetReviewManualStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetReviewManualStatsResponse;
  return proto.stats.GetReviewManualStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetReviewManualStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetReviewManualStatsResponse}
 */
proto.stats.GetReviewManualStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.ReviewStatDTO;
      reader.readMessage(value,proto.stats.ReviewStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetReviewManualStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetReviewManualStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetReviewManualStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetReviewManualStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.ReviewStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewStatDTO stats = 1;
 * @return {?proto.stats.ReviewStatDTO}
 */
proto.stats.GetReviewManualStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewStatDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.ReviewStatDTO, 1));
};


/**
 * @param {?proto.stats.ReviewStatDTO|undefined} value
 * @return {!proto.stats.GetReviewManualStatsResponse} returns this
*/
proto.stats.GetReviewManualStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetReviewManualStatsResponse} returns this
 */
proto.stats.GetReviewManualStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetReviewManualStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetTopicStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetTopicStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetTopicStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetTopicStatRequest}
 */
proto.stats.GetTopicStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetTopicStatRequest;
  return proto.stats.GetTopicStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetTopicStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetTopicStatRequest}
 */
proto.stats.GetTopicStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetTopicStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetTopicStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetTopicStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetTopicStatRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetTopicStatRequest} returns this
*/
proto.stats.GetTopicStatRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetTopicStatRequest} returns this
 */
proto.stats.GetTopicStatRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetTopicStatRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetTopicStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetTopicStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetTopicStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto.stats.TopicStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetTopicStatResponse}
 */
proto.stats.GetTopicStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetTopicStatResponse;
  return proto.stats.GetTopicStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetTopicStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetTopicStatResponse}
 */
proto.stats.GetTopicStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.TopicStatDTO;
      reader.readMessage(value,proto.stats.TopicStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetTopicStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetTopicStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetTopicStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.TopicStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicStatDTO stats = 1;
 * @return {?proto.stats.TopicStatDTO}
 */
proto.stats.GetTopicStatResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.TopicStatDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.TopicStatDTO, 1));
};


/**
 * @param {?proto.stats.TopicStatDTO|undefined} value
 * @return {!proto.stats.GetTopicStatResponse} returns this
*/
proto.stats.GetTopicStatResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetTopicStatResponse} returns this
 */
proto.stats.GetTopicStatResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetTopicStatResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.StatPerformanceTopicDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.StatPerformanceTopicDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.StatPerformanceTopicDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatPerformanceTopicDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && topic_pb.TopicDTO.toObject(includeInstance, f),
    totalreviewcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalmanualreviewcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalsm2reviewcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgconfidence: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.StatPerformanceTopicDTO}
 */
proto.stats.StatPerformanceTopicDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.StatPerformanceTopicDTO;
  return proto.stats.StatPerformanceTopicDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.StatPerformanceTopicDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.StatPerformanceTopicDTO}
 */
proto.stats.StatPerformanceTopicDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new topic_pb.TopicDTO;
      reader.readMessage(value,topic_pb.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalreviewcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalmanualreviewcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsm2reviewcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgconfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.StatPerformanceTopicDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.StatPerformanceTopicDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.StatPerformanceTopicDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatPerformanceTopicDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      topic_pb.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalmanualreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalsm2reviewcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvgconfidence();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional topic.TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.stats.StatPerformanceTopicDTO.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, topic_pb.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
*/
proto.stats.StatPerformanceTopicDTO.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
 */
proto.stats.StatPerformanceTopicDTO.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatPerformanceTopicDTO.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 totalReviewCount = 2;
 * @return {number}
 */
proto.stats.StatPerformanceTopicDTO.prototype.getTotalreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
 */
proto.stats.StatPerformanceTopicDTO.prototype.setTotalreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 totalManualReviewCount = 3;
 * @return {number}
 */
proto.stats.StatPerformanceTopicDTO.prototype.getTotalmanualreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
 */
proto.stats.StatPerformanceTopicDTO.prototype.setTotalmanualreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 totalSM2ReviewCount = 4;
 * @return {number}
 */
proto.stats.StatPerformanceTopicDTO.prototype.getTotalsm2reviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
 */
proto.stats.StatPerformanceTopicDTO.prototype.setTotalsm2reviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 avgConfidence = 5;
 * @return {number}
 */
proto.stats.StatPerformanceTopicDTO.prototype.getAvgconfidence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.StatPerformanceTopicDTO} returns this
 */
proto.stats.StatPerformanceTopicDTO.prototype.setAvgconfidence = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetTopicGlobalStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetTopicGlobalStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicGlobalStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && proto.stats.StatOptsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetTopicGlobalStatsRequest}
 */
proto.stats.GetTopicGlobalStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetTopicGlobalStatsRequest;
  return proto.stats.GetTopicGlobalStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetTopicGlobalStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetTopicGlobalStatsRequest}
 */
proto.stats.GetTopicGlobalStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.stats.StatOptsDTO;
      reader.readMessage(value,proto.stats.StatOptsDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetTopicGlobalStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetTopicGlobalStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicGlobalStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.StatOptsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO userId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetTopicGlobalStatsRequest} returns this
*/
proto.stats.GetTopicGlobalStatsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetTopicGlobalStatsRequest} returns this
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatOptsDTO opts = 2;
 * @return {?proto.stats.StatOptsDTO}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.getOpts = function() {
  return /** @type{?proto.stats.StatOptsDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.StatOptsDTO, 2));
};


/**
 * @param {?proto.stats.StatOptsDTO|undefined} value
 * @return {!proto.stats.GetTopicGlobalStatsRequest} returns this
*/
proto.stats.GetTopicGlobalStatsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetTopicGlobalStatsRequest} returns this
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetTopicGlobalStatsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.GetTopicGlobalStatsResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetTopicGlobalStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetTopicGlobalStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicGlobalStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostreviewedList: jspb.Message.toObjectList(msg.getMostreviewedList(),
    proto.stats.StatPerformanceTopicDTO.toObject, includeInstance),
    strongestList: jspb.Message.toObjectList(msg.getStrongestList(),
    proto.stats.StatPerformanceTopicDTO.toObject, includeInstance),
    weakestList: jspb.Message.toObjectList(msg.getWeakestList(),
    proto.stats.StatPerformanceTopicDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetTopicGlobalStatsResponse}
 */
proto.stats.GetTopicGlobalStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetTopicGlobalStatsResponse;
  return proto.stats.GetTopicGlobalStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetTopicGlobalStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetTopicGlobalStatsResponse}
 */
proto.stats.GetTopicGlobalStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.stats.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addMostreviewed(value);
      break;
    case 2:
      var value = new proto.stats.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.stats.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addStrongest(value);
      break;
    case 3:
      var value = new proto.stats.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.stats.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addWeakest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetTopicGlobalStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetTopicGlobalStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetTopicGlobalStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostreviewedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.stats.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.stats.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.stats.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPerformanceTopicDTO mostReviewed = 1;
 * @return {!Array<!proto.stats.StatPerformanceTopicDTO>}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.getMostreviewedList = function() {
  return /** @type{!Array<!proto.stats.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.StatPerformanceTopicDTO, 1));
};


/**
 * @param {!Array<!proto.stats.StatPerformanceTopicDTO>} value
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
*/
proto.stats.GetTopicGlobalStatsResponse.prototype.setMostreviewedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.stats.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.StatPerformanceTopicDTO}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.addMostreviewed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.stats.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.clearMostreviewedList = function() {
  return this.setMostreviewedList([]);
};


/**
 * repeated StatPerformanceTopicDTO strongest = 2;
 * @return {!Array<!proto.stats.StatPerformanceTopicDTO>}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.getStrongestList = function() {
  return /** @type{!Array<!proto.stats.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.StatPerformanceTopicDTO, 2));
};


/**
 * @param {!Array<!proto.stats.StatPerformanceTopicDTO>} value
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
*/
proto.stats.GetTopicGlobalStatsResponse.prototype.setStrongestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.stats.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.StatPerformanceTopicDTO}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.addStrongest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.stats.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.clearStrongestList = function() {
  return this.setStrongestList([]);
};


/**
 * repeated StatPerformanceTopicDTO weakest = 3;
 * @return {!Array<!proto.stats.StatPerformanceTopicDTO>}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.getWeakestList = function() {
  return /** @type{!Array<!proto.stats.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.StatPerformanceTopicDTO, 3));
};


/**
 * @param {!Array<!proto.stats.StatPerformanceTopicDTO>} value
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
*/
proto.stats.GetTopicGlobalStatsResponse.prototype.setWeakestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.stats.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.StatPerformanceTopicDTO}
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.addWeakest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.stats.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GetTopicGlobalStatsResponse} returns this
 */
proto.stats.GetTopicGlobalStatsResponse.prototype.clearWeakestList = function() {
  return this.setWeakestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetGeneralStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetGeneralStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetGeneralStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && proto.stats.StatOptsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetGeneralStatsRequest}
 */
proto.stats.GetGeneralStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetGeneralStatsRequest;
  return proto.stats.GetGeneralStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetGeneralStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetGeneralStatsRequest}
 */
proto.stats.GetGeneralStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.stats.StatOptsDTO;
      reader.readMessage(value,proto.stats.StatOptsDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetGeneralStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetGeneralStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetGeneralStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.StatOptsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO userId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetGeneralStatsRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
*/
proto.stats.GetGeneralStatsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
 */
proto.stats.GetGeneralStatsRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatOptsDTO opts = 2;
 * @return {?proto.stats.StatOptsDTO}
 */
proto.stats.GetGeneralStatsRequest.prototype.getOpts = function() {
  return /** @type{?proto.stats.StatOptsDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.StatOptsDTO, 2));
};


/**
 * @param {?proto.stats.StatOptsDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
*/
proto.stats.GetGeneralStatsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
 */
proto.stats.GetGeneralStatsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetGeneralStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetGeneralStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetGeneralStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto.stats.GeneralStatDTO.toObject(includeInstance, f),
    reviewtimelinestats: (f = msg.getReviewtimelinestats()) && proto.stats.ReviewTimelineStatsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetGeneralStatsResponse}
 */
proto.stats.GetGeneralStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetGeneralStatsResponse;
  return proto.stats.GetGeneralStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetGeneralStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetGeneralStatsResponse}
 */
proto.stats.GetGeneralStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.GeneralStatDTO;
      reader.readMessage(value,proto.stats.GeneralStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 2:
      var value = new proto.stats.ReviewTimelineStatsDTO;
      reader.readMessage(value,proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader);
      msg.setReviewtimelinestats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetGeneralStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetGeneralStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetGeneralStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.GeneralStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewtimelinestats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralStatDTO stats = 1;
 * @return {?proto.stats.GeneralStatDTO}
 */
proto.stats.GetGeneralStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.GeneralStatDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.GeneralStatDTO, 1));
};


/**
 * @param {?proto.stats.GeneralStatDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
*/
proto.stats.GetGeneralStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
 */
proto.stats.GetGeneralStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewTimelineStatsDTO reviewTimelineStats = 2;
 * @return {?proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.GetGeneralStatsResponse.prototype.getReviewtimelinestats = function() {
  return /** @type{?proto.stats.ReviewTimelineStatsDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.ReviewTimelineStatsDTO, 2));
};


/**
 * @param {?proto.stats.ReviewTimelineStatsDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
*/
proto.stats.GetGeneralStatsResponse.prototype.setReviewtimelinestats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
 */
proto.stats.GetGeneralStatsResponse.prototype.clearReviewtimelinestats = function() {
  return this.setReviewtimelinestats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsResponse.prototype.hasReviewtimelinestats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.StatOptsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.StatOptsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.StatOptsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatOptsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    to: (f = msg.getTo()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.StatOptsDTO}
 */
proto.stats.StatOptsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.StatOptsDTO;
  return proto.stats.StatOptsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.StatOptsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.StatOptsDTO}
 */
proto.stats.StatOptsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.StatOptsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.StatOptsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.StatOptsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatOptsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.TimestampDTO from = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.StatOptsDTO.prototype.getFrom = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.StatOptsDTO} returns this
*/
proto.stats.StatOptsDTO.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatOptsDTO} returns this
 */
proto.stats.StatOptsDTO.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatOptsDTO.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.TimestampDTO to = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.StatOptsDTO.prototype.getTo = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.StatOptsDTO} returns this
*/
proto.stats.StatOptsDTO.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatOptsDTO} returns this
 */
proto.stats.StatOptsDTO.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatOptsDTO.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewTimelineStatsDTO.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewTimelineStatsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewTimelineStatsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewTimelineStatsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsm2reviewedtimelineList: jspb.Message.toObjectList(msg.getReviewsm2reviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewsm2cardreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewsm2cardreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewmanualreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewmanualreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewmanualcardreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewmanualcardreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewManualQualityTimelineList: jspb.Message.toObjectList(msg.getReviewManualQualityTimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewSm2QualityTimelineList: jspb.Message.toObjectList(msg.getReviewSm2QualityTimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.ReviewTimelineStatsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewTimelineStatsDTO;
  return proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewTimelineStatsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewsm2reviewedtimeline(value);
      break;
    case 2:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewsm2cardreviewedtimeline(value);
      break;
    case 3:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewmanualreviewedtimeline(value);
      break;
    case 4:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewmanualcardreviewedtimeline(value);
      break;
    case 5:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewManualQualityTimeline(value);
      break;
    case 6:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewSm2QualityTimeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewTimelineStatsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsm2reviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewsm2cardreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewmanualreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewmanualcardreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewManualQualityTimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewSm2QualityTimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.TimelineIntDTO reviewSM2ReviewedTimeline = 1;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewsm2reviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 1));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewsm2reviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewsm2reviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewsm2reviewedtimelineList = function() {
  return this.setReviewsm2reviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewSM2CardReviewedTimeline = 2;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewsm2cardreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 2));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewsm2cardreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewsm2cardreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewsm2cardreviewedtimelineList = function() {
  return this.setReviewsm2cardreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewManualReviewedTimeline = 3;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewmanualreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 3));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewmanualreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewmanualreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewmanualreviewedtimelineList = function() {
  return this.setReviewmanualreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewManualCardReviewedTimeline = 4;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewmanualcardreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 4));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewmanualcardreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewmanualcardreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewmanualcardreviewedtimelineList = function() {
  return this.setReviewmanualcardreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO review_manual_quality_timeline = 5;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewManualQualityTimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 5));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewManualQualityTimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewManualQualityTimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewManualQualityTimelineList = function() {
  return this.setReviewManualQualityTimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO review_sm2_quality_timeline = 6;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewSm2QualityTimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 6));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewSm2QualityTimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewSm2QualityTimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewSm2QualityTimelineList = function() {
  return this.setReviewSm2QualityTimelineList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.GeneralStatDTO.repeatedFields_ = [10,11,12,13,14,15,16,17,18,19,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GeneralStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GeneralStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GeneralStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GeneralStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    numTopics: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numCards: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numCardLangs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numTopicRelationships: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numResources: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numSm2Reviews: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numSm2ReviewCards: jspb.Message.getFieldWithDefault(msg, 7, 0),
    numManualReviews: jspb.Message.getFieldWithDefault(msg, 8, 0),
    numManualReviewCards: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdTopicsList: jspb.Message.toObjectList(msg.getCreatedTopicsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdCardsList: jspb.Message.toObjectList(msg.getCreatedCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdCardLangsList: jspb.Message.toObjectList(msg.getCreatedCardLangsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdTopicRelationshipsList: jspb.Message.toObjectList(msg.getCreatedTopicRelationshipsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdResourcesList: jspb.Message.toObjectList(msg.getCreatedResourcesList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdSm2ReviewsList: jspb.Message.toObjectList(msg.getCreatedSm2ReviewsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdSm2ReviewCardsList: jspb.Message.toObjectList(msg.getCreatedSm2ReviewCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdManualReviewsList: jspb.Message.toObjectList(msg.getCreatedManualReviewsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdManualReviewCardsList: jspb.Message.toObjectList(msg.getCreatedManualReviewCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewedCardsList: jspb.Message.toObjectList(msg.getReviewedCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewedTopicsList: jspb.Message.toObjectList(msg.getReviewedTopicsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GeneralStatDTO}
 */
proto.stats.GeneralStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GeneralStatDTO;
  return proto.stats.GeneralStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GeneralStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GeneralStatDTO}
 */
proto.stats.GeneralStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumTopics(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumCards(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumCardLangs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumTopicRelationships(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumResources(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSm2Reviews(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSm2ReviewCards(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumManualReviews(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumManualReviewCards(value);
      break;
    case 10:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedTopics(value);
      break;
    case 11:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedCards(value);
      break;
    case 12:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedCardLangs(value);
      break;
    case 13:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedTopicRelationships(value);
      break;
    case 14:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedResources(value);
      break;
    case 15:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedSm2Reviews(value);
      break;
    case 16:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedSm2ReviewCards(value);
      break;
    case 17:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedManualReviews(value);
      break;
    case 18:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedManualReviewCards(value);
      break;
    case 19:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewedCards(value);
      break;
    case 20:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewedTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GeneralStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GeneralStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GeneralStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GeneralStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumTopics();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumCards();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumCardLangs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumTopicRelationships();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumResources();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNumSm2Reviews();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNumSm2ReviewCards();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNumManualReviews();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNumManualReviewCards();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreatedTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedCardLangsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTopicRelationshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedSm2ReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedSm2ReviewCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedManualReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedManualReviewCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewedTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 num_topics = 1;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumTopics = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumTopics = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 num_cards = 2;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumCards = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 num_card_langs = 3;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumCardLangs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumCardLangs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 num_topic_relationships = 4;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumTopicRelationships = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumTopicRelationships = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_resources = 5;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumResources = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumResources = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 num_SM2_reviews = 6;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumSm2Reviews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumSm2Reviews = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 num_SM2_review_cards = 7;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumSm2ReviewCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumSm2ReviewCards = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 num_manual_reviews = 8;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumManualReviews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumManualReviews = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 num_manual_review_cards = 9;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumManualReviewCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumManualReviewCards = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated utils.TimelineIntDTO created_topics = 10;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedTopicsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 10));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedTopicsList = function() {
  return this.setCreatedTopicsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_cards = 11;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 11));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedCardsList = function() {
  return this.setCreatedCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_card_langs = 12;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedCardLangsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 12));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedCardLangsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedCardLangs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedCardLangsList = function() {
  return this.setCreatedCardLangsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_topic_relationships = 13;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedTopicRelationshipsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 13));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedTopicRelationshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedTopicRelationships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedTopicRelationshipsList = function() {
  return this.setCreatedTopicRelationshipsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_resources = 14;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedResourcesList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 14));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedResourcesList = function() {
  return this.setCreatedResourcesList([]);
};


/**
 * repeated utils.TimelineIntDTO created_sm2_reviews = 15;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedSm2ReviewsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 15));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedSm2ReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedSm2Reviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedSm2ReviewsList = function() {
  return this.setCreatedSm2ReviewsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_sm2_review_cards = 16;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedSm2ReviewCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 16));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedSm2ReviewCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedSm2ReviewCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedSm2ReviewCardsList = function() {
  return this.setCreatedSm2ReviewCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_manual_reviews = 17;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedManualReviewsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 17));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedManualReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedManualReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedManualReviewsList = function() {
  return this.setCreatedManualReviewsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_manual_review_cards = 18;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedManualReviewCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 18));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedManualReviewCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedManualReviewCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedManualReviewCardsList = function() {
  return this.setCreatedManualReviewCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewed_cards = 19;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getReviewedCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 19));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setReviewedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addReviewedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearReviewedCardsList = function() {
  return this.setReviewedCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewed_topics = 20;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getReviewedTopicsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 20));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setReviewedTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addReviewedTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearReviewedTopicsList = function() {
  return this.setReviewedTopicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewStatCompositeDTO.repeatedFields_ = [4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewStatCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewStatCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewStatCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewStatCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mostreviewedcardsList: jspb.Message.toObjectList(msg.getMostreviewedcardsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    strongestcardsList: jspb.Message.toObjectList(msg.getStrongestcardsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    weakestcardsList: jspb.Message.toObjectList(msg.getWeakestcardsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    mostreviewedtopicsList: jspb.Message.toObjectList(msg.getMostreviewedtopicsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    strongesttopicsList: jspb.Message.toObjectList(msg.getStrongesttopicsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    weakesttopicsList: jspb.Message.toObjectList(msg.getWeakesttopicsList(),
    review_pb.ReviewCardDTO.toObject, includeInstance),
    alltopicsList: jspb.Message.toObjectList(msg.getAlltopicsList(),
    topic_pb.TopicDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewStatCompositeDTO}
 */
proto.stats.ReviewStatCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewStatCompositeDTO;
  return proto.stats.ReviewStatCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewStatCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewStatCompositeDTO}
 */
proto.stats.ReviewStatCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrd(value);
      break;
    case 4:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addMostreviewedcards(value);
      break;
    case 5:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addStrongestcards(value);
      break;
    case 6:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addWeakestcards(value);
      break;
    case 7:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addMostreviewedtopics(value);
      break;
    case 8:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addStrongesttopics(value);
      break;
    case 9:
      var value = new review_pb.ReviewCardDTO;
      reader.readMessage(value,review_pb.ReviewCardDTO.deserializeBinaryFromReader);
      msg.addWeakesttopics(value);
      break;
    case 10:
      var value = new topic_pb.TopicDTO;
      reader.readMessage(value,topic_pb.TopicDTO.deserializeBinaryFromReader);
      msg.addAlltopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewStatCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewStatCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewStatCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewStatCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMostreviewedcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getMostreviewedtopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongesttopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakesttopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      review_pb.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getAlltopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      topic_pb.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatCompositeDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatCompositeDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 ord = 3;
 * @return {number}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated review.ReviewCardDTO mostReviewedCards = 4;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getMostreviewedcardsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 4));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setMostreviewedcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addMostreviewedcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearMostreviewedcardsList = function() {
  return this.setMostreviewedcardsList([]);
};


/**
 * repeated review.ReviewCardDTO strongestCards = 5;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getStrongestcardsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 5));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setStrongestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addStrongestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearStrongestcardsList = function() {
  return this.setStrongestcardsList([]);
};


/**
 * repeated review.ReviewCardDTO weakestCards = 6;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getWeakestcardsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 6));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setWeakestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addWeakestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearWeakestcardsList = function() {
  return this.setWeakestcardsList([]);
};


/**
 * repeated review.ReviewCardDTO mostReviewedTopics = 7;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getMostreviewedtopicsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 7));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setMostreviewedtopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addMostreviewedtopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearMostreviewedtopicsList = function() {
  return this.setMostreviewedtopicsList([]);
};


/**
 * repeated review.ReviewCardDTO strongestTopics = 8;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getStrongesttopicsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 8));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setStrongesttopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addStrongesttopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearStrongesttopicsList = function() {
  return this.setStrongesttopicsList([]);
};


/**
 * repeated review.ReviewCardDTO weakestTopics = 9;
 * @return {!Array<!proto.review.ReviewCardDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getWeakesttopicsList = function() {
  return /** @type{!Array<!proto.review.ReviewCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, review_pb.ReviewCardDTO, 9));
};


/**
 * @param {!Array<!proto.review.ReviewCardDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setWeakesttopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.review.ReviewCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.review.ReviewCardDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addWeakesttopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.review.ReviewCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearWeakesttopicsList = function() {
  return this.setWeakesttopicsList([]);
};


/**
 * repeated topic.TopicDTO allTopics = 10;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.stats.ReviewStatCompositeDTO.prototype.getAlltopicsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, topic_pb.TopicDTO, 10));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
*/
proto.stats.ReviewStatCompositeDTO.prototype.setAlltopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.stats.ReviewStatCompositeDTO.prototype.addAlltopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatCompositeDTO} returns this
 */
proto.stats.ReviewStatCompositeDTO.prototype.clearAlltopicsList = function() {
  return this.setAlltopicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewStatDTO.repeatedFields_ = [4,5,14,15,19,20,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    strongestcardsList: jspb.Message.toObjectList(msg.getStrongestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    weakestcardsList: jspb.Message.toObjectList(msg.getWeakestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    avgcompletedquality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    avgcompletedeasiness: jspb.Message.getFieldWithDefault(msg, 7, 0),
    avgcompletedinterval: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avgcompletedrepetition: jspb.Message.getFieldWithDefault(msg, 9, 0),
    avgquality: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgeasiness: jspb.Message.getFieldWithDefault(msg, 11, 0),
    avginterval: jspb.Message.getFieldWithDefault(msg, 12, 0),
    avgrepetition: jspb.Message.getFieldWithDefault(msg, 13, 0),
    mostrepeatedcardsList: jspb.Message.toObjectList(msg.getMostrepeatedcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    reviewcardstatsList: jspb.Message.toObjectList(msg.getReviewcardstatsList(),
    proto.stats.ReviewCardStatDTO.toObject, includeInstance),
    startat: (f = msg.getStartat()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    mostReviewedTopicsList: jspb.Message.toObjectList(msg.getMostReviewedTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    strongestTopicsList: jspb.Message.toObjectList(msg.getStrongestTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    weakestTopicsList: jspb.Message.toObjectList(msg.getWeakestTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    allTopicsList: jspb.Message.toObjectList(msg.getAllTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    totalCardCount: jspb.Message.getFieldWithDefault(msg, 23, 0),
    totalTopicCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    composite: (f = msg.getComposite()) && proto.stats.ReviewStatCompositeDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewStatDTO}
 */
proto.stats.ReviewStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewStatDTO;
  return proto.stats.ReviewStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewStatDTO}
 */
proto.stats.ReviewStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addStrongestcards(value);
      break;
    case 5:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addWeakestcards(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedquality(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedeasiness(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedinterval(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedrepetition(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgeasiness(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvginterval(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrepetition(value);
      break;
    case 14:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addMostrepeatedcards(value);
      break;
    case 15:
      var value = new proto.stats.ReviewCardStatDTO;
      reader.readMessage(value,proto.stats.ReviewCardStatDTO.deserializeBinaryFromReader);
      msg.addReviewcardstats(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 17:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    case 18:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 19:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addMostReviewedTopics(value);
      break;
    case 20:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addStrongestTopics(value);
      break;
    case 21:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addWeakestTopics(value);
      break;
    case 22:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addAllTopics(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCardCount(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTopicCount(value);
      break;
    case 25:
      var value = new proto.stats.ReviewStatCompositeDTO;
      reader.readMessage(value,proto.stats.ReviewStatCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getMostrepeatedcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewcardstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.stats.ReviewCardStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getMostReviewedTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getAllTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalCardCount();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getTotalTopicCount();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.stats.ReviewStatCompositeDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO userId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated utils.UUID_DTO strongestCards = 4;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getStrongestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setStrongestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addStrongestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearStrongestcardsList = function() {
  return this.setStrongestcardsList([]);
};


/**
 * repeated utils.UUID_DTO weakestCards = 5;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getWeakestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 5));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setWeakestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addWeakestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearWeakestcardsList = function() {
  return this.setWeakestcardsList([]);
};


/**
 * optional int32 avgCompletedQuality = 6;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgcompletedquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgcompletedquality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgcompletedquality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgcompletedquality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 avgCompletedEasiness = 7;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgcompletedeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgcompletedeasiness = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgcompletedeasiness = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgcompletedeasiness = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgCompletedInterval = 8;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgcompletedinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgcompletedinterval = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgcompletedinterval = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgcompletedinterval = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 avgCompletedRepetition = 9;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgcompletedrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgcompletedrepetition = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgcompletedrepetition = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgcompletedrepetition = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 avgQuality = 10;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 avgEasiness = 11;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgeasiness = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgeasiness = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgeasiness = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 avgInterval = 12;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvginterval = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvginterval = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvginterval = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 avgRepetition = 13;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getAvgrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setAvgrepetition = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAvgrepetition = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasAvgrepetition = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated utils.UUID_DTO mostRepeatedCards = 14;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getMostrepeatedcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 14));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setMostrepeatedcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addMostrepeatedcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearMostrepeatedcardsList = function() {
  return this.setMostrepeatedcardsList([]);
};


/**
 * repeated ReviewCardStatDTO reviewCardStats = 15;
 * @return {!Array<!proto.stats.ReviewCardStatDTO>}
 */
proto.stats.ReviewStatDTO.prototype.getReviewcardstatsList = function() {
  return /** @type{!Array<!proto.stats.ReviewCardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewCardStatDTO, 15));
};


/**
 * @param {!Array<!proto.stats.ReviewCardStatDTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setReviewcardstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.stats.ReviewCardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewCardStatDTO}
 */
proto.stats.ReviewStatDTO.prototype.addReviewcardstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.stats.ReviewCardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearReviewcardstatsList = function() {
  return this.setReviewcardstatsList([]);
};


/**
 * optional utils.TimestampDTO startAt = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewStatDTO.prototype.getStartat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setStartat = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearStartat = function() {
  return this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional utils.TimestampDTO endAt = 17;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewStatDTO.prototype.getEndat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 17));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setEndat = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearEndat = function() {
  return this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 18;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewStatDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 18));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated utils.UUID_DTO most_reviewed_topics = 19;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getMostReviewedTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 19));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setMostReviewedTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addMostReviewedTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearMostReviewedTopicsList = function() {
  return this.setMostReviewedTopicsList([]);
};


/**
 * repeated utils.UUID_DTO strongest_topics = 20;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getStrongestTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 20));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setStrongestTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addStrongestTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearStrongestTopicsList = function() {
  return this.setStrongestTopicsList([]);
};


/**
 * repeated utils.UUID_DTO weakest_topics = 21;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getWeakestTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 21));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setWeakestTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addWeakestTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearWeakestTopicsList = function() {
  return this.setWeakestTopicsList([]);
};


/**
 * repeated utils.UUID_DTO all_topics = 22;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewStatDTO.prototype.getAllTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 22));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setAllTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewStatDTO.prototype.addAllTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearAllTopicsList = function() {
  return this.setAllTopicsList([]);
};


/**
 * optional int32 total_card_count = 23;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getTotalCardCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setTotalCardCount = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 total_topic_count = 24;
 * @return {number}
 */
proto.stats.ReviewStatDTO.prototype.getTotalTopicCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.setTotalTopicCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional ReviewStatCompositeDTO composite = 25;
 * @return {?proto.stats.ReviewStatCompositeDTO}
 */
proto.stats.ReviewStatDTO.prototype.getComposite = function() {
  return /** @type{?proto.stats.ReviewStatCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.ReviewStatCompositeDTO, 25));
};


/**
 * @param {?proto.stats.ReviewStatCompositeDTO|undefined} value
 * @return {!proto.stats.ReviewStatDTO} returns this
*/
proto.stats.ReviewStatDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewStatDTO} returns this
 */
proto.stats.ReviewStatDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewStatDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewCardStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewCardStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewCardStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewCardStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    easiness: jspb.Message.getFieldWithDefault(msg, 5, 0),
    quality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    repetitions: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startat: (f = msg.getStartat()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewCardStatDTO}
 */
proto.stats.ReviewCardStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewCardStatDTO;
  return proto.stats.ReviewCardStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewCardStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewCardStatDTO}
 */
proto.stats.ReviewCardStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEasiness(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitions(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewCardStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewCardStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewCardStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewCardStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO cardId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO topicId = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 easiness = 5;
 * @return {number}
 */
proto.stats.ReviewCardStatDTO.prototype.getEasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.setEasiness = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearEasiness = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasEasiness = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 quality = 6;
 * @return {number}
 */
proto.stats.ReviewCardStatDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 interval = 7;
 * @return {number}
 */
proto.stats.ReviewCardStatDTO.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.setInterval = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearInterval = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 repetitions = 8;
 * @return {number}
 */
proto.stats.ReviewCardStatDTO.prototype.getRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.setRepetitions = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearRepetitions = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasRepetitions = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO startAt = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getStartat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setStartat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearStartat = function() {
  return this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO endAt = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewCardStatDTO.prototype.getEndat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewCardStatDTO} returns this
*/
proto.stats.ReviewCardStatDTO.prototype.setEndat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewCardStatDTO} returns this
 */
proto.stats.ReviewCardStatDTO.prototype.clearEndat = function() {
  return this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewCardStatDTO.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.CardStatDTO.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.CardStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.CardStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.CardStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.CardStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    sm2reviewavgeasiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sm2reviewavgquality: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sm2reviewavginterval: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sm2reviewavgrepetitions: jspb.Message.getFieldWithDefault(msg, 5, 0),
    manualreviewavgquality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    manualreviewavginterval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    manualreviewavgrepetitions: jspb.Message.getFieldWithDefault(msg, 8, 0),
    earliestreview: (f = msg.getEarliestreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    recentreview: (f = msg.getRecentreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.stats.ReviewCardStatDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.CardStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.CardStatDTO;
  return proto.stats.CardStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.CardStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.CardStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgeasiness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgquality(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavginterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgrepetitions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavgquality(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavginterval(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavgrepetitions(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEarliestreview(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setRecentreview(value);
      break;
    case 11:
      var value = new proto.stats.ReviewCardStatDTO;
      reader.readMessage(value,proto.stats.ReviewCardStatDTO.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.CardStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.CardStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.CardStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.CardStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEarliestreview();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getRecentreview();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.stats.ReviewCardStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.CardStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 sm2ReviewAvgEasiness = 2;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgeasiness = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgeasiness = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgeasiness = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 sm2ReviewAvgQuality = 3;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgquality = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgquality = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgquality = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 sm2ReviewAvgInterval = 4;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavginterval = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavginterval = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavginterval = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 sm2ReviewAvgRepetitions = 5;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgrepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgrepetitions = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgrepetitions = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgrepetitions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 manualReviewAvgQuality = 6;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavgquality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavgquality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavgquality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 manualReviewAvgInterval = 7;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavginterval = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavginterval = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavginterval = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 manualReviewAvgRepetitions = 8;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavgrepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavgrepetitions = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavgrepetitions = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavgrepetitions = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO earliestReview = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.CardStatDTO.prototype.getEarliestreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setEarliestreview = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearEarliestreview = function() {
  return this.setEarliestreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasEarliestreview = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO recentReview = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.CardStatDTO.prototype.getRecentreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setRecentreview = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearRecentreview = function() {
  return this.setRecentreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasRecentreview = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated ReviewCardStatDTO stats = 11;
 * @return {!Array<!proto.stats.ReviewCardStatDTO>}
 */
proto.stats.CardStatDTO.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.stats.ReviewCardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewCardStatDTO, 11));
};


/**
 * @param {!Array<!proto.stats.ReviewCardStatDTO>} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.stats.ReviewCardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewCardStatDTO}
 */
proto.stats.CardStatDTO.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.stats.ReviewCardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.TopicStatDTO.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.TopicStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.TopicStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.TopicStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.TopicStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    numrelationships: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mostreviewedcard: (f = msg.getMostreviewedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    leastreviewedcard: (f = msg.getLeastreviewedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    weakestcard: (f = msg.getWeakestcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    strongestcard: (f = msg.getStrongestcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    numresources: jspb.Message.getFieldWithDefault(msg, 7, 0),
    avgquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avginterval: jspb.Message.getFieldWithDefault(msg, 9, 0),
    avgrepetition: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgeasiness: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nextcardreview: (f = msg.getNextcardreview()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    nextreview: (f = msg.getNextreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    recentreview: (f = msg.getRecentreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    earliestreview: (f = msg.getEarliestreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    cardstatsList: jspb.Message.toObjectList(msg.getCardstatsList(),
    proto.stats.CardStatDTO.toObject, includeInstance),
    totalreviewcount: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.TopicStatDTO}
 */
proto.stats.TopicStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.TopicStatDTO;
  return proto.stats.TopicStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.TopicStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.TopicStatDTO}
 */
proto.stats.TopicStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumrelationships(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setMostreviewedcard(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setLeastreviewedcard(value);
      break;
    case 5:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setWeakestcard(value);
      break;
    case 6:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setStrongestcard(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumresources(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvginterval(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrepetition(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgeasiness(value);
      break;
    case 12:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setNextcardreview(value);
      break;
    case 13:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setNextreview(value);
      break;
    case 14:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setRecentreview(value);
      break;
    case 15:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEarliestreview(value);
      break;
    case 16:
      var value = new proto.stats.CardStatDTO;
      reader.readMessage(value,proto.stats.CardStatDTO.deserializeBinaryFromReader);
      msg.addCardstats(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalreviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.TopicStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.TopicStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.TopicStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.TopicStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMostreviewedcard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLeastreviewedcard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcard();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNextcardreview();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getNextreview();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getRecentreview();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEarliestreview();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.stats.CardStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 numRelationships = 2;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getNumrelationships = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setNumrelationships = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNumrelationships = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNumrelationships = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO mostReviewedCard = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getMostreviewedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setMostreviewedcard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearMostreviewedcard = function() {
  return this.setMostreviewedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasMostreviewedcard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO leastReviewedCard = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getLeastreviewedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setLeastreviewedcard = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearLeastreviewedcard = function() {
  return this.setLeastreviewedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasLeastreviewedcard = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional utils.UUID_DTO weakestCard = 5;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getWeakestcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 5));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setWeakestcard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearWeakestcard = function() {
  return this.setWeakestcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasWeakestcard = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.UUID_DTO strongestCard = 6;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getStrongestcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 6));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setStrongestcard = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearStrongestcard = function() {
  return this.setStrongestcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasStrongestcard = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 numResources = 7;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getNumresources = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setNumresources = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNumresources = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNumresources = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgQuality = 8;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 avgInterval = 9;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvginterval = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvginterval = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvginterval = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 avgRepetition = 10;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgrepetition = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgrepetition = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgrepetition = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 avgEasiness = 11;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgeasiness = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgeasiness = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgeasiness = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.UUID_DTO nextCardReview = 12;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getNextcardreview = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 12));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setNextcardreview = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNextcardreview = function() {
  return this.setNextcardreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNextcardreview = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional utils.TimestampDTO nextReview = 13;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getNextreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 13));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setNextreview = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNextreview = function() {
  return this.setNextreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNextreview = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional utils.TimestampDTO recentReview = 14;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getRecentreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 14));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setRecentreview = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearRecentreview = function() {
  return this.setRecentreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasRecentreview = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional utils.TimestampDTO earliestReview = 15;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getEarliestreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 15));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setEarliestreview = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearEarliestreview = function() {
  return this.setEarliestreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasEarliestreview = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated CardStatDTO cardStats = 16;
 * @return {!Array<!proto.stats.CardStatDTO>}
 */
proto.stats.TopicStatDTO.prototype.getCardstatsList = function() {
  return /** @type{!Array<!proto.stats.CardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.CardStatDTO, 16));
};


/**
 * @param {!Array<!proto.stats.CardStatDTO>} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setCardstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.stats.CardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.TopicStatDTO.prototype.addCardstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.stats.CardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearCardstatsList = function() {
  return this.setCardstatsList([]);
};


/**
 * optional int32 totalReviewCount = 17;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getTotalreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setTotalreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


goog.object.extend(exports, proto.stats);
