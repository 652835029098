import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {InternalErrorTypes, IUIError, NewUIError} from "../service/cartaError";
import {UUID_DTO} from "../proto/utils_pb";
import {convertDateToTimestamp, convertTimestampToDate, ListItem} from "../utils/utils";
import {LanguageDTO} from "../proto/language_pb";

export class Language extends BaseModel<Language, LanguageDTO> {
	iso_name: string;
	iso_code: string;
	local_name: string;
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.iso_name,
			metadata1: this.iso_code,
		}
	}
	
	constructor() {
		super();
		this.iso_name = ""
		this.iso_code = ""
		this.local_name = ""
	}
	
	TYPE: EntityKind = EntityKind.Language
	
	fromDTO(dto: LanguageDTO): void | IUIError {
		let origin = "fromDTO";
		let errorKind = InternalErrorTypes.InvalidLanguageDTO;
		let id = "";
		let userId = "";
		
		if (dto.getId()) {
			if (dto.getId()!.getValue()) {
				id = dto.getId()!.getValue();
			} else {
				return NewUIError(
					origin,
					errorKind,
					`languageID is empty '' - language: ${dto}"`
				);
			}
		} else {
			return NewUIError(
				origin,
				errorKind,
				`languageID is undefined '' - language: ${dto}"`
			);
		}
		
		if (!dto.getIsoName()) {
			return NewUIError(
				origin,
				errorKind,
				`IsoName string is empty '' - language: ${dto}"`
			);
		}
		
		if (!dto.getIsoCode()) {
			return NewUIError(
				origin,
				errorKind,
				`IsoCode string is empty '' - language: ${dto}"`
			);
		}
		
		if (!dto.getLocalName()) {
			return NewUIError(
				origin,
				errorKind,
				`LocalName string is empty '' - language: ${dto}"`
			);
		}
		
		if (!dto.getCreatedon()) {
			return NewUIError(
				origin,
				errorKind,
				`language created date is empty '' - language: ${dto}"`
			);
		}
		
		if (!dto.getUpdatedon()) {
			return NewUIError(
				origin,
				errorKind,
				`language updated date is empty '' - language: ${dto}"`
			);
		}
		
		const createdOn = convertTimestampToDate(dto.getCreatedon()!);
		const updatedOn = convertTimestampToDate(dto.getUpdatedon()!);

		
		this.id = id;
		this.userId = userId;
		this.iso_name = dto.getIsoName()
		this.iso_code = dto.getIsoCode()
		this.local_name = dto.getLocalName()
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		return;
	}
	
	intoDTO(): IUIError | LanguageDTO {
		let dto = new LanguageDTO();
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		dto.setIsoCode(this.iso_code);
		dto.setIsoName(this.iso_name);
		dto.setLocalName(this.local_name)
		
		return dto
	}
	
	clone(): Language {
		let temp = Object.assign({}, this);
		let newLang = new Language();
		
		newLang.id = temp.id;
		newLang.userId = temp.userId;
		newLang.createdOn = temp.createdOn;
		newLang.updatedOn = temp.updatedOn;
		newLang.iso_name = temp.iso_name;
		newLang.iso_code = temp.iso_code;
		newLang.local_name = temp.local_name;
		
		return newLang
	}
	
	sanitize(): Language {
		return this
	}
	
	customValidate(): IUIError | Language {
		return this.sanitize()
	}
	
	init(): Language {
		return new Language();
	}
	
	to1LineString(): String {
		return this.iso_name;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			name: this.iso_name,
			title: this.iso_name,
			subtitle: this.local_name,
			description: this.iso_code,
		} as IDisplayItem;
	}
}