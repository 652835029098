import * as React from 'react';
import {useState} from 'react';

// material-ui
import {Box, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';

// assets
import {EditOutlined, LogoutOutlined} from '@ant-design/icons';
import {Link as RouterLink} from "react-router-dom";
import {GITHUB_COMMIT_SHA} from "../../../../../consts";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
	handleLogout: () => void;
}

const ProfileTab = ({handleLogout}: Props) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
		setSelectedIndex(index);
	};
	
	return (
		<List component="nav" sx={{p: 0, '& .MuiListItemIcon-root': {minWidth: 32}}}>
			<Link className="header-link" color="primary" component={RouterLink} to="/profiles/account/profile"
				  underline="none">
				<ListItemButton selected={selectedIndex === 0}
								onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
					<ListItemIcon>
						<EditOutlined/>
					</ListItemIcon>
					<ListItemText primary="User Account & Profile"/>
				</ListItemButton>
			</Link>
			
			{/*<ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>*/}
			{/*  <ListItemIcon>*/}
			{/*    <UserOutlined />*/}
			{/*  </ListItemIcon>*/}
			{/*  <ListItemText primary="View Profile" />*/}
			{/*</ListItemButton>*/}
			
			{/*<ListItemButton selected={selectedIndex === 3} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>*/}
			{/*  <ListItemIcon>*/}
			{/*    <ProfileOutlined />*/}
			{/*  </ListItemIcon>*/}
			{/*  <ListItemText primary="Social Profile" />*/}
			{/*</ListItemButton>*/}
			{/*<ListItemButton selected={selectedIndex === 4} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>*/}
			{/*  <ListItemIcon>*/}
			{/*    <WalletOutlined />*/}
			{/*  </ListItemIcon>*/}
			{/*  <ListItemText primary="Billing" />*/}
			{/*</ListItemButton>*/}
			<ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
				<ListItemIcon>
					<LogoutOutlined/>
				</ListItemIcon>
				<ListItemText primary="Logout"/>
			</ListItemButton>
			<Box display={"flex"} justifyContent={"center"} width={"100%"}>
				{
					GITHUB_COMMIT_SHA && <Typography variant={'caption'} color={'textSecondary'} align={'center'}>
						Version: {GITHUB_COMMIT_SHA}
					</Typography>
				}
			</Box>
		</List>
	);
};

export default ProfileTab;
