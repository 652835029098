import {BaseModel, EntityKind} from "./BaseModel";
import {CardMediaDTO, CardMediaKindDTO, CardMediaOriginDTO, CardMediaSignedURLDTO} from "proto/cardMedia_pb";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {IDisplayItem} from "./interfaces";
import {UUID_DTO} from "proto/utils_pb";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	convertFromDTOToString,
	ListItem
} from "utils/utils";
import {IFromDTO} from "./model";

/**
 * ImageMeta is a data structure that represents an image file uploaded by the user via the UI
 */
export interface ImageMeta {
	url: string;
	name: string;
	size: number; // Size in bytes
	hash: string; // Hash of the image file
	extension: string; // File extension
	file: File; // The image file
}

export class CardMedia extends BaseModel<CardMedia, CardMediaDTO> {
	position: number;
	cardId: string;
	kind: CardMediaKindDTO
	origin: CardMediaOriginDTO
	hash: string;
	caption: string;
	size: number;
	url: string;
	file: File;
	extension: string;
	mime: string;
	
	constructor() {
		super();
		this.position = 0;
		this.cardId = "";
		this.kind = CardMediaKindDTO.KIND_IMAGE;
		this.origin = CardMediaOriginDTO.ORIGIN_UPLOAD;
		this.hash = "";
		this.caption = "";
		this.size = 0;
		this.url = "";
		this.file = new File([], "");
		this.extension = "";
		this.mime = "";
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.caption,
			content: this.url,
			createdOn: this.createdOn,
			updatedOn: this.updatedOn,
		} as ListItem
	}
	
	to1LineString(): String {
		throw new Error("Method not implemented.");
	}
	
	init(): CardMedia {
		throw new Error("Method not implemented.");
	}
	
	TYPE: EntityKind = EntityKind.CardMedia;
	
	fromDTO(dto: CardMediaDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
		this.cardId = convertFromDTOToID('cardId', this.TYPE, dto.getCardId());
		this.hash = convertFromDTOToString('hash', this.TYPE, dto.getHash());
		this.caption = convertFromDTOToString('caption', this.TYPE, dto.getCaption());
		this.position = dto.getPosition();
		this.size = dto.getSize();
		this.url = convertFromDTOToString('url', this.TYPE, dto.getUrl(), true);
		this.kind = dto.getKind();
		this.origin = dto.getOrigin();
		this.extension = convertFromDTOToString('extension', this.TYPE, dto.getFileext());
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedOn(), false)!;
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedOn())!;
	}
	
	intoDTO(): IUIError | CardMediaDTO {
		let dto = new CardMediaDTO();
		
		dto.setCardId(new UUID_DTO().setValue(this.cardId));
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setUserId(new UUID_DTO().setValue(this.userId));
		dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedOn(convertDateToTimestamp(this.updatedOn));
		dto.setHash(this.hash);
		dto.setCaption(this.caption);
		dto.setPosition(this.position);
		dto.setUrl(this.url)
		dto.setKind(this.kind);
		dto.setSize(this.size);
		dto.setOrigin(this.origin);
		dto.setFileext(this.extension);
		
		return dto
	}
	
	clone(): CardMedia {
		const cloned = new CardMedia();
		
		cloned.id = this.id;
		cloned.userId = this.userId;
		cloned.position = this.position;
		cloned.cardId = this.cardId;
		cloned.kind = this.kind;
		cloned.origin = this.origin;
		cloned.hash = this.hash;
		cloned.caption = this.caption;
		cloned.size = this.size;
		cloned.url = this.url;
		cloned.file = new File([this.file], this.file.name, {
			type: this.file.type,
			lastModified: this.file.lastModified,
		});
		cloned.extension = this.extension;
		return cloned;
	}
	
	sanitize(): CardMedia {
		this.caption = this.caption.trim();
		
		return this
	}
	
	customValidate(): IUIError | CardMedia {
		if (this.hash === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "hash is empty")
		}
		if (this.size === 0) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "size is 0")
		}
		if (this.kind !== CardMediaKindDTO.KIND_IMAGE && this.kind !== CardMediaKindDTO.KIND_VIDEO) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `unsupported media kind: ${this.kind} `)
		}
		if (this.origin !== CardMediaOriginDTO.ORIGIN_UPLOAD) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `unsupported media origin: ${this.origin}`)
		}
		if (this.cardId === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "cardId is empty")
		}
		
		return this.sanitize()
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.caption,
			metadata1: this.url,
		}
	}
}

export class CardMediaSignedURL implements IFromDTO<CardMediaSignedURLDTO> {
	mediaId: string;
	cardId: string;
	signedUrl: string;
	
	constructor() {
		this.mediaId = "";
		this.cardId = "";
		this.signedUrl = "";
	}
	
	fromDTO(dto: CardMediaSignedURLDTO): void | IUIError {
		const id = convertFromDTOToID('id', EntityKind.CardMediaSignedUrl, dto.getMediaId());
		const cardId = convertFromDTOToID('cardId', EntityKind.CardMediaSignedUrl, dto.getCardId());
		const signedUrl = dto.getSignedUrl();
		
		this.mediaId = id;
		this.cardId = cardId;
		this.signedUrl = signedUrl;
	}
}