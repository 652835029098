import { BaseService } from "./BaseService";
import {
  DTOCreatorRequestType,
  DTOCreatorResponseType,
  IGRPCService,
  ListResponse,
} from "../model/BaseModel";
import grpcWeb from "grpc-web";
import {FetchLanguageRequest, LanguageDTO, ListLanguageByIdsRequest} from "../proto/language_pb";
import { Language } from "../model/Language";
import { LanguageServicePromiseClient } from "../proto/language_grpc_web_pb";
import {
  ListOptionsRequestDTO,
  ListOptionsResponseDTO,
  UUID_DTO,
} from "../proto/utils_pb";
import { CardDTO } from "../proto/card_pb";
import {ResourceDTO} from "proto/resource_pb";
import {CARTA_PROXY_URL} from "consts";
import {unifiedInterceptor} from "../utils/utils";

const languageClient = new LanguageServicePromiseClient(
  CARTA_PROXY_URL!,
  null,
  { withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor] }
);

export class LanguageGRPCImpl
  implements
    IGRPCService<
      LanguageDTO,
      DTOCreatorRequestType,
      DTOCreatorResponseType<LanguageDTO>
    >
{
  setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
    let req = new ListLanguageByIdsRequest();
    req.setIdsList(ids);
    
    return req;
  }
  async listByIDs(
      req: DTOCreatorRequestType,
      meta?: grpcWeb.Metadata
  ): Promise<ListResponse<LanguageDTO> | undefined> {
    let x = await languageClient.listByIds(req as ListLanguageByIdsRequest, meta);

    return {
      items: x.getItemsList() as LanguageDTO[],
      info: x.getInfo(),
    } as ListResponse<LanguageDTO>;
  }
  create(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<LanguageDTO | undefined> {
    return Promise.reject();
  }

  setupCreateReq(dto: LanguageDTO): DTOCreatorRequestType {
    return {};
  }

  async list(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<ListResponse<LanguageDTO> | undefined> {
    const x = await languageClient.list(
      req as FetchLanguageRequest,
      meta
    );

    return {
      items: x.getItemsList() as LanguageDTO[],
      info: x.getInfo(),
    } as ListResponse<LanguageDTO>;
  }

  setupListReq(opts: ListOptionsRequestDTO): DTOCreatorRequestType {
    return new FetchLanguageRequest();
  }

  delete(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<void> {
    return Promise.resolve(undefined);
  }

  get(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<LanguageDTO | undefined> {
    return Promise.resolve(undefined);
  }

  setupDeleteReq(id: string): DTOCreatorRequestType {
    return {};
  }

  setupGetReq(id: string): DTOCreatorRequestType {
    return {};
  }

  setupUpdateReq(dto: LanguageDTO): DTOCreatorRequestType {
    return {};
  }

  update(
    req: DTOCreatorRequestType,
    meta?: grpcWeb.Metadata
  ): Promise<LanguageDTO | undefined> {
    return Promise.resolve(undefined);
  }
}

export class LanguageService extends BaseService<
  Language,
  LanguageDTO,
  LanguageGRPCImpl
> {
  constructor() {
    super(new LanguageGRPCImpl(), Language);
  }
}
