import {BaseModel, EntityKind} from "./BaseModel";
import {ListItem, NewUUID} from "../utils/utils";
import {IDisplayItem} from "./interfaces";
import {ActionType, InternalErrorTypes, IUIError, NewUIError, NewUIErrorV2} from "../service/cartaError";
import {UUID_DTO} from "../proto/utils_pb";
import {ReviewSM2FilterConfigDTO} from "../proto/reviewSM2_pb";
import {DEFAULT_LIMIT, MAX_LIMIT} from "consts";


export class ReviewSM2Config extends BaseModel<ReviewSM2Config, ReviewSM2FilterConfigDTO> {
    limit: number;
    id: string;
    userId: string;
    newnessFactor: number;
    isTemplate: boolean;
    
    constructor() {
        super();
        this.id = NewUUID();
        this.userId = "";
        this.limit = DEFAULT_LIMIT;
        this.newnessFactor = 0;
        this.isTemplate = false;
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.id,
            metadata1: this.userId,
        }
    }
    
    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }
    
    to1LineString(): String {
        throw new Error("Method not implemented.");
    }
    
    init(): ReviewSM2Config {
        return new ReviewSM2Config()
    }
    
    TYPE: EntityKind = EntityKind.ReviewSM2Config;
    
    sanitize(): ReviewSM2Config {
        return this
    }
    
    customValidate(): ReviewSM2Config | IUIError {
        if (this.limit > MAX_LIMIT) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, InternalErrorTypes.InvalidReviewSM2Config, `Limit is greater than ${MAX_LIMIT}`);
        }
        if (this.newnessFactor > 100) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, InternalErrorTypes.InvalidReviewSM2Config, `Newness Factor is greater than 100`);
        }
        if (this.newnessFactor < 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, InternalErrorTypes.InvalidReviewSM2Config, `Newness Factor is less than 0`);
        }
        if (this.userId === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, InternalErrorTypes.InvalidReviewSM2Config, `UserID is empty`);
        }
        
        return this
    }
    
    static default(): ReviewSM2Config {
        let config = new ReviewSM2Config();
        config.limit = DEFAULT_LIMIT;
        config.newnessFactor = 20;
        config.isTemplate = false;
        
        return config;
    }
    
    clone(): ReviewSM2Config {
        let temp = Object.assign({}, this);
        let newConfig = new ReviewSM2Config();
        
        newConfig.id = temp.id;
        newConfig.userId = temp.userId;
        newConfig.limit = temp.limit;
        newConfig.newnessFactor = temp.newnessFactor;
        newConfig.isTemplate = temp.isTemplate;
        
        return newConfig;
    }
    
    fromDTO(config: ReviewSM2FilterConfigDTO): void | IUIError {
        const origin = "fromDTO";
        const errorKind = InternalErrorTypes.InvalidReviewSM2Config;
        
        if (config.getId()) {
            if (config.getId()!.getValue()) {
                this.id = config.getId()!.getValue();
            } else {
                return NewUIError(origin, errorKind, `ReviewSM2Config is empty "`);
            }
        } else {
            return NewUIError(origin, errorKind, `ReviewSM2Config is undefined "`);
        }
        
        if (config.getUserId()) {
            if (config.getUserId()!.getValue()) {
                this.userId = config.getUserId()!.getValue();
            } else {
                return NewUIError(
                    origin,
                    errorKind,
                    `ReviewSM2Config userID is empty"`
                );
            }
        } else {
            return NewUIError(
                origin,
                errorKind,
                `ReviewSM2Config userID is undefined"`
            );
        }
        
        this.isTemplate = config.getIstemplate();
        this.limit = config.getLimit();
        this.newnessFactor = config.getNewnessfactor();
        
        
    }
    
    intoDTO(): IUIError | ReviewSM2FilterConfigDTO {
        let dto = new ReviewSM2FilterConfigDTO();
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setLimit(this.limit);
        dto.setNewnessfactor(this.newnessFactor);
        dto.setIstemplate(this.isTemplate);
        
        return dto;
    }
}