import BaseStore from "./BaseStore";
import {Language} from "../model/Language";
import {LanguageDTO} from "../proto/language_pb";
import {LanguageGRPCImpl, LanguageService} from "../service/LanguageService";

export class LanguageStore extends BaseStore<Language, LanguageDTO, LanguageGRPCImpl, LanguageService>{
	
	constructor(service: LanguageService) {
		super(service, Language);
	}
	
	// async FetchAll<Language>(opts: ListOptionsRequestDTO): Promise<Result<Language[], IUIError>> {
	// 	return super.FetchAll<Language>(opts);
	// }
	
	get GetList(): Language[] {
		return Array.from(this.map.values());
	}
	
	GetLanguageNameByID(id: string): string | undefined {
		let lang = this.GetById(id)
		if (lang === undefined) {
			return ""
		}
		
		return lang!.iso_name
	}
}

class LanguageStoreImpl extends LanguageStore {
}