import {ActionType, isError, IUIError, NewUIErrorV2, UIErrorV2} from "../service/cartaError";
import {IBaseModel, Identifiable, IFromString} from "./model";
import {Displayable, IDisplayItem, Listable} from "./interfaces";
import {convertTimestampToDate, ListItem, NewUUID} from "../utils/utils";
import grpcWeb from "grpc-web";
import {ListOptionsRequestDTO, ListOptionsResponseDTO, TimestampDTO, UUID_DTO,} from "../proto/utils_pb";
import {Err, Ok, Result} from "../utils/result";

export interface ListResponse<T> {
    items: T[];
    info: ListOptionsResponseDTO;
}

export const EmptyListResponse: ListResponse<any> = {
    items: [],
    info: new ListOptionsResponseDTO()
}

/**
 * IGPRCService is a generic interface for all GRPC services that contains methods that assist in interfacing with grpc_web
 * to setup requests and responses.
 */
export interface IGRPCService<DTO, Req, Resp> {
    // The setupCreateReq method is a generic method that takes in a DTO and returns a request object that can be used in the
    // `create` method.
    setupCreateReq(dto: DTO): Req;
    
    // The create method is a generic method that takes in a request and returns a response
    create(req: Req, meta?: grpcWeb.Metadata): Promise<DTO | undefined>;
    
    setupUpdateReq(dto: DTO): Req;
    
    update(req: Req, meta?: grpcWeb.Metadata): Promise<DTO | undefined>;
    
    setupListReq(opts: ListOptionsRequestDTO): Req;
    
    list(
        req: Req,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<DTO> | undefined>;
    
    setupListByIDsReq(ids: UUID_DTO[]): Req;
    
    listByIDs(req: Req, meta?: grpcWeb.Metadata): Promise<ListResponse<DTO> | undefined>;
    
    setupGetReq(id: string): Req;
    
    get(req: Req, meta?: grpcWeb.Metadata): Promise<DTO | undefined>;
    
    setupDeleteReq(id: string): Req;
    
    delete(req: Req, meta?: grpcWeb.Metadata): Promise<void>;
}

export interface IReviewRespStatDTO<DTO, STAT_DTO> {
    dto: DTO,
    statDTO: STAT_DTO
}

export interface IReviewRespStat<MODEL, STAT_MODEL> {
    model: MODEL,
    statModel: STAT_MODEL
}

export interface IGRPCReviewService<
    MODEL_DTO,
    SUB_MODEL_DTO,
    MODEL_STAT_DTO,
    CONFIG_DTO,
    Req,
    Resp
> extends IGRPCService<MODEL_DTO, Req, Resp> {
    setupListCardsReq(reviewId: string): Req;
    
    // This lists cards belonging to a review. For Manual Reviews, it will return all the cards that were selected as part of the review.
    // for SM2 Reviews, it will return reviewed cards so far.
    listCards(
        req: Req,
        meta?: grpcWeb.Metadata
    ): Promise<SUB_MODEL_DTO[] | undefined>;
    
    // setupSelectCardsByConfigReq(reviewId: string, configId: string): Req
    //
    // selectCardsByConfig(req: Req, meta?: grpcWeb.Metadata): Promise<SUB_MODEL_DTO[] | undefined>
    
    // setupSaveReq(dto: DTO, cardDTO: CDTO): Req
    //
    // save(req: Req, meta?: grpcWeb.Metadata): Promise<DTO | undefined>
    
    setupListCardsByConfigReq(reviewId: string, config: CONFIG_DTO): Req;
    
    // This will fetch cards based on the configuration. For Manual Reviews, it will return all the cards that were selected as part of the review.
    // for SM2 Reviews it will return cards that are due for review i.e. Available Cards.
    // The type `any` here represents DTOs of the ICard type (interface)
    listCardsByConfig(
        req: Req,
        meta?: grpcWeb.Metadata
    ): Promise<SUB_MODEL_DTO[] | undefined>;
    
    setupCompleteReq(dto: MODEL_DTO): Req;
    
    complete(req: Req, meta?: grpcWeb.Metadata): Promise<MODEL_DTO | undefined>;
    
    setupSaveCardReq(reviewId: string, dto: SUB_MODEL_DTO): Req;
    
    saveCard(
        req: Req,
        meta?: grpcWeb.Metadata
    ): Promise<SUB_MODEL_DTO | undefined>;
    
    setupGetStatsReq(id: string): Req;
    
    getStats(
        req: Req,
        meta?: grpcWeb.Metadata
    ): Promise<MODEL_STAT_DTO | undefined>;
}

export type ValidListable = Listable & Identifiable;

/**
 * BaseModel is a generic class that implements the IModel interface and forms the foundation for underlying models
 * that need to communicate with the backend and most importantly be stored in the database. It ties closely with the
 * generated protobuf definitions so allow a seamless interaction between the frontend and backend.
 */
export abstract class BaseModel<Self, DTO>
    implements IBaseModel<Self, DTO>, Displayable, Listable, ValidListable {
    id: string;
    userId: string;
    createdOn: Date;
    updatedOn: Date;
    
    protected constructor() {
        const now = new Date();
        this.id = NewUUID();
        this.userId = "";
        this.createdOn = now;
        this.updatedOn = now;
    }
    
    abstract toDisplayable(): IDisplayItem;
    
    abstract to1LineString(): String;
    
    abstract TYPE: EntityKind;
    
    abstract fromDTO(t: DTO): void | IUIError;
    
    abstract intoDTO(): DTO | IUIError;
    
    abstract clone(): Self;
    
    abstract sanitize(): Self;
    
    // Base level validation for common fields
    private validateBase(): IUIError | null {
        if (!this.id) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ID is missing");
        }
        if (!this.userId) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "UserID is missing");
        }
        
        return null;
    }
    
    abstract customValidate(): IUIError | Self;
    
    validate(): IUIError | Self {
        const baseError = this.validateBase();
        if (baseError && isError(baseError)) {
            return baseError as IUIError;
        }
        
        const customError = this.customValidate();
        if (customError && isError(customError)) {
            return customError as IUIError;
        }
        
        return this.sanitize();
    }
    
    abstract toListItem(): ListItem;
    
    // Static generic fromDTO method
    static fromDTO<U extends BaseModel<U, T>, T>(this: { new(): U }, t: T): Result<U, UIErrorV2> {
        try {
            // Create a new instance of the implementing class
            const instance = new this();
            
            for (const key in t) {
                if (Object.prototype.hasOwnProperty.call(t, key) && key in instance) {
                    const value = (t as any)[key];
                    
                    // Handle Date fields ending with 'On' or 'At' with TimestampDTO conversion
                    if ((key.endsWith('On') || key.endsWith('At')) && value instanceof TimestampDTO) {
                        const dateValue = convertTimestampToDate(value);
                        if (!dateValue) {
                            return Err(
                                NewUIErrorV2(ActionType.ConvertFromDTO, instance.TYPE, `Failed to convert TimestampDTO for key: ${key}`)
                            );
                        }
                        (instance as any)[key] = dateValue;
                    } else {
                        // Set the value directly for non-date fields
                        (instance as any)[key] = value;
                    }
                }
            }
            
            return Ok(instance); // Return a successful result with the instance
        } catch (error) {
            console.error("Error in fromDTO:", error);
            return Err(
                // TODO - Fix the kind
                NewUIErrorV2(ActionType.ConvertFromDTO, EntityKind.StripeProduct, error)
            );
        }
    }
}

export enum EntityKind {
    Auth = "AUTH",
    Card = "CARD",
    Topic = "TOPIC",
    RelTopic = "REL_TOPIC",
    Tag = "TAG",
    Resource = "RESOURCE",
    ResourceMetadata = "RESOURCE_METADATA",
    Review = "REVIEW",
    ReviewSM2 = "SM2_REVIEW",
    ReviewManual = "MANUAL_REVIEW",
    ReviewManualCard = "REVIEW_MANUAL_CARD",
    ReviewManualCardComposite = "REVIEW_MANUAL_CARD_COMPOSITE",
    ReviewSM2Card = "REVIEW_SM2_CARD",
    ReviewSM2CardComposite = "REVIEW_SM2_CARD_COMPOSITE",
    ReviewManualConfig = "REVIEW_MANUAL_CONFIG",
    ReviewSM2Config = "REVIEW_SM2_CONFIG",
    User = "USER",
    UserAttribute = "USER_ATTRIBUTE",
    CardLang = "CARD_LANG",
    Language = "LANGUAGE",
    CardLangComposite = "CARD_LANG_COMPOSITE",
    CardComposite = "CARD_COMPOSITE",
    ReviewCardStats = "REVIEW_CARD_STATS",
    ReviewCard = "REVIEW_CARD",
    ReviewManualStat = "REVIEW_MANUAL_STAT",
    ReviewSM2Stat = "REVIEW_SM2_STAT",
    CardMedia = "CARD_MEDIA",
    CardMediaSignedUrl = "CARD_MEDIA_SIGNED_URL",
    JWTToken = "JWTToken",
    Unknown = "UNKNOWN",
    ResourceComposite = "RESOURCE_COMPOSITE",
    Stats = "STATS",
    Stripe = "STRIPE",
    StripeProduct = "STRIPE_PRODUCT",
    StripePrice = "STRIPE_PRICE",
    StripeEntitlement = "STRIPE_ENTITLEMENT",
    UserSubscription = "USER_SUBSCRIPTION",
    TopicRelation = "TOPIC_RELATION",
    CardStat = "CARD_STAT",
	ReviewStat = "REVIEW_STAT",
    UIComponent = "UI_COMPONENT",
    TopicGraph = "TOPIC_GRAPH",
}


export interface IOwnedModel<M> extends Identifiable {
    userId: string;
}

export interface DTOCreatorRequestType {
}

export interface DTOCreatorResponseType<DTO> {
    extract(): DTO | undefined;
}
