import {action, makeObservable, observable, runInAction} from "mobx";
import {
	ActionType,
	InternalErrorTypes,
	IUIError,
	LogErrorToProvider,
	NewUIError,
	NewUIErrorV2,
} from "service/cartaError";
import {ReviewSM2} from "model/ReviewSM2";
import {ReviewSM2GRPC, ReviewSM2Service} from "service/ReviewSM2Service";
import {ProgressStateEnumDTO} from "proto/utils_pb";
import {ReviewCard} from "model/ReviewCard";
import {
	GetMostRecentReviewRequest,
	GetMostRecentReviewResponse,
	ReviewSM2DTO,
	ReviewSM2FilterConfigDTO,
	SaveReviewSM2Request,
	SaveReviewSM2Response,
} from "proto/reviewSM2_pb";
import {ReviewSM2ServicePromiseClient} from "proto/reviewSM2_grpc_web_pb";
import {ReviewBaseStore} from "./ReviewBaseStore";
import {Err, Ok, Result} from "utils/result";
import {EntityKind} from "model/BaseModel";
import {CARTA_PROXY_URL} from "consts";
import {ReviewCardDTO} from "../proto/review_pb";
import {ReviewStatDTO} from "../proto/stats_pb";
import {ReviewStat} from "../model/ReviewStat";

const reviewClient = new ReviewSM2ServicePromiseClient(CARTA_PROXY_URL!, null, {
	withCredentials: true,
});

export class ReviewSM2Store extends ReviewBaseStore<
	ReviewSM2,
	ReviewSM2DTO,
	ReviewCard,
	ReviewCardDTO,
	ReviewStat,
	ReviewStatDTO,
	ReviewSM2FilterConfigDTO,
	ReviewSM2GRPC,
	ReviewSM2Service
> {
	public service: ReviewSM2Service;
	
	// Due to the dynamic nature of an SM2 review, we need to store the review in a temp variable. Unlike manual reviews
	// where we make use of the map, and cardMap, we can't really do that here as the cards are dynamic and the review may be
	// ephemeral.
	// @observable
	public tempCreatedReview: ReviewSM2 | undefined;
	// Due to the dynamic nature of an SM2 review, we need to store the cards in a temp variable. This variable is used
	// during the review creation process to store the cards that were selected for the review.
	// @observable
	public tempCreatedReviewCards: ReviewCard[] | undefined;
	// @observable
	public tempCreatedConfig: ReviewSM2FilterConfigDTO | undefined;
	
	constructor(service: ReviewSM2Service) {
		super(service, ReviewSM2, ReviewCard, ReviewStat);
		
		makeObservable(this, {
			tempCreatedReview: observable,
			tempCreatedReviewCards: observable,
			tempCreatedConfig: observable,

			CreateReview: action,
			UpdateCardInTempReview: action,
			ResumeSM2Review: action,
			StartReview: action,
			GetMostRecentReview: action,
		});
		
		this.service = service;
	}
	
	// @action
	async CreateReview(
		review: ReviewSM2,
		config: ReviewSM2FilterConfigDTO,
		firstCard: ReviewCard
	): Promise<Result<ReviewSM2, IUIError>> {
		const actionType = ActionType.Create;
		
		try {
			let res = await this.service.CreateReview(review, config, firstCard);
			
			if (!res.ok) {
				return Err(res.error as IUIError);
			}
			
			const mRes = res.value as ReviewSM2;
			
			runInAction(() => {
				this.map.set(mRes.id, mRes);
			});
			
			return Ok(mRes);
		} catch (err) {
			return Err(NewUIErrorV2(actionType, EntityKind.ReviewSM2, err));
		}
	}
	
	// @action
	public GetMostRecentReview = async (): Promise<ReviewSM2 | IUIError> => {
		const errorKind = InternalErrorTypes.GetReviewSM2;
		const origin = "getMostRecentReview";
		
		let req: GetMostRecentReviewRequest = new GetMostRecentReviewRequest();
		
		try {
			let res: GetMostRecentReviewResponse =
				await reviewClient.getMostRecentReview(req);
			
			if (res.getReview()) {
				let review = new ReviewSM2();
				review.fromDTO(res.getReview()!);
				
				runInAction(() => {
					this.map.set(review.id, review);
				});
				
				return review as ReviewSM2;
			} else {
				// TODO: It may be the users first time creating a review, so we shouldnt error
				return NewUIError(origin, errorKind, "returned review is undefined");
			}
		} catch (err) {
			return NewUIError(
				origin,
				errorKind,
				`failed to get previous SM2 review: ${JSON.stringify(err)}`
			);
		}
	};
	
	// @action
	public ResumeSM2Review = async (
		id: string,
		config: ReviewSM2FilterConfigDTO
	): Promise<
		Result<[review: ReviewSM2, reviewCards: ReviewCard[]], IUIError>
	> => {
		const review = await this.GetOneOrFetch(id);
		
		
		if (review.ok) {
			if (review.value === undefined) {
				return Err(
					NewUIError(
						"ResumeReview",
						InternalErrorTypes.GetReview,
						`failed to fetch review: ${id} - undefined`
					)
				);
			}
			
			try {
				const res = await this.service.ResumeReview(review.value, config);
				
				if (res.ok) {
					const [review, cards] = res.value;
					
					runInAction(() => {
						this.map.set(review.id, review);
						this.reviewCardMap.set(review.id, cards);
						
						this.tempCreatedReview = review;
						this.tempCreatedReviewCards = cards;
					});
					
					return Ok([review, cards]);
				} else {
					return Err(
						NewUIError(
							"ResumeReview",
							InternalErrorTypes.GetReview,
							`failed to fetch review + cards to resume: ${id} - undefined`
						)
					);
				}
			} catch (err) {
				return Err(
					NewUIError(
						"ResumeReview",
						InternalErrorTypes.GetReview,
						`failed to fetch review: ${id} - undefined`,
						undefined, undefined,
						err
					)
				);
			}
		} else {
			return Err(review.error as IUIError);
		}
	};
	
	public UpdateCardInTempReview = (card: ReviewCard): void => {
		if (this.tempCreatedReview === undefined) {
			LogErrorToProvider(NewUIErrorV2(ActionType.Unimplemented, EntityKind.ReviewSM2, "tempCreatedReview is undefined"));
			return;
		}
		if (this.tempCreatedReviewCards === undefined) {
			LogErrorToProvider(NewUIErrorV2(ActionType.Unimplemented, EntityKind.ReviewSM2Card, "tempCreatedReviewCards is undefined"));
			return;
		}
		
		this.tempCreatedReviewCards = this.tempCreatedReviewCards.map((c) => {
			if (c.id === card.id) {
				return card;
			}
			return c;
		});
	}
	
	// @action
	public StartReview = async (
		review: ReviewSM2
	): Promise<ReviewSM2 | IUIError> => {
		review.startAt = new Date();
		review.progressState = ProgressStateEnumDTO.IN_PROGRESS;
		review.updatedOn = new Date();
		
		review = review.sanitize();
		
		let dto = review.convertReviewSM2ToDTO();
		
		let req = new SaveReviewSM2Request();
		req.setReview(dto);
		
		
		try {
			const response: SaveReviewSM2Response = await reviewClient.save(
				req,
			);
			
			if (response.getReview() === undefined) {
				return NewUIError(
					"SaveReviewSM2",
					InternalErrorTypes.SaveReviewSM2,
					`returned reviewSM2 is undefined`
				);
			}
			if (response.getAvailablecardsList() === undefined) {
				return NewUIError(
					"SaveReviewSM2",
					InternalErrorTypes.SaveReviewSM2,
					`returned reviewSM2 cards list undefined`
				);
			}
			
			let review = new ReviewSM2();
			let err = review.fromDTO(response.getReview()!);
			if (err) {
				return NewUIError(
					"SaveReviewSM2",
					InternalErrorTypes.SaveReviewSM2,
					`failed to save reviewSM2: base review (Value = review: ${review.id})`,
					undefined,
					err
				);
			}
			
			// Get the cards for this new SM2 review
			let cards: ReviewCard[] = [];
			response.getAvailablecardsList().forEach((dto) => {
				let card = new ReviewCard();
				const err = card.fromDTO(dto);
				
				if (err) {
					return NewUIError(
						"SaveReviewSM2",
						InternalErrorTypes.SaveReviewSM2,
						`failed to convert available card reviewSM2: (Value = review: ${dto.getId()})`,
						undefined,
						err
					);
				}
				cards.push(card);
			});
			
			runInAction(() => {
				cards.forEach((card) => {
					review.cards.push(card);
					this.map.set(review.id, review);
					return review;
				});
			});
			
			return review;
		} catch (err) {
			return NewUIError(
				"SaveReviewSM2",
				InternalErrorTypes.SaveReviewSM2,
				`failed to save reviewSM2: base review (Value = review: ${JSON.stringify(
					review
				)}) - Err(Value = ${JSON.stringify(err)})`
			);
		}
	};
}