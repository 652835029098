import {DevSupport} from "@react-buddy/ide-toolbox";
import * as Sentry from "@sentry/react";
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

// import '@fontsource/inter/400.css';
// import '@fontsource/inter/500.css';
// import '@fontsource/inter/600.css';
// import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';


// import '@fontsource/public-sans/400.css';
// import '@fontsource/public-sans/500.css';
// import '@fontsource/public-sans/600.css';
// import '@fontsource/public-sans/700.css';

import {SENTRY_DSN, TRACE_DISTRIBUTION_TARGET_FIREBASE, TRACE_DISTRIBUTION_TARGET_MEMINNI} from "consts";
import {ConfigProvider} from "context/ConfigContext";
import React from "react";
import "./index.css";
import {createRoot} from 'react-dom/client';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {useLocation} from "react-router-dom";


// if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
Sentry.init({
	dsn: SENTRY_DSN,
	release: 'meminni-web'+ process.env.npm_package_version,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost:3000", TRACE_DISTRIBUTION_TARGET_FIREBASE, TRACE_DISTRIBUTION_TARGET_MEMINNI],
	// Session Replay
	replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: process.env.NODE_ENV,
});
// }

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<ConfigProvider>
			<App />
		</ConfigProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();