import {createContext} from "react";
import {TopicStore} from "./TopicStore";
import {CardStore} from "./CardStore";
import {ResourceStore} from "./ResourceStore";
import {TagStore} from "./TagStore";
import {ReviewSM2Store} from "./ReviewSM2Store";
import {ReviewManualStore} from "./ReviewManualStore";
import {ReviewPageStore} from "./ReviewPageStore";
import {CardLandingPageStore} from "stores/CardLandingPageStore";
import {AuthenticationStore} from "./AuthenticationStore";
import {CardLangStore} from "./CardLangStore";
import {CardLangService} from "service/CardLangService";
import {LanguageService} from "service/LanguageService";
import {LanguageStore} from "./LanguageStore";
import {ReviewSM2Service} from "service/ReviewSM2Service";
import {ReviewManualService} from "service/ReviewManualService";
import {TagService} from "service/TagService";
import {TopicService} from "service/TopicService";
import {CardService} from "service/CardService";
import {ResourceService} from "service/ResourceService";
import {UserStore} from "stores/UserStore";
import {StatsStore} from "stores/StatsStore";
import {StatsServicePromiseClient} from "proto/stats_grpc_web_pb";
import {StripeStore} from "stores/StripeStore";
import {PublicStore} from "stores/PublicStore";
import {CARTA_PROXY_URL} from "consts";

const statsClient = new StatsServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true,
});

let cardService = new CardService();
let cardStore = new CardStore(cardService);

const tagService = new TagService();
let tagStore = new TagStore(tagService);

let topicService = new TopicService();
let topicStore = new TopicStore(topicService, cardStore);
let resourceStore = new ResourceStore(new ResourceService());

const sm2Service = new ReviewSM2Service();
let sm2Store = new ReviewSM2Store(sm2Service);

const manualService = new ReviewManualService()
let manualStore = new ReviewManualStore(cardStore, manualService);

const cardLangService = new CardLangService()
let cardLangStore = new CardLangStore(cardLangService);

const languageService = new LanguageService()
let languageStore = new LanguageStore(languageService);

let userStore = new UserStore();
let authStore = new AuthenticationStore(userStore);

let statsStore = new StatsStore(statsClient);
let stripeStore = new StripeStore()
let publicStore = new PublicStore()
let reviewPageStore = new ReviewPageStore(sm2Store, manualStore, cardStore);
let cardLandingPageStore = new CardLandingPageStore(cardStore, topicStore, resourceStore, tagStore)

export const rootStoreContext = createContext({
    topicStore: topicStore,
    cardStore: cardStore,
    tagStore: tagStore,
    resourceStore: resourceStore,
    reviewSM2Store: sm2Store,
    reviewManualStore: manualStore,
    authStore: authStore,
    cardLangStore: cardLangStore,
    languageStore: languageStore,
    userStore: userStore,
    statsStore: statsStore,
    stripeStore: stripeStore,
    publicStore: publicStore,
    
    // PAges
    reviewPageStore: reviewPageStore,
    cardLandingPageStore: cardLandingPageStore
});

// Clears all the stores
export const clearStores = () => {
    cardStore.Clear();
    // topicStore = new TopicStore(topicService, cardStore);
    // resourceStore = new ResourceStore(new ResourceService());
    // tagStore = new TagStore(tagService);
    // sm2Store = new ReviewSM2Store(sm2Service);
    // manualStore = new ReviewManualStore(cardStore, manualService);
    // cardLangStore = new CardLangStore(cardLangService);
    // languageStore = new LanguageStore(languageService);
    // userStore = new UserStore();
    // authStore = new AuthenticationStore(userStore);
    // statsStore = new StatsStore(statsClient);
    // stripeStore = new StripeStore();
    // publicStore = new PublicStore();
    
    // authStore.Clear();
    topicStore.Clear();
    cardStore.Clear();
    tagStore.Clear();
    resourceStore.Clear();
    sm2Store.Clear();
    manualStore.Clear();
    cardLangStore.Clear();
    languageStore.Clear();
    
    userStore.Clear();
    // authStore.Clear();
    statsStore.Clear();
    stripeStore.Clear();
    // publicStore.Clear();
    reviewPageStore.Clear();
    cardLandingPageStore.Clear();
};