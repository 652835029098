import {Tag} from "./tag";
import {Topic} from "./topic";
import {Resource} from "./resource/Resource";
import {IBaseModel, IFromDTO, IIntoDTO} from "./model";
import {
	ActionType,
	InternalErrorTypes,
	isError,
	IUIError,
	LogError,
	NewUIErrorV2,
	UIError
} from "../service/cartaError";
import {TopicDTO} from "../proto/topic_pb";
import {TagDTO} from "../proto/tag_pb";
import {ResourceDTO} from "../proto/resource_pb";
import {CardCompositeDTO} from "../proto/card_pb";
import {CardMedia} from "./CardMedia";
import {EntityKind} from "./BaseModel";
import {CardMediaDTO} from "../proto/cardMedia_pb";
import {MAX_MEDIA_PER_CARD, MAX_RESOURCE_PER_CARD, MAX_TAG_PER_CARD} from "../consts";

export interface ICard extends IBaseModel<any, any> {
	front: string;
	back: string;
	composite?: ICardComposite;
}

export interface IComposite {
	// This references the supertypes id
	id: string,
	ord: number,
}

export interface ICardComposite extends IComposite {
	tags: Tag[];
	topics: Topic[];
	resources: Resource[]
	media: CardMedia[]
}

export class CardComposite
	implements ICardComposite,
		IIntoDTO<CardCompositeDTO>, IFromDTO<CardCompositeDTO> {

	id: string = "";
	ord: number = 0;
	tags: Tag[] = [];
	topics: Topic[] = [];
	resources: Resource[] = [];
	media: CardMedia[] = [];
	
	constructor() {}

	fromDTO(t: CardCompositeDTO): void | IUIError {
		const errorKind = ActionType.ConvertFromDTO;
		const origin = EntityKind.CardComposite;
		
		t.getTopicsList().forEach((dto) => {
			let topic = new Topic()
			const err = topic.fromDTO(dto);
			
			if (err) {
				throw NewUIErrorV2(errorKind, origin,err, `failed to convert topic`)
			}
			
			this.topics.push(topic)
		})
		
		t.getTagsList().forEach((dto) => {
			let tag = new Tag()
			const err = tag.fromDTO(dto);
			
			if (err) {
				throw NewUIErrorV2(errorKind, origin,err, `failed to convert tag`)
			}
			this.tags.push(tag)
		})
		
		// RESOURCES
		t.getResourcesList().forEach((dto) => {
			let resource = new Resource()
			const err = resource.fromDTO(dto);
			
			if (err) {
				throw NewUIErrorV2(errorKind, origin,err, `failed to convert resource`)
			}
			this.resources.push(resource)
		})
		
		t.getMediaList().forEach((dto) => {
			let media = new CardMedia()
			const err = media.fromDTO(dto);
			
			if (err) {
				throw NewUIErrorV2(errorKind, origin, err, `failed to convert media`)
			}
			this.media.push(media)
		})
	}
	
	intoDTO(): IUIError | CardCompositeDTO {
		let topics_dto: TopicDTO[] = []
		let tags_dto: TagDTO[] = []
		let resources_dto: ResourceDTO[] = []
		let media_dto: CardMediaDTO[] = []
		
		this.topics.forEach((topic) => {
			const dto = topic.intoDTO();
			if (isError(dto)) {
				throw new UIError(InternalErrorTypes.InvalidCardComposite, "invalid topic", dto as IUIError)
			}
			topics_dto.push(dto as TopicDTO)
		})
		
		this.tags.forEach((tag) => {
			const dto = tag.intoDTO();
			if (isError(dto)) {
				throw new UIError(InternalErrorTypes.InvalidCardComposite, "invalid tag", dto as IUIError)
			}
			tags_dto.push(dto as TagDTO)
		})
		
		this.resources.forEach((resource) => {
			const dto = resource.intoDTO();
			if (isError(dto)) {
				throw new UIError(InternalErrorTypes.InvalidCardComposite, "invalid resource", dto as IUIError)
			}
			resources_dto.push(dto as ResourceDTO)
		})
		
		this.media.forEach((media) => {
			const dto = media.intoDTO();
			if (isError(dto)) {
				throw new UIError(InternalErrorTypes.InvalidCardComposite, "invalid media", dto as IUIError)
			}
			media_dto.push(dto as CardMediaDTO)
		})
		
		let dto = new CardCompositeDTO();
		
		dto.setOrd(0)
		dto.setTopicsList(topics_dto)
		dto.setTagsList(tags_dto)
		dto.setResourcesList(resources_dto)
		dto.setMediaList(media_dto)
		
		return dto
	}
	
	
    validate(): CardComposite | IUIError {
		if (this.topics.length === 0) {
			return NewUIErrorV2(ActionType.Validate, EntityKind.CardComposite, undefined, "no topics")
		}
		if (this.topics.length !== 1) {
			return NewUIErrorV2(ActionType.Validate, EntityKind.CardComposite, undefined, "too many topics, only one allowed")
		}
		
		if (this.tags.length > MAX_TAG_PER_CARD) {
			return NewUIErrorV2(ActionType.Validate, EntityKind.CardComposite, undefined, `too many tags, only ${MAX_TAG_PER_CARD} allowed`)
		}
		if (this.resources.length > MAX_RESOURCE_PER_CARD) {
			return NewUIErrorV2(ActionType.Validate, EntityKind.CardComposite, undefined, `too many resources, only ${MAX_RESOURCE_PER_CARD} allowed`)
		}
		if (this.media.length > MAX_MEDIA_PER_CARD) {
			return NewUIErrorV2(ActionType.Validate, EntityKind.CardComposite, undefined, `too many media items, only ${MAX_MEDIA_PER_CARD}} allowed`)
		}
		
		this.media.forEach((media) => {
			const err = media.customValidate();
			if (isError(err)) {
				return err
			}
		});

		this.resources.forEach((resource) => {
			const err = resource.customValidate();
			if (isError(err)) {
				return err
			}
		});

		this.tags.forEach((tag) => {
			const err = tag.customValidate();
			if (isError(err)) {
				return err
			}
		});

		this.topics.forEach((topic) => {
			const err = topic.customValidate();
			if (isError(err)) {
				return err
			}
		})

		return this
    }
	
    sanitize() {
		this.media = this.media.map((media) => media.sanitize());
		this.resources = this.resources.map((resource) => resource.sanitize());
		this.tags = this.tags.map((tag) => tag.sanitize());
		this.topics = this.topics.map((topic) => topic.sanitize());
    }

    clone(): CardComposite {
		let newComposite = new CardComposite();
		
		newComposite.id = this.id;
		newComposite.ord = this.ord;
		newComposite.tags = [...this.tags];
		newComposite.topics = [...this.topics];
		newComposite.resources = [...this.resources];
		newComposite.media = [...this.media];

		return newComposite
    }
}
