// source: review.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var card_pb = require('./card_pb.js');
goog.object.extend(proto, card_pb);
var cardLang_pb = require('./cardLang_pb.js');
goog.object.extend(proto, cardLang_pb);
var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.review.ReviewCardCompositeDTO', null, global);
goog.exportSymbol('proto.review.ReviewCardDTO', null, global);
goog.exportSymbol('proto.review.ReviewCardLangCompositeDTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.review.ReviewCardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.review.ReviewCardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.review.ReviewCardDTO.displayName = 'proto.review.ReviewCardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.review.ReviewCardCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.review.ReviewCardCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.review.ReviewCardCompositeDTO.displayName = 'proto.review.ReviewCardCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.review.ReviewCardLangCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.review.ReviewCardLangCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.review.ReviewCardLangCompositeDTO.displayName = 'proto.review.ReviewCardLangCompositeDTO';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.review.ReviewCardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.review.ReviewCardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.review.ReviewCardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardkind: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reviewkind: jspb.Message.getFieldWithDefault(msg, 7, 0),
    quality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 9, 0),
    easiness: jspb.Message.getFieldWithDefault(msg, 10, 0),
    repetitions: jspb.Message.getFieldWithDefault(msg, 11, 0),
    startAt: (f = msg.getStartAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endAt: (f = msg.getEndAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    nextReview: (f = msg.getNextReview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    composite: (f = msg.getComposite()) && proto.review.ReviewCardCompositeDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.review.ReviewCardDTO}
 */
proto.review.ReviewCardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.review.ReviewCardDTO;
  return proto.review.ReviewCardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.review.ReviewCardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.review.ReviewCardDTO}
 */
proto.review.ReviewCardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 5:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    case 7:
      var value = /** @type {!proto.utils.EnumReviewKindDTO} */ (reader.readEnum());
      msg.setReviewkind(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEasiness(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitions(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 13:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndAt(value);
      break;
    case 14:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setNextReview(value);
      break;
    case 15:
      var value = new proto.review.ReviewCardCompositeDTO;
      reader.readMessage(value,proto.review.ReviewCardCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 17:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 18:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.review.ReviewCardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.review.ReviewCardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.review.ReviewCardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getReviewkind();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getNextReview();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.review.ReviewCardCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.review.ReviewCardDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.review.ReviewCardDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO cardId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.review.ReviewCardDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO reviewID = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.review.ReviewCardDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 5;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.review.ReviewCardDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional utils.EnumReviewKindDTO reviewKind = 7;
 * @return {!proto.utils.EnumReviewKindDTO}
 */
proto.review.ReviewCardDTO.prototype.getReviewkind = function() {
  return /** @type {!proto.utils.EnumReviewKindDTO} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.utils.EnumReviewKindDTO} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setReviewkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 quality = 8;
 * @return {number}
 */
proto.review.ReviewCardDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 interval = 9;
 * @return {number}
 */
proto.review.ReviewCardDTO.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setInterval = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearInterval = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 easiness = 10;
 * @return {number}
 */
proto.review.ReviewCardDTO.prototype.getEasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setEasiness = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearEasiness = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasEasiness = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 repetitions = 11;
 * @return {number}
 */
proto.review.ReviewCardDTO.prototype.getRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.setRepetitions = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearRepetitions = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasRepetitions = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO start_at = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getStartAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional utils.TimestampDTO end_at = 13;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getEndAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 13));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearEndAt = function() {
  return this.setEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasEndAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional utils.TimestampDTO next_review = 14;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getNextReview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 14));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setNextReview = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearNextReview = function() {
  return this.setNextReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasNextReview = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ReviewCardCompositeDTO composite = 15;
 * @return {?proto.review.ReviewCardCompositeDTO}
 */
proto.review.ReviewCardDTO.prototype.getComposite = function() {
  return /** @type{?proto.review.ReviewCardCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.review.ReviewCardCompositeDTO, 15));
};


/**
 * @param {?proto.review.ReviewCardCompositeDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 17;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 17));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 18;
 * @return {?proto.utils.TimestampDTO}
 */
proto.review.ReviewCardDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 18));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.review.ReviewCardDTO} returns this
*/
proto.review.ReviewCardDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardDTO} returns this
 */
proto.review.ReviewCardDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.review.ReviewCardCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.review.ReviewCardCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.review.ReviewCardCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    supertypeId: (f = msg.getSupertypeId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && card_pb.CardDTO.toObject(includeInstance, f),
    cardLang: (f = msg.getCardLang()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.review.ReviewCardCompositeDTO}
 */
proto.review.ReviewCardCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.review.ReviewCardCompositeDTO;
  return proto.review.ReviewCardCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.review.ReviewCardCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.review.ReviewCardCompositeDTO}
 */
proto.review.ReviewCardCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setSupertypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrd(value);
      break;
    case 3:
      var value = new card_pb.CardDTO;
      reader.readMessage(value,card_pb.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 4:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCardLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.review.ReviewCardCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.review.ReviewCardCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.review.ReviewCardCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupertypeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      card_pb.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardLang();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO supertype_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.review.ReviewCardCompositeDTO.prototype.getSupertypeId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
*/
proto.review.ReviewCardCompositeDTO.prototype.setSupertypeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
 */
proto.review.ReviewCardCompositeDTO.prototype.clearSupertypeId = function() {
  return this.setSupertypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardCompositeDTO.prototype.hasSupertypeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 ord = 2;
 * @return {number}
 */
proto.review.ReviewCardCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
 */
proto.review.ReviewCardCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional card.CardDTO card = 3;
 * @return {?proto.card.CardDTO}
 */
proto.review.ReviewCardCompositeDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, card_pb.CardDTO, 3));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
*/
proto.review.ReviewCardCompositeDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
 */
proto.review.ReviewCardCompositeDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardCompositeDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional cardLang.CardLangDTO card_lang = 4;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.review.ReviewCardCompositeDTO.prototype.getCardLang = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 4));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
*/
proto.review.ReviewCardCompositeDTO.prototype.setCardLang = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardCompositeDTO} returns this
 */
proto.review.ReviewCardCompositeDTO.prototype.clearCardLang = function() {
  return this.setCardLang(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardCompositeDTO.prototype.hasCardLang = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.review.ReviewCardLangCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.review.ReviewCardLangCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardLangCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewcard: (f = msg.getReviewcard()) && proto.review.ReviewCardDTO.toObject(includeInstance, f),
    card: (f = msg.getCard()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.review.ReviewCardLangCompositeDTO}
 */
proto.review.ReviewCardLangCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.review.ReviewCardLangCompositeDTO;
  return proto.review.ReviewCardLangCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.review.ReviewCardLangCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.review.ReviewCardLangCompositeDTO}
 */
proto.review.ReviewCardLangCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.review.ReviewCardDTO;
      reader.readMessage(value,proto.review.ReviewCardDTO.deserializeBinaryFromReader);
      msg.setReviewcard(value);
      break;
    case 5:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.review.ReviewCardLangCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.review.ReviewCardLangCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.review.ReviewCardLangCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewcard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.review.ReviewCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewCardDTO reviewCard = 1;
 * @return {?proto.review.ReviewCardDTO}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.getReviewcard = function() {
  return /** @type{?proto.review.ReviewCardDTO} */ (
    jspb.Message.getWrapperField(this, proto.review.ReviewCardDTO, 1));
};


/**
 * @param {?proto.review.ReviewCardDTO|undefined} value
 * @return {!proto.review.ReviewCardLangCompositeDTO} returns this
*/
proto.review.ReviewCardLangCompositeDTO.prototype.setReviewcard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardLangCompositeDTO} returns this
 */
proto.review.ReviewCardLangCompositeDTO.prototype.clearReviewcard = function() {
  return this.setReviewcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.hasReviewcard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cardLang.CardLangDTO card = 5;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.getCard = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 5));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.review.ReviewCardLangCompositeDTO} returns this
*/
proto.review.ReviewCardLangCompositeDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.review.ReviewCardLangCompositeDTO} returns this
 */
proto.review.ReviewCardLangCompositeDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.review.ReviewCardLangCompositeDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.review);
