import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import {GuardProps} from 'types/auth';
import {AuthenticationStateEnum} from "stores/AuthenticationStore";
import {APP_DEFAULT_PATH} from "../../consts";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { authenticationState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isPublicPath = location.pathname.startsWith("/p/") || location.pathname.startsWith("/login") || location.pathname.startsWith("/register");

    if (authenticationState.state === AuthenticationStateEnum.LoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    } else if (!isPublicPath) {
      // navigate("/login", {
      //   replace: true
      // });
    }
  }, [authenticationState, navigate, location]);

  return children;
};


export default GuestGuard;
