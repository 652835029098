import "./App.css";
import {RouterProvider,} from "react-router-dom";
import React from "react";
import {injectStores} from "@mobx-devtools/tools";
import {TopicStore} from "stores/TopicStore";
import {CardStore} from "stores/CardStore";
import {ReviewSM2Store} from "stores/ReviewSM2Store";
import {ReviewManualStore} from "stores/ReviewManualStore";
import {ReviewPageStore} from "stores/ReviewPageStore";
import {TagStore} from "stores/TagStore";
import {ResourceStore} from "stores/ResourceStore";
import {AlertProvider} from "context/AlertContext";
import {ReviewSM2Service} from "service/ReviewSM2Service";
import {ReviewManualService} from "service/ReviewManualService";
import {TopicService} from "service/TopicService";
import {TagService} from "service/TagService";
import {CardService} from "service/CardService";
import {ResourceService} from "service/ResourceService";
import {FirebaseProvider} from "context/FirebaseContext";

import Snackbar from 'components/@extended/Snackbar';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from "@mui/material/useMediaQuery";
import router from "routes";
import ThemeCustomization from "themes";
import RTLLayout from "components/RTLLayout";
import Locales from "components/Locales";
import ScrollTop from "components/ScrollTop";
import Notistack from "components/third-party/Notistack";
import {AuthenticationStore} from "stores/AuthenticationStore";
import {UserStore} from "stores/UserStore";

import * as Sentry from "@sentry/react";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {
    const cardStore = new CardStore(new CardService());
    const topicStore = new TopicStore(new TopicService(), cardStore);
    const sm2Store = new ReviewSM2Store(new ReviewSM2Service());
    const manualStore = new ReviewManualStore(cardStore, new ReviewManualService());
    const reviewPageStore = new ReviewPageStore(sm2Store, manualStore, cardStore);
    const tagStore = new TagStore(new TagService());
    const resourceStore = new ResourceStore(new ResourceService());
    const userStore = new UserStore()
    const authStore = new AuthenticationStore(userStore)

    injectStores({
        topicStore,
        cardStore,
        sm2Store,
        manualStore,
        reviewPageStore,
        tagStore,
        resourceStore,
        authStore,
        userStore
    });

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // const theme = React.useMemo(
    //     () =>
    //         createTheme({
    //             palette: {
    //                 mode: prefersDarkMode ? 'dark' : 'light',
    //             },
    //         }),
    //     [prefersDarkMode],
    // );

    return (
        <ThemeCustomization>
            <RTLLayout>
                {/*<ThemeProvider theme={theme}>*/}
                    <CssBaseline/>
                    <Locales>
                        <ScrollTop>
                            <FirebaseProvider>
                                <>
                                    <Notistack>
                                        <AlertProvider>
                                            <RouterProvider router={router}/>
                                            <Snackbar/>
                                        </AlertProvider>
                                    </Notistack>
                                </>
                            </FirebaseProvider>
                        </ScrollTop>
                    </Locales>
                {/*</ThemeProvider>*/}
            </RTLLayout>
        </ThemeCustomization>
    );
}

export default Sentry.withProfiler(App);

