import {StripeServicePromiseClient} from "proto/stripe_grpc_web_pb";
import {Err, Ok, Result} from "utils/result";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";
import {StripeProduct} from "model/StripeProduct";
import {PublicServicePromiseClient} from "proto/public_grpc_web_pb";
import {
    CreateCheckoutSessionRequest,
    CreateCheckoutSessionResponse,
    CreatePortalSessionRequest,
    CreatePortalSessionResponse, EnumSubscriptionProductDTO, PricingSessionDto,
} from "proto/stripe_pb";
import {GetAllProductsRequest, GetProductAndPriceRequest} from "proto/public_pb";
import {PricingSession} from "pages/billing/pricing";
import {CARTA_PROXY_URL} from "consts";

const stripeClient = new StripeServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true,
});

const publicClient = new PublicServicePromiseClient(CARTA_PROXY_URL!, null, {});

export class StripeStore {
    constructor() {
    }
    
    Clear() {
        // this.map.clear();
    }

    // async GetAllProductsAndPrices(): Promise<Result<any, IUIError>> {
    //     try {
    //         const res = await stripeClient.getAllProductsAndPrice({})
    //
    //         return Ok(res)
    //     } catch (e) {
    //         return Err(NewUIErrorV2(ActionType.StripeGetAllProductsAndPrices, EntityKind.Stripe, e))
    //     }
    // }
    //

    async GetAllProductsAndPrices(): Promise<Result<StripeProduct[], IUIError>> {
        let req = new GetAllProductsRequest();

        try {
            const res = await publicClient.getAllProductsAndPrice(req)
            let products: Array<StripeProduct> = [];

            res.getProductsList().forEach((x) => {
                let product = new StripeProduct();

                const err = product.fromDTO(x)
                if (err) {
                    return Err(err as IUIError)
                }

                products.push(product)
            })
            return Ok(products)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }

    async GetProductAndPrice(sub: EnumSubscriptionProductDTO): Promise<Result<StripeProduct, IUIError>> {
        let req = new GetProductAndPriceRequest();
        req.setSub(sub)

        try {
            const res = await publicClient.getProductAndPrice(req)
            if (!res.getProduct()) {
                return Err(NewUIErrorV2(ActionType.StripeProductAndPrice, EntityKind.Stripe, new Error("product is null")))
            }

            let product = new StripeProduct();
            product.fromDTO(res.getProduct()!);

            console.log("PRODUCT: ", product)

            return Ok(product)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }


    async CreateCheckoutSession(subscription: EnumSubscriptionProductDTO, createStripeCustomer?: boolean): Promise<Result<CreateCheckoutSessionResponse, IUIError>> {
        let req = new CreateCheckoutSessionRequest();
        if (createStripeCustomer) {
            req.setCreateCustomer(createStripeCustomer)
        }

        try {
            const res = await stripeClient.createCheckoutSession(req);

            console.log(res)

            return Ok(res)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }

    async PublicCreateCheckoutSession(pricingSession: PricingSession, customerId: string | null, createStripeCustomer?: boolean): Promise<Result<CreateCheckoutSessionResponse, IUIError>> {
        let req = new CreateCheckoutSessionRequest();
        if (createStripeCustomer) {
            req.setCreateCustomer(createStripeCustomer)
        }
        if (customerId) {
            req.setCustomerId(customerId)
        }

        let dto = new PricingSessionDto();
        dto.setSessionId(pricingSession.sessionId)
        dto.setPriceId(pricingSession.priceId)

        req.setPricingSession(dto)

        try {
            const res = await publicClient.publicCreateCheckoutSession(req);

            console.log(res)

            return Ok(res)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }

    async CreatePortalSession(sessionId: string): Promise<Result<CreatePortalSessionResponse, IUIError>> {
        let req = new CreatePortalSessionRequest();
        req.setSessionId(sessionId)

        console.log(req)

        try {
            const res = await stripeClient.createPortalSession(req);

            console.log(res)

            return Ok(res)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripePortalCheckoutSession, EntityKind.Stripe, e))
        }
    }
}